import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Table } from '../../../../components/Table';
import {
    useDeleteUserMutation,
    useDeleteUserBulkMutation,
    useGetUserDsQuery,
    useGetSupliersListQuery,
    useDeleteSupplierMutation,
    useDeleteSupplierBulkMutation
} from '../../../../api/users';
import { User, UserDsQueryParams, Supplier } from '../../../../api/types/user';
import { ModalUsers } from '../../../../components/Modals/ModalUsers';
import { Key, TableRowSelection } from 'antd/es/table/interface';
import ArrowUp from '../../../../components/Icons/ArrowUp';
import ArrowDown from '../../../../components/Icons/ArrowDown';
import UserStatus from '../../../../components/UserStatus';
import Delete from '../../../../components/Icons/Delete';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useTableDataSourceWithHooks } from '../../../../components/Table/useTableDataPropsWithHooks';
import UserInfo from '../../../../components/UserInfo';
import InputSearch from '../../../../components/Fields/InputSearch';
import { Button, Empty, message, Typography } from 'antd';
import NoUsers from '../../../../components/Icons/NoUsers';
import NoResults from '../../../../components/Icons/NoResults';
import ModalDeleteUser from '../../../../components/Modals/ModalDeleteUser';
import { colors } from '../../../../themes/colors';
import useDebounceSearch from '../../../../hooks/useDebounceSearch';
import { CustomErrorType } from '../../../../api/types/global';
import { CustomMultiSelectCheckbox } from '../../../../components/Fields/CustomMultiSelectCheckbox';
import { useGetSchoolTypesQuery } from '../../../../api/common';
import searchList from '../../../../assets/search-list-loap.svg';
import Pending from '../../../../assets/Clock, Time, Arrow.svg';
import Blacklisted from '../../../../assets/Blacklisted.svg';
import Unverified from '../../../../assets/Unverified.svg';
import Verified from '../../../../assets/Verified.svg';
import NotProvided from '../../../../assets/Not Provided.svg';
import Requested from '../../../../assets/Requested.svg';
import Received from '../../../../assets/Received.svg';
import { ModalSupplier } from '../../../../components/Modals/ModalSupplier';
import { useSearchParams } from 'react-router-dom';

export const Suppliers = () => {
    const [editModal, setEditModal] = useState<{ type: string; open: boolean; id: number | null }>({
        type: 'create',
        open: false,
        id: null
    });
    const [deleteModal, setDeleteModal] = useState<{ open: boolean; id: number | number[] | null; name: string }>({
        open: false,
        id: null,
        name: ''
    });
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounceSearch(search);
    const types = {};
    const { data: dataSchoolTypes } = useGetSchoolTypesQuery(types);

    const [selectedArea, setSelectedAreas] = useState<any[]>([]);
    const [selecteDocStatuses, setSelectedDocStatuses] = useState<any[]>([]);
    const [selectedSuppStatuses, setSelectedSuppStatuses] = useState<any[]>([]);

    const [searchParams] = useSearchParams();
    const supplierStatus = searchParams.get('status');
    const documentStatus = searchParams.get('docstatus');

    console.log('selectedRowKeys', selectedRowKeys);

    useEffect(() => {
        if (documentStatus === 'not_provided') {
            setSelectedDocStatuses([{ name: 'Not Provided', id: 'not_provided' }]);
        }
        if (documentStatus === 'received') {
            setSelectedDocStatuses([{ name: 'Received', id: 'received' }]);
        }
        if (supplierStatus === 'pending') {
            setSelectedSuppStatuses([{ name: 'Pending', id: 'pending' }]);
        }
        if (supplierStatus === 'pending_tpa') {
            setSelectedSuppStatuses([{ name: 'Pending TPA', id: 'pending_tpa' }]);
        }
        if (supplierStatus === 'unverified') {
            setSelectedSuppStatuses([{ name: 'Unverified', id: 'unverified' }]);
        }
    }, [supplierStatus, documentStatus]);

    const [variables, setVariables] = useState<UserDsQueryParams>({
        filter: { search: undefined },
        sort_field: 'company_name',
        sort_direction: undefined,
        per_page: 10,
        page: 1
    });

    useEffect(() => {
        setVariables({
            ...variables,
            filter: { ...variables.filter, search: debouncedSearch }
        });
    }, [debouncedSearch]);

    useEffect(() => {
        setVariables((prev) => ({ ...prev, page: 1 }));
    }, [search]);

    useEffect(() => {
        setVariables({
            ...variables,
            filter: {
                ...variables.filter,
                expense_area: selectedArea?.length ? selectedArea.map((elem) => elem.id) : [],
                document_status: selecteDocStatuses?.length ? selecteDocStatuses?.map((elem) => elem.id) : [],
                status: selectedSuppStatuses?.length ? selectedSuppStatuses?.map((elem) => elem.id) : []
            }
        });
    }, [selectedArea, selecteDocStatuses, selectedSuppStatuses]);

    const { data, isLoading, isFetching, refetch } = useGetSupliersListQuery(variables);
    const [deleteUser, { isLoading: isDeleting, isError: isDeletingError, error: deletingError }] =
        useDeleteSupplierMutation();
    const [deleteUserBulk, { isLoading: isDeletingBulk, isError: isDeletingBulkError, error: deletingBulkError }] =
        useDeleteSupplierBulkMutation();
    const [messageApi, contextHolder] = message.useMessage();

    const deleteModalCloseHandler = () => {
        setDeleteModal({ open: false, id: null, name: '' });
        refetch();
    };

    useEffect(() => {
        if (isDeletingError) {
            messageApi.error('The User can not be deleted as they participate in an active tender');
        }
    }, [isDeletingError]);

    // TODO: move delete to separate file
    const handleDelete = async () => {
        try {
            await deleteUser(deleteModal.id).unwrap();
            messageApi.success(`User ${deleteModal.name} has been deleted successfully`);
        } catch (e) {
            if (deletingBulkError) {
                messageApi.error((deletingBulkError as CustomErrorType)?.data?.message);
            }
        } finally {
            setDeleteModal({ open: false, id: null, name: '' });
            refetch();
        }
    };

    const handleBulkDelete = async (ids: number[]) => {
        try {
            await deleteUserBulk({ ids }).unwrap();
            messageApi.success(`User ${deleteModal.name} has been deleted successfully`);
        } catch (e) {
            if (isDeletingBulkError) {
                messageApi.error((deletingBulkError as CustomErrorType)?.data?.message || 'Something went wrong');
            }
        } finally {
            setDeleteModal({ open: false, id: null, name: '' });
            setSelectedRowKeys([]);
            refetch();
        }
    };

    const statusImage = (status: string) => {
        if (status === 'Pending' || status === 'Pending TPA') {
            return Pending;
        }

        if (status === 'Blacklisted' || status === 'Blacklist') {
            return Blacklisted;
        }
        if (status === 'Unverified') {
            return Unverified;
        }
        if (status === 'Verified' || status === 'Verify') {
            return Verified;
        }
        if (status === 'Not Provided') {
            return NotProvided;
        }
        if (status === 'Requested') {
            return Requested;
        }
        if (status === 'Received') {
            return Received;
        }
    };

    const selectItem = (
        area: any,
        setSelectedItems: Dispatch<SetStateAction<any[]>>,
        selectedItem: any[],
        data: any[]
    ) => {
        if (area.id === 'selectedAll') {
            const ids = data?.map((elem: any) => elem);

            setSelectedItems(ids);
        } else {
            const isAlreadySelected = selectedItem.some((item) => item.id === area.id);

            if (isAlreadySelected) {
                const updatedSelectedAreas = selectedItem.filter((item) => item.id !== area.id);
                setSelectedItems(updatedSelectedAreas);
            } else {
                const newSelectedAreas = [...selectedItem, area];
                setSelectedItems(newSelectedAreas);
            }
        }
    };

    const { tableProps } = useTableDataSourceWithHooks<Supplier>({
        columns: [
            {
                dataIndex: 'company_name',
                title: 'Company Name',
                sorter: true,
                width: 200,
                sortIcon: ({ sortOrder }) => {
                    if (sortOrder === 'ascend') {
                        return <ArrowUp />;
                    }
                    if (sortOrder === 'descend') {
                        return <ArrowDown />;
                    }
                }
            },
            {
                dataIndex: 'email',
                title: 'Email',
                sorter: true,
                width: 200,
                sortIcon: ({ sortOrder }) => {
                    if (sortOrder === 'ascend') {
                        return <ArrowUp />;
                    }
                    if (sortOrder === 'descend') {
                        return <ArrowDown />;
                    }
                }
            },
            {
                dataIndex: 'phone_number',
                title: 'Phone Number',
                sorter: true,
                width: 150,
                sortIcon: ({ sortOrder }) => {
                    if (sortOrder === 'ascend') {
                        return <ArrowUp />;
                    }
                    if (sortOrder === 'descend') {
                        return <ArrowDown />;
                    }
                }
            },
            {
                dataIndex: 'expense_areas',
                title: 'Expense Area',
                width: 200,
                render: (area) => (
                    <div>
                        {area?.map((elem: any) => (
                            <div>{elem.name}</div>
                        ))}
                    </div>
                )
            },
            {
                dataIndex: 'provinces',
                title: 'Province',
                width: 200,
                render: (provinces) => (
                    <div>
                        {provinces?.map((elem: any) => (
                            <div>{elem.name}</div>
                        ))}
                    </div>
                )
            },
            {
                dataIndex: 'status',
                title: 'Supplier Status',
                sorter: true,
                width: 150,
                render: (status, record) => (
                    <div className="supplier_status">
                        <img src={statusImage(record.status_name)} />
                        {record.status_name}
                    </div>
                ),

                sortIcon: ({ sortOrder }) => {
                    if (sortOrder === 'ascend') {
                        return <ArrowUp />;
                    }
                    if (sortOrder === 'descend') {
                        return <ArrowDown />;
                    }
                }
            },
            {
                dataIndex: 'document_status',
                title: 'Docs. Status',
                sorter: true,
                width: 180,
                render: (status, record) => (
                    <div className="supplier_status">
                        <img src={statusImage(record.document_status_name)} />
                        {record.document_status_name}
                    </div>
                ),

                sortIcon: ({ sortOrder }) => {
                    if (sortOrder === 'ascend') {
                        return <ArrowUp />;
                    }
                    if (sortOrder === 'descend') {
                        return <ArrowDown />;
                    }
                }
            },

            {
                title: ' ',
                width: 50,
                key: 'x',
                render: (_, record) => (
                    <Delete
                        style={{ fontSize: '20px' }}
                        fill={colors.Texts_and_Icons.Disabled}
                        onClick={(event) => {
                            event.stopPropagation();
                            setDeleteModal({ open: true, id: record.id, name: record.company_name || record.email });
                        }}
                    />
                ),
                align: 'right'
            }
        ],
        rowKey: 'id',
        data: {
            rows: data?.data,
            loading: isLoading || isFetching,
            total: data?.meta?.total
        },
        paginationConfig: { defaultPageSize: 10, showSizeChanger: false, position: ['bottomCenter'] },
        onQueryVariableChange: (options) => {
            setVariables({
                ...variables,
                page: options?.page || 1,
                per_page: options?.limit || 10,
                sort_field: options?.sortField || variables.sort_field,
                sort_direction: options?.sortDirection || variables.sort_direction,
                filter: {
                    ...variables.filter
                }
            });
        }
    });

    const rowSelection: TableRowSelection<Supplier> = {
        type: 'checkbox',
        selectedRowKeys,
        onChange: (selectedRowKeys: Key[], selectedRows: Supplier[]) => {
            setSelectedRowKeys(selectedRowKeys);
        },
        columnWidth: 35
    };

    return (
        <>
            {contextHolder}
            <div className="header-actions">
                <div className="header-actions__row">
                    <InputSearch handleChange={(value) => setSearch(value)} />
                    <div className="input-wrapper-multiselect with-checkboxes">
                        <CustomMultiSelectCheckbox
                            required={false}
                            floatLabel={'Expense Area'}
                            err={false}
                            options={dataSchoolTypes?.data?.expense_areas}
                            onChange={(item: any) => {
                                selectItem(item, setSelectedAreas, selectedArea, dataSchoolTypes?.data?.expense_areas);
                            }}
                            hasError={() => false}
                            prefix={searchList}
                            value={selectedArea}
                            deleteItem={() => {}}
                            deleteAllItems={(values) => setSelectedAreas(values)}
                            setTouched={() => {}}
                        />
                    </div>
                    <div className="input-wrapper-multiselect with-checkboxes">
                        <CustomMultiSelectCheckbox
                            required={false}
                            floatLabel={'Docs. Status'}
                            err={false}
                            options={dataSchoolTypes?.data?.supplier_document_statuses.map((elem: any) => ({
                                id: elem.key,
                                name: elem.value
                            }))}
                            onChange={(item: any) => {
                                selectItem(
                                    item,
                                    setSelectedDocStatuses,
                                    selecteDocStatuses,
                                    dataSchoolTypes?.data?.supplier_document_statuses.map((elem: any) => ({
                                        id: elem.key,
                                        name: elem.value
                                    }))
                                );
                            }}
                            hasError={() => false}
                            prefix={undefined}
                            value={selecteDocStatuses}
                            deleteItem={() => {}}
                            deleteAllItems={(values) => setSelectedDocStatuses(values)}
                            setTouched={() => {}}
                        />
                    </div>
                    <div className="input-wrapper-multiselect with-checkboxes">
                        <CustomMultiSelectCheckbox
                            required={false}
                            floatLabel={'Supplier Status'}
                            err={false}
                            options={dataSchoolTypes?.data?.supplier_company_statuses.map((elem: any) => ({
                                id: elem.key,
                                name: elem.value
                            }))}
                            onChange={(item: any) => {
                                selectItem(
                                    item,
                                    setSelectedSuppStatuses,
                                    selectedSuppStatuses,
                                    dataSchoolTypes?.data?.supplier_company_statuses.map((elem: any) => ({
                                        id: elem.key,
                                        name: elem.value
                                    }))
                                );
                            }}
                            hasError={() => false}
                            prefix={undefined}
                            value={selectedSuppStatuses}
                            deleteItem={() => {}}
                            deleteAllItems={(values) => setSelectedSuppStatuses(values)}
                            setTouched={() => {}}
                        />
                    </div>
                </div>

                <Button
                    type="primary"
                    onClick={() => {
                        setEditModal({ type: 'create', open: true, id: null });
                    }}
                    icon={<PlusCircleOutlined />}
                >
                    Add
                </Button>
            </div>

            <Table<Supplier>
                {...tableProps}
                rowSelection={rowSelection}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                scroll={{ y: '70vh' }}
                handleDelete={() =>
                    setDeleteModal({ open: true, id: selectedRowKeys as number[], name: 'selected users' })
                }
                onRow={(record) => {
                    return {
                        onClick: () => {
                            setEditModal({ type: 'view', open: true, id: record.id });
                        }
                    };
                }}
                // TODO: move to separate component
                locale={{
                    emptyText: variables.filter?.search ? (
                        <Empty
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                gap: '24px',
                                margin: '50px 0'
                            }}
                            image={<NoResults />}
                            description={
                                <div
                                    style={{
                                        maxWidth: '390px'
                                    }}
                                >
                                    <Typography.Title level={4}>No results found</Typography.Title>
                                    <Typography.Paragraph type="secondary">
                                        Try adjusting your search to find what you are looking for
                                    </Typography.Paragraph>
                                </div>
                            }
                        />
                    ) : (
                        <Empty
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                gap: '24px',
                                margin: '50px 0'
                            }}
                            image={<NoUsers />}
                            description={
                                <div
                                    style={{
                                        maxWidth: '390px'
                                    }}
                                >
                                    <Typography.Title level={4}>No suppliers yet</Typography.Title>
                                    <Typography.Paragraph type="secondary">
                                        Add a new user to start to working with them
                                    </Typography.Paragraph>
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            setEditModal({ type: 'create', open: true, id: null });
                                        }}
                                        icon={<PlusCircleOutlined />}
                                    >
                                        Add User
                                    </Button>
                                </div>
                            }
                        />
                    )
                }}
            />
            {editModal.open && (
                <ModalSupplier
                    openModal={editModal.open}
                    setOpenModal={setEditModal}
                    type={editModal.type}
                    userId={editModal.id}
                    refetch={() => refetch()}
                    statusImage={statusImage}
                />
            )}
            {deleteModal.open && (
                <ModalDeleteUser
                    open={deleteModal.open}
                    onClose={deleteModalCloseHandler}
                    userId={deleteModal.id}
                    deleteUser={handleDelete}
                    deleteUserBulk={handleBulkDelete}
                    userName={deleteModal.name}
                    isDeleting={isDeleting || isDeletingBulk}
                    type={'supplier'}
                />
            )}
        </>
    );
};
