import { useState } from 'react';
import logo from '../../assets/logoForHeader.svg';
import logoPhone from '../../assets/logo-width-to600.svg';
import '../SignUp/index.scss';
import EmailIcon from '../../assets/EmailIcon.svg';

export const EmailConfirmation = () => {
    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);
    function handleResize() {
        setScreenWidth(window.screen.width);
    }

    window.addEventListener('resize', handleResize);

    return (
        <>
            <div className="email_confirmation">
                <div className="email_confirmation__header header">
                    {screenWidth > 600 && <img src={logo} className="header__logo" />}
                    {screenWidth <= 600 && <img src={logoPhone} className="header__logo" />}
                </div>
                <div className="email_confirmation__wrapper">
                    <img src={EmailIcon} />
                    <div className="email_confirmation__title">Email address confirmed</div>
                    <div className="email_confirmation__subtitle">
                        You have successfully confirmed your email address, and your company has been added to the
                        supplier list for this tender.
                    </div>
                    <div className="email_confirmation__add-text">
                        Going forward, you will receive periodic notifications to keep you informed of important updates
                        and next steps.
                    </div>
                </div>
            </div>
        </>
    );
};
