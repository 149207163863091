import { useState, FC, useEffect } from 'react';
import {
    useApproveTaskMutation,
    useDeleteFileMutation,
    useSendActivitiesMutation,
    useSendTaskMutation,
    useUpdateTaskDeadlineMutation
} from '../../api/tenders';
import { Button, Modal, Tooltip, message } from 'antd';
import { useAppSelector } from '../../hooks';
import { Loader } from '../Loader/Loader';
import { taskProps, tenderDataProps } from '../../pages/TenderViewPage/types';
import { UploadFileModal } from '../Modals/UploadFileModal';
import { TaskDeadlineModal } from '../Modals/TaskDeadline';
import { DefineMeetingTask } from './DefinePhaseComponents/DefineMeetingTask';
import { DefineMandatTasks } from './DefinePhaseComponents/DefineMandatTasks';
import { DefineQuestionnaireTask } from './DefinePhaseComponents/DefineQuestionnaireTask';
import { DefineSupportingDocumentTask } from './DefinePhaseComponents/DefineSupportingDocumentTask';
import WelcomeToDefine from '../../assets/WelcomeToDefine.svg';
import edit from '../../assets/editAdminIcon.svg';

import arrow from '../../assets/arrow-right-white.svg';
import attention from '../../assets/attention.svg';
import file from '../../assets/document.svg';
import fileGrey from '../../assets/fileGrey.svg';

import moment from 'moment';
import './phases.scss';
import { AllTasksCompleted } from './AllTasksCompleted';

interface PropsPhase {
    data: tenderDataProps;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DefinePhase: FC<PropsPhase> = ({ data, setTrigger, setLoading, setConfirmModal }) => {
    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);
    function handleResize() {
        setScreenWidth(window.screen.width);
    }
    window.addEventListener('resize', handleResize);

    const [
        deleteFile,
        {
            data: deletedData,
            isLoading: isLoadingDeleteFile,
            isSuccess: isSuccessDeleteFile,
            isError: isErrorDeleteFile
        }
    ] = useDeleteFileMutation();

    const [sendActivities] = useSendActivitiesMutation();

    const [
        sendTask,
        { data: dataSendTask, isError: isErrorSendTask, isSuccess: isSuccessSendTask, isLoading: isLoadingSendTask }
    ] = useSendTaskMutation();

    const [updateDeadline, { isLoading }] = useUpdateTaskDeadlineMutation();

    const { role } = useAppSelector((state: any) => state.profileReducer);
    const [isCreateModal, setIsCreateModal] = useState<boolean>(false);
    const [taskId, setTaskId] = useState<string | null | number>(null);
    const [messageApi, contextHolder] = message.useMessage();
    const [checked, setChecked] = useState('');
    const [isCreateTenderMandatModal, setIsCreateTenderMandatModal] = useState<boolean>(false);
    const [isDeleteFileModal, setIsDeleteFileModal] = useState<boolean>(false);
    const [deadlineModal, setDeadlineModal] = useState(false);
    const [SelectedDate, setSelectedDate] = useState<Date | string>(new Date());
    const [confirmMeeting, setConfirmMeeting] = useState(false);
    const [supportingModal, setSupportingModal] = useState<boolean>(false);
    const [approveMeeting, setApproveMeeting] = useState<boolean>(false);

    useEffect(() => {
        if (!data) return;
        if (data.tasks_deadline_at) {
            setSelectedDate(data.tasks_deadline_at);
        }
    }, [data]);

    const updateDeadlineFunc = async () => {
        try {
            const formData = {
                id: data.id,
                data: { deadline_at: moment(SelectedDate).format('YYYY-MM-DD') }
            };
            await updateDeadline(formData).unwrap();
            await setDeadlineModal(false);
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    const sendActivitiesFunc = async () => {
        const formData = {
            tenderId: data.id,
            taskId: taskId,
            data: {
                action: 'link_click'
            }
        };
        try {
            await sendActivities(formData);
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    const [approveTask, { data: approvedData, isSuccess: isSuccessApprovedData, isLoading: isLoadingApproveTask }] =
        useApproveTaskMutation();

    const approveTaskFunc = async () => {
        try {
            const formData = {
                tenderId: data?.id,
                taskId: taskId
            };
            await approveTask(formData).unwrap();
            setApproveMeeting(false);
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    const sendTaskFunc = async () => {
        if (data?.phase_tasks?.[0].action === 'define_phase_meeting' && role !== 'school_user') {
            let formData = {};

            formData = {
                id: data?.id,
                data: {
                    completed: checked === 'took_place' ? true : false
                }
            };

            try {
                await sendTask(formData).unwrap();
                await setTrigger((prev: boolean) => !prev);
                await setConfirmMeeting(false);
            } catch {}
        } else {
            if (role !== 'school_user') {
                let formData = {};

                formData = {
                    id: data?.id
                };
                try {
                    await sendTask(formData).unwrap();
                    await setTrigger((prev: boolean) => !prev);
                    await setConfirmMeeting(false);
                } catch {}
            }
            if (role === 'school_user') {
                let formData = {
                    id: data?.id
                };
                try {
                    await sendTask(formData).unwrap();
                    await setTrigger((prev: boolean) => !prev);
                } catch {}
            }
        }
    };

    useEffect(() => {
        if (isErrorSendTask) {
            messageApi.error('Something went wrong, please try again');
        }
        if (isSuccessSendTask) {
            messageApi.success(dataSendTask.message);
        }
    }, [isErrorSendTask, isSuccessSendTask]);

    useEffect(() => {
        if (!data) return;
        data.phase_tasks.forEach((elem: taskProps) => {
            if (elem.action === 'define_phase_meeting') {
                setTaskId(elem.id);
            }
        });
    }, [data]);

    useEffect(() => {
        if (isSuccessDeleteFile) {
            messageApi.success(deletedData.message);
        }
        if (isErrorDeleteFile) {
            messageApi?.error('Something went wrong, please try again');
        }
    }, [isSuccessDeleteFile, isErrorDeleteFile]);

    const deleteFileFunc = async () => {
        try {
            const formData = {
                tenderId: data.id,
                taskId: taskId,
                file:
                    data?.phase_tasks?.find((elem: any) => elem.id === taskId)?.files.tender_representative[0].uuid ||
                    null
            };
            await deleteFile(formData).unwrap();
            await setIsDeleteFileModal(false);
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    useEffect(() => {
        if (isSuccessApprovedData) {
            messageApi.success(approvedData?.message);
        }
    }, [isSuccessApprovedData, approvedData]);

    const isTheSameSlots = () => {
        const task = data?.phase_tasks?.find((elem) => elem.action === 'define_phase_meeting');
        //@ts-ignore
        const { date, time_from, time_to, slots } = task;

        return (
            slots?.find(
                (slot: any) => slot.date === date && slot.time_from === time_from && slot.time_to === time_to
            ) || null
        );
    };

    const isMeeting = data?.phase_tasks?.[0]?.action === 'define_phase_meeting';
    const isStatusNeedApproveTR = data?.phase_tasks?.[0]?.status === 'need_approve_tr';
    const isQuestionary = data?.phase_tasks?.[0]?.action === 'define_phase_questionnaire';
    const isMandat = data?.phase_tasks?.[0]?.action === 'define_tender_mandate';

    const isTaskDeadlineForSU = (isMeeting && !isStatusNeedApproveTR && !isTheSameSlots()) || isQuestionary || isMandat;

    return (
        <>
            {contextHolder}
            <div className={`tendermain__block tasks`}>
                {role !== 'school_user' && (
                    <div className="tasks__header define">
                        <div className="count-wrapper">
                            Task Centre
                            <div className="count">{data?.phase_task_count}</div>
                        </div>
                        {data?.phase_tasks?.map((elem: taskProps) => {
                            if (elem.action === 'define_phase_meeting' && elem.status !== 'pending_su') {
                                if (!data?.phase_tasks[0].approved) {
                                    return (
                                        <div className="tasks__deadline-wrapper" key={elem.id}>
                                            <img src={attention} />
                                            {screenWidth > 500 ? 'Task' : ''} Deadline -{' '}
                                            {moment(data?.phase_deadline_at).format('YYYY-MM-DD')}
                                        </div>
                                    );
                                }
                            }
                            if (elem.action === 'define_phase_questionnaire') {
                                if (
                                    data?.phase_tasks[0].status === 'pending_tr' ||
                                    data?.phase_tasks[0].status === 'need_resend_tr'
                                ) {
                                    return (
                                        <div className="tasks__deadline-wrapper" key={elem.id}>
                                            <img src={attention} />
                                            {screenWidth > 500 ? 'Task' : ''} Deadline -{' '}
                                            {moment(data?.phase_deadline_at).format('YYYY-MM-DD')}
                                        </div>
                                    );
                                }
                            }
                            if (
                                elem.action === 'define_tender_mandate' &&
                                (elem?.status === 'pending_tr' || elem?.status === 'need_approve_tr')
                            ) {
                                return (
                                    <div className="tasks__deadline-wrapper" key={elem.id}>
                                        <img src={attention} />
                                        {screenWidth > 500 ? 'Task' : ''} Deadline -{' '}
                                        {moment(data?.phase_deadline_at).format('YYYY-MM-DD')}
                                    </div>
                                );
                            }
                        })}
                    </div>
                )}
                {role === 'school_user' && (
                    <div className="tasks__header define">
                        <div className="count-wrapper">
                            Task Centre
                            <div className="count">{data?.phase_task_count}</div>
                        </div>
                        {data?.phase_tasks?.map((elem: taskProps) => {
                            if (
                                (elem.action === 'define_tender_mandate' ||
                                    elem.action === 'define_phase_meeting' ||
                                    elem.action === 'define_phase_questionnaire') &&
                                data.phase_tasks[0]?.status !== 'need_approve_tr' &&
                                data?.tasks_deadline_at
                            ) {
                                return (
                                    <div className="tasks__deadline-wrapper" key={elem.id}>
                                        <img src={attention} />
                                        {screenWidth > 500 ? 'Task' : ''} Deadline -{' '}
                                        {moment(data?.tasks_deadline_at).format('YYYY-MM-DD')}
                                    </div>
                                );
                            }
                        })}
                    </div>
                )}

                {data?.phase_tasks?.length ? (
                    <div className="tasks__list">
                        {isTaskDeadlineForSU && role !== 'school_user' && data.status !== 'archive' && (
                            <div className="tasks__info-wrap info-wrap" style={{ margin: '0 0 16px 0' }}>
                                <div className="info-wrap__deadline">Task Deadline for School User</div>
                                <div className="info-wrap__status">
                                    {data?.tasks_deadline_at
                                        ? moment(data?.tasks_deadline_at).format('YYYY-MM-DD')
                                        : 'Not indicated'}
                                </div>
                                <div className="info-wrap__edit" onClick={() => setDeadlineModal(true)}>
                                    <img src={edit} />
                                    Edit
                                </div>
                            </div>
                        )}
                        {role === 'school_user' &&
                            ((data.phase_tasks?.[0]?.action === 'define_phase_questionnaire' &&
                                data.phase_tasks?.[0]?.status === 'pending_tr') ||
                                (data.phase_tasks?.[0]?.action === 'define_phase_meeting' &&
                                    data.phase_tasks?.[0]?.status === 'need_approve_tr') ||
                                data.phase_tasks?.[0]?.status === 'declined' ||
                                data?.phase_tasks?.[0]?.status === 'completed' ||
                                data?.phase_tasks?.[0]?.status === 'pending_tr' ||
                                (data?.phase_tasks?.[0]?.action === 'define_tender_mandate' &&
                                    data?.phase_tasks?.[0]?.status === 'need_approve_tr')) && <AllTasksCompleted />}
                        <DefineMeetingTask
                            data={data}
                            role={role}
                            taskId={taskId}
                            setTaskId={setTaskId}
                            setIsCreateModal={setIsCreateModal}
                            sendActivitiesFunc={sendActivitiesFunc}
                            checked={checked}
                            setChecked={setChecked}
                            messageApi={messageApi}
                            setTrigger={setTrigger}
                            isCreateModal={isCreateModal}
                            sendTaskFunc={sendTaskFunc}
                            setApproveMeeting={setApproveMeeting}
                            isLoadingApproveTask={isLoadingApproveTask}
                            approveMeeting={approveMeeting}
                            setDeadlineModal={setDeadlineModal}
                        />
                        <DefineMandatTasks
                            data={data}
                            role={role}
                            setDeadlineModal={setDeadlineModal}
                            setTaskId={setTaskId}
                            setIsCreateTenderMandatModal={setIsCreateTenderMandatModal}
                            taskId={taskId}
                            messageApi={messageApi}
                            setTrigger={setTrigger}
                        />
                        <DefineQuestionnaireTask
                            data={data}
                            role={role}
                            setTaskId={setTaskId}
                            messageApi={messageApi}
                            setTrigger={setTrigger}
                            taskId={taskId}
                            setLoading={setLoading}
                        />
                        <DefineSupportingDocumentTask
                            data={data}
                            role={role}
                            setTaskId={setTaskId}
                            taskId={taskId}
                            messageApi={messageApi}
                            setTrigger={setTrigger}
                            isCreateModal={isCreateModal}
                            supportingModal={supportingModal}
                            setSupportingModal={setSupportingModal}
                        />
                    </div>
                ) : (
                    <>
                        {role === 'school_user' && (
                            <div className="noTasks">
                                <img src={WelcomeToDefine} />
                                <div className="noTasks__title">Welcome to the Define Phase!</div>
                                <div className="noTasks__subtitle">
                                    Watch this space - new tasks for you will be posted here as they become available.
                                </div>
                            </div>
                        )}
                    </>
                )}
                {role === 'tender_representative' && (
                    <>
                        {data.phase_tasks[0]?.action === 'define_phase_questionnaire' && (
                            <div className="tasks__btn-wrap">
                                <div></div>
                                <Tooltip
                                    title={
                                        data?.phase_tasks?.[0]?.status === 'pending_su'
                                            ? ''
                                            : !data?.can_send_task && !data?.tasks_deadline_at
                                            ? 'To send this task, please set a deadline for the school user first'
                                            : 'To send this task, you need complete all Task Activities'
                                    }
                                    overlayClassName={` ${
                                        !data?.can_send_task ? 'custom-tooltip-disabled' : 'custom-tooltip'
                                    }`}
                                >
                                    <Button
                                        className={`tasks__next-btn ${!data?.can_send_task ? 'disabled' : ''}`}
                                        type="primary"
                                        onClick={() => {
                                            if (!data?.can_send_task) {
                                                return;
                                            } else {
                                                sendTaskFunc();
                                            }
                                        }}
                                    >
                                        {isLoadingSendTask ? (
                                            <Loader />
                                        ) : (
                                            <div className="flex">
                                                {data.phase_tasks[0]?.approved ? 'Next Task' : 'Send Task'}{' '}
                                                <img src={arrow} style={{ margin: '0 0 0 10px' }} />
                                            </div>
                                        )}
                                    </Button>
                                </Tooltip>
                            </div>
                        )}
                        {data.phase_tasks[0]?.action === 'define_phase_meeting' &&
                            data.phase_tasks[0]?.status !== 'pending_su' &&
                            data?.phase_tasks?.[0]?.status !== 'need_approve_tr' && (
                                <>
                                    <div className="tasks__btn-wrap">
                                        <div></div>
                                        <Tooltip
                                            title={
                                                !data?.can_send_task
                                                    ? data?.tasks_deadline_at
                                                        ? 'To send this task, you need complete all Task Activities'
                                                        : 'To send this task, please set a deadline for the school user first'
                                                    : ''
                                            }
                                            overlayClassName={` ${
                                                !data?.can_send_task ? 'custom-tooltip-disabled' : 'custom-tooltip'
                                            }`}
                                        >
                                            <Button
                                                className={`tasks__next-btn ${
                                                    !data?.can_send_task || (data.phase_tasks[0].approved && !checked)
                                                        ? 'disabled'
                                                        : ''
                                                }`}
                                                type="primary"
                                                onClick={() => {
                                                    if (
                                                        !data?.can_send_task ||
                                                        (data.phase_tasks[0].approved && !checked)
                                                    ) {
                                                        return;
                                                    } else {
                                                        if (!data.phase_tasks[0].approved) {
                                                            setConfirmMeeting(true);
                                                        } else {
                                                            sendTaskFunc();
                                                        }
                                                    }
                                                }}
                                            >
                                                {!isTheSameSlots() ? 'Send Task' : 'Next Task'}
                                                <img src={arrow} />
                                            </Button>
                                        </Tooltip>
                                    </div>
                                </>
                            )}
                        {data.phase_tasks[0]?.action === 'define_phase_meeting' &&
                            data?.phase_tasks?.[0]?.status === 'need_approve_tr' && (
                                <>
                                    <div className="tasks__btn-wrap">
                                        <div></div>
                                        <Tooltip
                                            title={
                                                checked && (checked === 'no_show' || data?.can_send_task)
                                                    ? ''
                                                    : `The confirmation will be available after ${moment(
                                                          data?.phase_tasks?.[0]?.date
                                                      ).format('DD MMMM YYYY')}, ${
                                                          data?.phase_tasks?.[0]?.time_from
                                                      } - ${data?.phase_tasks?.[0]?.time_to}`
                                            }
                                            overlayClassName={` ${
                                                checked && data?.can_send_task
                                                    ? 'custom-tooltip'
                                                    : 'custom-tooltip-disabled'
                                            }`}
                                        >
                                            <Button
                                                className={`tasks__next-btn ${
                                                    checked && (checked === 'no_show' || data?.can_send_task)
                                                        ? ''
                                                        : 'disabled'
                                                }`}
                                                type="primary"
                                                onClick={() => {
                                                    if (checked && (checked === 'no_show' || data?.can_send_task)) {
                                                        sendTaskFunc();
                                                    }
                                                }}
                                            >
                                                Send Task
                                                <img src={arrow} />
                                            </Button>
                                        </Tooltip>
                                    </div>
                                </>
                            )}
                        {data.phase_tasks[0]?.action === 'define_tender_mandate' && !data.can_update_phase && (
                            <div className="tasks__btn-wrap">
                                {data.phase_tasks[0]?.status === 'pending_tr' && (
                                    <>
                                        <div
                                            className={`tasks__add_supporting $`}
                                            onClick={() => {
                                                setSupportingModal(true);
                                            }}
                                        >
                                            <img src={file} />
                                            Add Supporting Document
                                        </div>
                                    </>
                                )}
                                {data.phase_tasks[0]?.status === 'pending_su' && (
                                    <div
                                        className={`tasks__add_supporting ${
                                            data.phase_tasks[0]?.status === 'pending_su' ? 'disable' : ''
                                        }`}
                                        onClick={() => {
                                            if (data.phase_tasks[0]?.status === 'pending_su') {
                                                return;
                                            } else {
                                                setSupportingModal(true);
                                            }
                                        }}
                                    >
                                        <img src={data.phase_tasks[0]?.status === 'pending_su' ? fileGrey : file} />
                                        Add Supporting Document
                                    </div>
                                )}

                                <div></div>
                                <Tooltip
                                    style={{ width: '250px' }}
                                    title={
                                        !data?.can_send_task &&
                                        data.phase_tasks[0]?.status !== 'pending_su' &&
                                        data.phase_tasks[1]?.status !== 'pending_su' &&
                                        data.phase_tasks[0]?.status !== 'need_resend_su' &&
                                        data.phase_tasks[1]?.status !== 'need_resend_su'
                                            ? data?.tasks_deadline_at
                                                ? 'To send this task, you need complete all Task Activities'
                                                : 'To send this task, please set a deadline for the school user first'
                                            : ''
                                    }
                                    overlayClassName={` ${
                                        !data?.can_send_task ? 'custom-tooltip-disabled' : 'custom-tooltip'
                                    }`}
                                >
                                    <Button
                                        className={`tasks__next-btn ${!data?.can_send_task ? 'disabled' : ''}`}
                                        type="primary"
                                        onClick={() => {
                                            if (!data?.can_send_task) {
                                                return;
                                            } else {
                                                sendTaskFunc();
                                            }
                                        }}
                                    >
                                        {isLoadingSendTask ? (
                                            <Loader />
                                        ) : (
                                            <div className="flex">
                                                Send Task <img src={arrow} style={{ margin: '0 0 0 10px' }} />
                                            </div>
                                        )}
                                    </Button>
                                </Tooltip>
                            </div>
                        )}
                        {data.phase_tasks[0]?.action === 'define_tender_mandate' && data.can_update_phase && (
                            <div className="tasks__btn-wrap">
                                <div></div>
                                <Tooltip
                                    title="To move to the next Phase, you need to Complete all the tasks"
                                    overlayClassName={` ${
                                        !data?.can_update_phase ? 'custom-tooltip-disabled' : 'custom-tooltip'
                                    }`}
                                >
                                    <Button
                                        className={`tasks__next-btn ${data?.can_update_phase ? '' : 'disabled'}`}
                                        type="primary"
                                        onClick={() => {
                                            if (data?.can_update_phase) {
                                                setConfirmModal(true);
                                            } else {
                                                return;
                                            }
                                        }}
                                    >
                                        Next Phase <img src={arrow} />
                                    </Button>
                                </Tooltip>
                            </div>
                        )}
                    </>
                )}
                {role === 'school_user' && (
                    <>
                        {data.phase === 'define' &&
                            (data.phase_tasks[0]?.action === 'define_tender_mandate' ||
                                data.phase_tasks[0]?.action === 'define_supporting_document' ||
                                data.phase_tasks[0]?.action === 'define_phase_questionnaire' ||
                                (data.phase_tasks[0]?.action === 'define_phase_meeting' &&
                                    data.phase_tasks?.[0]?.status !== 'declined')) && (
                                <div className="tasks__btn-wrap">
                                    <div></div>
                                    <Tooltip
                                        title={
                                            data.phase_tasks[0]?.status === 'pending_tr' ||
                                            data.phase_tasks[0]?.status === 'need_approve_tr'
                                                ? ''
                                                : 'To Send Task, you need to Complete all activities'
                                        }
                                        overlayClassName={` ${
                                            !data?.can_send_task ? 'custom-tooltip-disabled' : 'custom-tooltip'
                                        }`}
                                    >
                                        <Button
                                            className={`tasks__next-btn ${!data?.can_send_task ? 'disabled' : ''}`}
                                            type="primary"
                                            onClick={() => {
                                                if (!data?.can_send_task) {
                                                    return;
                                                } else {
                                                    sendTaskFunc();
                                                }
                                            }}
                                        >
                                            {isLoadingSendTask ? (
                                                <Loader />
                                            ) : (
                                                <div className="flex">
                                                    Submit <img src={arrow} style={{ margin: '0 0 0 10px' }} />
                                                </div>
                                            )}
                                        </Button>
                                    </Tooltip>
                                </div>
                            )}
                    </>
                )}
            </div>

            {isCreateTenderMandatModal && (
                <UploadFileModal
                    openModal={isCreateTenderMandatModal}
                    setOpenModal={setIsCreateTenderMandatModal}
                    data={data}
                    taskId={taskId}
                    messageApi={messageApi}
                    setTrigger={setTrigger}
                    title="Tender Mandate Template"
                    subtitle="Upload a file to import tender mandate template"
                />
            )}

            {confirmMeeting && (
                <Modal
                    className="confirmModal"
                    centered
                    open={confirmMeeting}
                    width={660}
                    closeIcon={<></>}
                    footer={
                        <div
                            style={{
                                display: 'flex',
                                justifyItems: 'space-between',
                                gap: '16px',
                                width: '100%',
                                margin: '25px 0 0 0'
                            }}
                        >
                            <Button key="back" onClick={() => setConfirmMeeting(false)} block className="cancel">
                                Cancel
                            </Button>
                            <Button type="primary" style={{ width: '100%' }} onClick={sendTaskFunc}>
                                {isLoadingSendTask ? <Loader /> : 'Confirm'}
                            </Button>
                        </div>
                    }
                >
                    <div className="modal-confirm">
                        <div className="modal-confirm__subtitle" style={{ color: '#4D5E93' }}>
                            Are you sure you want to confirm the Define Phase Meeting on{' '}
                            <span>{data?.phase_tasks[0]?.date}</span> from{' '}
                            <span>{data?.phase_tasks[0]?.time_from}</span> to{' '}
                            <span>{data?.phase_tasks[0]?.time_to}</span>?
                        </div>
                    </div>
                </Modal>
            )}

            {isDeleteFileModal && (
                <Modal
                    className="confirmModal"
                    centered
                    open={isDeleteFileModal}
                    width={660}
                    closeIcon={<></>}
                    footer={
                        <div
                            style={{
                                display: 'flex',
                                justifyItems: 'space-between',
                                gap: '16px',
                                width: '100%',
                                margin: '25px 0 0 0'
                            }}
                        >
                            <Button key="back" onClick={() => setIsDeleteFileModal(false)} block className="cancel">
                                Cancel
                            </Button>
                            <button className="quit" style={{ width: '100%' }} onClick={deleteFileFunc}>
                                {isLoadingDeleteFile ? <Loader /> : 'Delete'}
                            </button>
                        </div>
                    }
                >
                    <div className="modal-confirm">
                        <div className="modal-confirm__subtitle" style={{ color: '#4D5E93' }}>
                            Are you sure you want to delete the file?
                        </div>
                    </div>
                </Modal>
            )}

            {deadlineModal && (
                <TaskDeadlineModal
                    openModal={deadlineModal}
                    setOpenModal={setDeadlineModal}
                    SelectedDate={SelectedDate}
                    setSelectedDate={setSelectedDate}
                    updateDeadlineFunc={updateDeadlineFunc}
                    isLoading={isLoading}
                />
            )}
        </>
    );
};
