import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Checkbox, Divider, Form } from 'antd';
import type { SelectProps } from 'antd';
import { useClickOutside } from '../../hooks/useClickOutSide';
import schoolImage from '../../assets/schoolImage.svg';
import arrow from '../../assets/arrow.svg';
import close from '../../assets/Close.svg';
import noResult from '../../assets/noResult.svg';
import defaultAvatar from '../../assets/DefaultAvatar.svg';
import './index.scss';

interface FloatSelectProps extends SelectProps {
    required: boolean;
    floatLabel: string;
    err: boolean;
    options: any;
    onChange: any;
    hasError: () => boolean;
    prefix: any;
    value: any[];
    deleteItem: (id: string | number) => void;
    deleteAllItems: Dispatch<SetStateAction<any[]>>;
    setTouched: (value: boolean) => void;
}

export const CustomMultiSelectCheckbox = (props: FloatSelectProps) => {
    const [openSelect, setOpenSelect] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);
    let {
        floatLabel,
        placeholder,
        value,
        required,
        err,
        options,
        onChange,
        disabled,
        prefix,
        hasError,
        deleteItem,
        deleteAllItems,
        setTouched
    } = props;
    if (!placeholder) placeholder = floatLabel;

    useClickOutside(ref, () => setOpenSelect(false));

    useEffect(() => {
        if (openSelect) {
            setTouched(true);
        }
    }, [openSelect]);

    const isOccupied = openSelect;

    const labelClass = isOccupied ? 'label as-label' : 'label as-placeholder';

    <img src={value ? '' : arrow} className={'image'} />;

    const x = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            style={{ margin: '0 0 -5px 0' }}
        >
            <path
                d="M6.66699 6.6665L13.3337 13.3332"
                stroke="#808CB2"
                stroke-width="1.7"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M13.3337 6.6665L6.66699 13.3332"
                stroke="#808CB2"
                stroke-width="1.7"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );

    return (
        <div className="float-label" ref={ref}>
            <div
                style={disabled ? { cursor: 'not-allowed' } : {}}
                className={`multiSelect with-checkbox ${
                    openSelect && value?.length === 0
                        ? 'multiSelect-no-value-open custom-select with-checkbox'
                        : !openSelect && value?.length > 0
                        ? 'multiSelect-no-value-close with-checkbox'
                        : openSelect && value?.length > 0
                        ? 'multiSelect-no-value-open custom-select with-checkbox'
                        : 'multiSelect-no-value-close custom-select with-checkbox'
                } ${err ? 'err' : ''} ${disabled ? 'disabled-select' : ''} ${
                    hasError() && !value?.length ? 'err' : ''
                }`}
                onClick={() => {
                    if (!openSelect && !disabled) {
                        setOpenSelect(true);
                    }
                }}
            >
                {!disabled && (
                    <>
                        {!!value?.length && !openSelect ? (
                            <img
                                src={close}
                                className={`customSelect_image`}
                                onClick={(e) => {
                                    e.stopPropagation();

                                    deleteAllItems([]);
                                }}
                            />
                        ) : (
                            <img
                                src={arrow}
                                className={`customSelect_image ${openSelect ? 'rotate' : ''}`}
                                onClick={(e) => {
                                    e.stopPropagation();

                                    setOpenSelect((prev) => !prev);
                                }}
                            />
                        )}
                    </>
                )}

                {prefix && <img src={prefix} className="prefix" />}

                <label className={`${labelClass} ${prefix ? 'prefix' : ''} with-checkbox-name`}>
                    {isOccupied ? floatLabel : placeholder} {required && <span className="red">*</span>}
                    {value?.length > 0 && !openSelect && (
                        <div className="multiSelect__values">
                            <div className="multiSelect__value">{value[0]?.name}</div>
                            {value.length > 1 && `(+${value.length - 1})`}
                        </div>
                    )}
                </label>

                {openSelect && (
                    <div>
                        <div className="multiSelect__options">
                            {!!options?.length && (
                                <div
                                    className="select-option custom-select with-checkbox"
                                    onClick={() => {
                                        if (value.length === options.length) {
                                            deleteAllItems([]);
                                        } else {
                                            onChange({ name: 'selectedAll', id: 'selectedAll' });
                                        }
                                    }}
                                >
                                    <Checkbox
                                        style={{ margin: '0 10px 0 0' }}
                                        checked={value.length === options.length}
                                    />{' '}
                                    <div className="select-option__wrap">
                                        <div className="select-option__name">Select All</div>
                                    </div>
                                </div>
                            )}
                            <Divider style={{ margin: '5px 20px', minWidth: '90%', width: '90%' }} />
                            {options?.length ? (
                                options?.map((elem: any) => {
                                    return (
                                        <div
                                            className="select-option custom-select with-checkbox"
                                            onClick={() => {
                                                onChange({ name: elem.name, id: elem.id });
                                            }}
                                        >
                                            <Checkbox
                                                style={{ margin: '0 10px 0 0' }}
                                                checked={value.some((item) => item.id === elem.id)}
                                            />
                                            <div className="select-option__wrap with-checkbox">
                                                <div className="select-option__name">{elem.name}</div>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="customSelect__no-result">
                                    <img src={noResult} />
                                    <div>No results found</div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
