import { Tooltip } from 'antd';
import gradeIcon from '../../../assets/GradeIcon.svg';

export const ScoreHistory = (item: any) => {
    const classNameOfGrade = (key: string) => {
        if (key === 'A') {
            return 'light-Green';
        }
        if (key === 'B') {
            return 'Dark-Green';
        }
        if (key === 'C') {
            return 'Yellow';
        }
        if (key === 'D') {
            return 'Orange';
        }
        if (key === 'F') {
            return 'Brown';
        }
        if (key === 'E') {
            return 'Red';
        }
    };

    return (
        <Tooltip
            color="#fff"
            key={'#fff'}
            overlayClassName="history"
            title={
                <div className="grade-history">
                    Score History
                    {item.grade_history?.map((grade: any) => {
                        return (
                            <div className="grade-history__item">
                                <div className="grade-history__title">{grade.phase_name}</div>
                                <div className="grade-history__value">
                                    {grade.grade.value_format === 'Pass' ? 'Pass' : grade.grade.value_format}
                                    {grade?.grade?.key !== 'pass' && (
                                        <div className={`grade-history__key ${classNameOfGrade(grade?.grade?.key)}`}>
                                            {grade?.grade?.key}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            }
        >
            <div className="grade__icon_wrapper">
                <img src={gradeIcon} />
            </div>
        </Tooltip>
    );
};
