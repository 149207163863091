import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/logoForHeader.svg';
import logoPhone from '../../assets/logo-width-to600.svg';
import { Button, Checkbox, Divider, Form, Tooltip } from 'antd';
import general from '../../assets/generalIcon.svg';
import supplierCreated from '../../assets/supplierCreated.svg';
import { useLazyAddTenderToSupplierQuery, useLazyCheckSupplierQuery } from '../../api/users';
import { FloatInput } from '../../components/Fields/FloatInput';
import { yupSync } from '../../utils';
import { PhoneNumberInput } from '../../components/Fields/PhoneNumberInput';
import * as Yup from 'yup';
import { usePreventSpaceTyping } from '../../hooks/usePreventSpaceTyping';
import { Loader } from '../../components/Loader/Loader';
import i from '../../assets/i.svg';
import Plus from '../../assets/Plus, Add.svg';
import '../SignUp/index.scss';
import Delete from '../../components/Icons/Delete';
import { FloatTextArea } from '../../components/Fields/FloatTextArea';
import { downloadTPA } from '../../api/axiosCruds';

interface ReferenceI {
    entity_name: string;
    contact_name: string;
    position_title: string;
    email: string;
    phone_number: string;
}

const validationSchema = Yup.object().shape({
    registrar_name: Yup.string().required('This is a required field'),
    name: Yup.string().required('This is a required field'),
    comment: Yup.string()
        .required('This is a required field')
        .test('comment', 'This is a required field', (value) => {
            const passwordRegex = /[^\s]/;
            return passwordRegex.test(value);
        }),
    email: Yup.string().email('Enter your email: e.g. email@domain.com').required('Email field is required'),
    phone_number: Yup.string()
        .required('This is a required field')
        .matches(/^[^_]*$/, 'This is a required field')
});

export const SuppSelfRegistration = () => {
    const [references, setReferences] = useState<ReferenceI[]>([]);
    const [referencesError, setReferencesError] = useState<number[]>([]);
    const [addTenderToSupplier, { isLoading, isSuccess, isError, error }] = useLazyAddTenderToSupplierQuery();
    const [checkSupplier, { data: dataSupplier, isError: isErrorCheckSupplier, error: errorCheckSupplier }] =
        useLazyCheckSupplierQuery();
    const location = useLocation();
    const [step, setStep] = useState('general');
    const [form] = Form.useForm();
    const { handleKeyPress } = usePreventSpaceTyping();
    const [relationship, setRelationShip] = useState('');
    const [relationshipError, setRelationShipError] = useState(false);
    const [checkbox, setCheckbox] = useState(false);
    const [isFirstClick, setIsFirstClick] = useState(true);
    const navigate = useNavigate();
    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);
    function handleResize() {
        setScreenWidth(window.screen.width);
    }
    window.addEventListener('resize', handleResize);
    const [email, setEmail] = useState('');

    useEffect(() => {
        if (location.search) {
            checkSupplier(location.search);
        }
    }, [location]);

    const handleInputChange = (index: number, e: React.ChangeEvent<HTMLInputElement>, field: keyof ReferenceI) => {
        const indexToRemove = referencesError.indexOf(index);
        const newErrors = [...referencesError];

        if (indexToRemove !== -1) {
            newErrors.splice(indexToRemove, 1);
            setReferencesError(newErrors);
        }
        setReferences((prevReferences) => {
            const updatedReferences = [...prevReferences];
            updatedReferences[index] = {
                ...updatedReferences[index],
                [field]: e.target.value
            };
            return updatedReferences;
        });
    };

    const downloadTpaFunc = () => {
        // if (dataSupplier?.data?.tpa) {
        downloadTPA(location.search).then((res) => {
            const blobUrl = window.URL.createObjectURL(res?.data);
            window.open(blobUrl, '_blank');
        });
        // }
        //  else {
        //     window.open(dataSupplier?.data?.tpa_link, '_blank');
        // }
    };

    const deleteRef = (index: number) => {
        const array = [...references];
        array.splice(index, 1);
        setReferences(array);
    };

    useEffect(() => {
        if (isSuccess) {
            setStep('supplier_created');
        }
    }, [isSuccess]);

    useEffect(() => {
        //@ts-ignore
        if (isError && error?.status === 403) {
            navigate('/expired');
        }
        //@ts-ignore
        if (errorCheckSupplier && errorCheckSupplier?.status === 403) {
            navigate('/expired?action=supplier_self_registration');
        }
    }, [isError, error, errorCheckSupplier, isErrorCheckSupplier]);

    const handleSubmit = () => {
        let isValid = true;
        if (!relationship) {
            setRelationShipError(true);
        }
        const refWithErrors: number[] = [];
        references?.forEach((elem: ReferenceI, index: number) => {
            if (!elem.contact_name || !elem.email || !elem.entity_name || !elem.phone_number || !elem.position_title) {
                isValid = false;
                refWithErrors.push(index);
            }
        });
        setReferencesError(refWithErrors);

        form.validateFields()
            .then((values: any) => {
                if (!isValid) return;
                if (step === 'general') {
                    const data = {
                        signature: location.search,
                        data: {
                            registrar_name: values.registrar_name,
                            manager: {
                                name: values.name,
                                email: values.email,
                                phone_number: values.phone_number
                            },
                            references: references,
                            has_school_relationships: relationship === 'Yes' ? true : false,
                            comment: values.comment
                        }
                    };
                    addTenderToSupplier(data);
                }

                if (step === 'contact_info') {
                }
            })
            .catch(() => {});
    };

    return (
        <>
            <div className="signup">
                <div className="signup__header header">
                    {screenWidth > 600 && <img src={logo} className="header__logo" />}
                    {screenWidth <= 600 && <img src={logoPhone} className="header__logo" />}
                </div>
                <div className="signup__main main">
                    {step === 'general' && (
                        <div className="main__block">
                            <div className="main__form form">
                                <div className="form__title">Tender self-registration</div>

                                <div className="form__subtitle">
                                    Hello {dataSupplier?.data?.company?.name}. Please provide the contact information of
                                    the individual who will be managing this tender on behalf of your company
                                </div>

                                <Form form={form} onFinish={handleSubmit} validateTrigger="onBlur">
                                    <div className="signup__fields fields">
                                        <div className="fields__row">
                                            <div className="fields__title" style={{ margin: '15px 0 0 0' }}>
                                                Tender Info
                                            </div>
                                        </div>
                                        <div className="fields__tender-info tender-info">
                                            <div className="tender-info__row">
                                                <div className="tender-info__title">School</div>
                                                <div className="tender-info__value">
                                                    <div>{dataSupplier?.data?.school?.name}</div>
                                                    <div className="tender-info__add-value">
                                                        {dataSupplier?.data?.school?.address}
                                                    </div>
                                                </div>
                                            </div>
                                            <Divider />
                                            <div className="tender-info__row">
                                                <div className="tender-info__title">Expense Area</div>
                                                <div className="tender-info__value">
                                                    {dataSupplier?.data?.expense_area}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="fields__block">
                                            <div className="fields__title" style={{ margin: '32px 0 16px 0' }}>
                                                Your Details
                                            </div>
                                            <Form.Item
                                                className="input-wrapper"
                                                name="registrar_name"
                                                rules={yupSync('registrar_name', validationSchema, true)}
                                            >
                                                <FloatInput
                                                    floatLabel="Full Name"
                                                    className="input"
                                                    maxLength={128}
                                                    onBlur={() => yupSync('registrar_name', validationSchema, true)}
                                                />
                                            </Form.Item>
                                            <div className="fields__row">
                                                <div className="fields__title" style={{ margin: '16px 0' }}>
                                                    Tender Manager
                                                </div>
                                                <div className="fields__subtitle">All fields are mandatory</div>
                                            </div>
                                            <Form.Item
                                                className="input-wrapper"
                                                name="name"
                                                rules={yupSync('name', validationSchema, true)}
                                            >
                                                <FloatInput floatLabel="Full Name" className="input" maxLength={128} />
                                            </Form.Item>
                                            <Form.Item
                                                className="input-wrapper"
                                                name="email"
                                                rules={yupSync('email', validationSchema, true)}
                                            >
                                                <FloatInput
                                                    floatLabel="Email"
                                                    className="input"
                                                    onInput={(e: any) => setEmail(e.target.value)}
                                                    maxLength={60}
                                                    onKeyPress={handleKeyPress}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                className="input-wrapper"
                                                name="phone_number"
                                                rules={yupSync('phone_number', validationSchema, true)}
                                            >
                                                <PhoneNumberInput
                                                    floatLabel="Phone Number"
                                                    className="input"
                                                    maxLength={256}
                                                    value={''}
                                                    placeholder={undefined}
                                                    required={undefined}
                                                    onChange={() => {}}
                                                    hasError={() => !!form.getFieldError('phone_number').length}
                                                    onBlur={() => yupSync('phone_number', validationSchema, true)}
                                                />
                                            </Form.Item>
                                            <div className="fields__title" style={{ margin: '32px 0 16px 0' }}>
                                                References{' '}
                                                <Tooltip
                                                    title={
                                                        <div style={{ fontWeight: '400' }}>
                                                            You may provide up to three references. <br />
                                                            <br />
                                                            Please ensure they are relevant to the scope of this tender
                                                            and, ideally located within the same city or province. These
                                                            references may be contacted by the school as part of the
                                                            evaluation process
                                                        </div>
                                                    }
                                                    overlayClassName={'custom-tooltip-disabled'}
                                                >
                                                    <img
                                                        src={i}
                                                        className="supplier__i"
                                                        style={{ margin: '0 0 -5px 10px' }}
                                                    />
                                                </Tooltip>
                                            </div>

                                            {references.map((ref: ReferenceI, index: number) => {
                                                return (
                                                    <>
                                                        <div
                                                            className={`references ${
                                                                referencesError.includes(index) ? 'error' : ''
                                                            }`}
                                                        >
                                                            <div className="references__title">
                                                                Reference {index + 1}
                                                                <span onClick={() => deleteRef(index)}>
                                                                    <Delete
                                                                        fill="#E80A0F"
                                                                        style={{ cursor: 'pointer' }}
                                                                    />
                                                                </span>
                                                            </div>
                                                            <Divider
                                                                style={{ margin: '12px 0px 17px -17px', width: '107%' }}
                                                            />
                                                            <Form.Item className="input-wrapper">
                                                                <FloatInput
                                                                    floatLabel="Name of Entity"
                                                                    className="input"
                                                                    maxLength={128}
                                                                    value={ref.entity_name}
                                                                    onChange={(e: any) =>
                                                                        handleInputChange(index, e, 'entity_name')
                                                                    }
                                                                    type="custom"
                                                                />
                                                            </Form.Item>
                                                            <Form.Item className="input-wrapper">
                                                                <FloatInput
                                                                    floatLabel="Contact Person"
                                                                    className="input"
                                                                    maxLength={128}
                                                                    value={ref.contact_name}
                                                                    onChange={(e: any) =>
                                                                        handleInputChange(index, e, 'contact_name')
                                                                    }
                                                                    type="custom"
                                                                />
                                                            </Form.Item>
                                                            <Form.Item className="input-wrapper">
                                                                <FloatInput
                                                                    floatLabel="Position Title"
                                                                    className="input"
                                                                    maxLength={128}
                                                                    value={ref.position_title}
                                                                    onChange={(e: any) =>
                                                                        handleInputChange(index, e, 'position_title')
                                                                    }
                                                                    type="custom"
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                className="input-wrapper"
                                                                name={`email${index}`}
                                                                rules={[
                                                                    {
                                                                        type: 'email',
                                                                        message: 'Please enter a valid email address'
                                                                    }
                                                                ]}
                                                            >
                                                                <FloatInput
                                                                    floatLabel="Email"
                                                                    className="input"
                                                                    maxLength={60}
                                                                    onKeyPress={handleKeyPress}
                                                                    value={ref.email}
                                                                    onChange={(e: any) =>
                                                                        handleInputChange(index, e, 'email')
                                                                    }
                                                                    type="custom"
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                className="input-wrapper"
                                                                name={`phone${index}`}
                                                                rules={[
                                                                    {
                                                                        validator: async (_, value) => {
                                                                            const schema = Yup.string().matches(
                                                                                /^[^_]*$/,
                                                                                'Please enter a valid phone number'
                                                                            );
                                                                            await schema.validate(value);
                                                                        }
                                                                    }
                                                                ]}
                                                            >
                                                                <PhoneNumberInput
                                                                    floatLabel="Phone Number"
                                                                    className="input"
                                                                    maxLength={256}
                                                                    value={ref.phone_number}
                                                                    placeholder={undefined}
                                                                    required={undefined}
                                                                    onChange={(e: any) =>
                                                                        handleInputChange(index, e, 'phone_number')
                                                                    }
                                                                    mask={'099 999 9999'}
                                                                    hasError={() =>
                                                                        !!form.getFieldError('phone_number').length
                                                                    }
                                                                    onBlur={() =>
                                                                        yupSync('phone_number', validationSchema, true)
                                                                    }
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                        {referencesError.includes(index) && (
                                                            <div
                                                                className="error-text"
                                                                style={{ margin: '-15px 0 10px 0' }}
                                                            >
                                                                These are required fields
                                                            </div>
                                                        )}
                                                    </>
                                                );
                                            })}
                                            {references.length >= 3 ? (
                                                <></>
                                            ) : (
                                                <div
                                                    className="fields__addReference"
                                                    onClick={() => {
                                                        setReferences([
                                                            ...references,
                                                            {
                                                                entity_name: '',
                                                                contact_name: '',
                                                                position_title: '',
                                                                email: '',
                                                                phone_number: ''
                                                            }
                                                        ]);
                                                    }}
                                                >
                                                    <img src={Plus} />
                                                    Add Reference
                                                </div>
                                            )}
                                            <div
                                                className={`fields__relationship relationship ${
                                                    relationshipError ? 'error' : ''
                                                }`}
                                            >
                                                <div className="relationship__title">
                                                    To the best of your knowledge, are any directors or executives
                                                    within your organisation affiliated in any capacity with the school,
                                                    either directly or indirectly, which could present a potential
                                                    conflict of interest?
                                                </div>
                                                <div className="relationship__subtitle">
                                                    This information will be shared with the school to ensure full
                                                    transparency and support a fair evaluation process.
                                                </div>
                                                <div className="relationship__row">
                                                    <div
                                                        className={`relationship__btn ${
                                                            relationship === 'No' ? 'active' : ''
                                                        }`}
                                                        onClick={() => {
                                                            setRelationShip('No');
                                                            setRelationShipError(false);
                                                        }}
                                                    >
                                                        No
                                                    </div>
                                                    <div
                                                        className={`relationship__btn ${
                                                            relationship === 'Yes' ? 'active' : ''
                                                        }`}
                                                        onClick={() => {
                                                            setRelationShip('Yes');
                                                            setRelationShipError(false);
                                                        }}
                                                    >
                                                        Yes
                                                    </div>
                                                </div>
                                                {relationship === 'Yes' && (
                                                    <Form.Item
                                                        className="input-wrapper"
                                                        name="comment"
                                                        rules={yupSync('comment', validationSchema, true)}
                                                    >
                                                        <FloatTextArea
                                                            value={''}
                                                            onChange={() => {}}
                                                            maxLength={200}
                                                            hasError={() => !!form.getFieldError(['comment']).length}
                                                            bordered={true}
                                                            floatLabel={'Provide Details'}
                                                            resize={true}
                                                        />
                                                    </Form.Item>
                                                )}
                                            </div>
                                            {relationshipError && (
                                                <div className="error-text" style={{ margin: '-25px 0 20px 0' }}>
                                                    These are required action
                                                </div>
                                            )}
                                            <div className="fields__row" style={{ alignItems: 'start' }}>
                                                <Checkbox
                                                    checked={checkbox}
                                                    onChange={() => {
                                                        if (isFirstClick) {
                                                            downloadTpaFunc();
                                                            setIsFirstClick(false);
                                                        } else {
                                                            setCheckbox((prev) => !prev);
                                                        }
                                                    }}
                                                ></Checkbox>
                                                <div className="fields__checkbox_text">
                                                    By clicking here, you re-confirm your understanding of the
                                                    obligations under the signed{' '}
                                                    <span
                                                        onClick={() => {
                                                            downloadTpaFunc();
                                                        }}
                                                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                                    >
                                                        Tender Participation Agreement
                                                    </span>
                                                    , including any fees payable to SchoolAdvisor if your company is
                                                    appointed by the school.
                                                </div>
                                            </div>
                                        </div>

                                        <Button
                                            className="fields__btn"
                                            type="primary"
                                            disabled={checkbox && relationship ? false : true}
                                            onClick={handleSubmit}
                                            loading={isLoading}
                                        >
                                            {isLoading ? <Loader /> : 'Submit'}
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    )}

                    {step === 'supplier_created' && (
                        <div className="main__block">
                            <div className="main__form form">
                                <div className="form__title" style={{ margin: '0 0 40px 0' }}>
                                    Your registration has been successfully received!
                                </div>
                                <div className="signup__fields fields">
                                    <div className="fields__row">
                                        <div className="fields__title">To complete your registration:</div>
                                    </div>
                                    <ul>
                                        <li className="signup__li">
                                            Your appointed tender manager will receive an email at the provided address:
                                            <b> {email}</b>. They must confirm their email address to finalise
                                            registration.
                                        </li>
                                        <li className="signup__li">
                                            Once confirmed, your registration will be complete, and your company will be
                                            added to the supplier list for this tender.{' '}
                                        </li>
                                    </ul>
                                    <div className="signup__addtext">
                                        Please note, all future correspondence regarding this tender will be directed to
                                        your appointed tender manager.
                                    </div>
                                    <div className="signup__addtext">
                                        If you have any questions, please contact the supplier onboarding team at
                                        supplier.onboarding@schooladvisor.co.za
                                    </div>
                                    <div className="signup__addtext">or contact us directly at +27 (0) 21 565 0401</div>
                                </div>
                                <Button
                                    className="fields__btn"
                                    type="primary"
                                    onClick={() => (window.location.href = 'https://schooladvisor.co.za')}
                                >
                                    Back Home
                                </Button>
                            </div>
                        </div>
                    )}

                    <div className={`main__block ${screenWidth < 1280 ? 'image' : ''}`}>
                        <div className={step === 'general' ? 'general' : 'password'}>
                            {step === 'general' && <img src={general} className="image__item" />}
                            {step === 'supplier_created' && <img src={supplierCreated} className="image__item" />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
