import { ChangeEvent, FC, SetStateAction, useEffect, useState } from 'react';
import { PartI, SupplierI, taskProps, tenderDataProps } from '../../../pages/TenderViewPage/types';
import { downloadFileFunc, downloadProfileSummary } from '../../../api/axiosCruds';
import edit from '../../../assets/editAdminIcon.svg';
import { useUpdateGradeMutation, useUpdateTaskMutation } from '../../../api/tenders';
import calendar from '../../../assets/Calendar, Schedule, Clock.svg';
import pendingIcon from '../../../assets/panding-circle.svg';
import SupplierListIcon from '../../../assets/SupplierListIcon.svg';
import arrow from '../../../assets/arrow.svg';
import gradeHistory from '../../../assets/gradeHistory.svg';
import messageIcon from '../../../assets/chat-message.svg';
import Visit from '../../../assets/Visit.svg';

import attantion from '../../../assets/point.svg';
import gradeIcon from '../../../assets/GradeIcon.svg';
import editIcon from '../../../assets/editAdminIcon.svg';
import download from '../../../assets/download.svg';

import messageGrey from '../../../assets/messageGrey.svg';
import SupplierIcon from '../../../assets/SupplierIcon.svg';
import downloadIcon from '../../../assets/Documents, File, Download.svg';
import { SupplierInfoModal } from '../../Modals/SupplierInfoModal';
import { ClockIcon } from '../../Icons/ClockIcon';
import { NoShowModal } from '../../Modals/NoShowModal';
import Unverified from '../../../assets/Unverified.svg';
import Verified from '../../../assets/Verified.svg';
import { EditSupplierModal } from '../../Modals/EditSupplierModal';
import { ParticipantsModal } from '../../Modals/ParticipantsModal';
import { Divider, Tooltip } from 'antd';

interface DefineSupportingDocumentTaskProps {
    data: tenderDataProps;
    role: string | null;
    messageApi: any;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    setDeadlineModal: React.Dispatch<React.SetStateAction<boolean>>;
    isSuccess: boolean;
}

const star = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path
                d="M3.86361 10.4163C3.61007 10.5486 3.30335 10.5253 3.07271 10.3562C2.84208 10.1871 2.72765 9.90153 2.77765 9.61994L3.18232 7.30047L1.47861 5.66779C1.27036 5.46919 1.19404 5.16899 1.28216 4.89505C1.37028 4.62111 1.60731 4.4217 1.89228 4.38175L4.25777 4.04361L5.32571 1.91323C5.45234 1.65773 5.71284 1.49609 5.99799 1.49609C6.28315 1.49609 6.54365 1.65773 6.67027 1.91323L7.73822 4.04361L10.1037 4.38175C10.3887 4.4217 10.6257 4.62111 10.7138 4.89505C10.8019 5.16899 10.7256 5.46919 10.5174 5.66779L8.81367 7.30047L9.21834 9.62044C9.26834 9.90203 9.15391 10.1876 8.92328 10.3567C8.69264 10.5258 8.38592 10.5491 8.13238 10.4168L5.99799 9.31381L3.86361 10.4163Z"
                fill="#E3A11F"
                stroke="#E3A11F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export const SupplierCard: FC<DefineSupportingDocumentTaskProps> = ({
    data,
    role,
    messageApi,
    setTrigger,
    setDeadlineModal,
    isSuccess
}) => {
    const [isSupplierInfoModal, setIsSupplierInfoModal] = useState<boolean>(false);
    const [taskId, setTaskId] = useState<number | null>(null);
    const [isEditSupplier, setIsEditSupplier] = useState(false);
    const [noShowModal, setNoShowModal] = useState(false);
    const [updateTask, { isLoading: isLoadingTookPlace }] = useUpdateTaskMutation();
    const [grades, setGrades] = useState<{ id: number; grade: any }[]>([]);
    const [updateGrade, { isSuccess: isSuccessUpdatedGrade }] = useUpdateGradeMutation();
    const [SupplierData, setSupplierData] = useState<any>(null);
    const [isEditModal, setIsEditModal] = useState(false);

    const [openState, setOpenState] = useState<{ id: number; open: boolean }[]>([]);

    useEffect(() => {
        if (isSuccessUpdatedGrade) {
            setTrigger((prev) => !prev);
        }
    }, [isSuccessUpdatedGrade]);

    useEffect(() => {
        if (data) {
            const grades = data?.suppliers?.map((elem) => ({
                id: elem.id,
                grade: elem?.grade?.value ? elem?.grade?.value : null
            }));
            setGrades(grades);

            const opens = data?.suppliers?.map((elem) => ({
                id: elem.id,
                open: false
            }));
            setOpenState(opens);
        }
    }, [data]);

    useEffect(() => {
        if (data) {
            const grades = data?.suppliers?.map((elem) => ({
                id: elem.id,
                grade: elem?.grade?.value ? elem?.grade?.value : null
            }));
            setGrades(grades);
        }
    }, [data]);

    const updateTaskFunc = async (id: number) => {
        const formData = {
            ids: {
                tender_id: data?.id,
                task_id: id
            },
            data: { action: 'supplier_took_place' }
        };
        try {
            await updateTask(formData).unwrap();
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    const isToolTip = (className: string): boolean => {
        const element = document.querySelector(className);

        if (element) {
            const isTextOverflowing = element.scrollWidth > element.clientWidth;

            return isTextOverflowing;
        }

        return false;
    };

    const downloadFunc = (fileId: string, taskId: number) => {
        downloadFileFunc({
            tenderId: data.id,
            taskId: taskId,
            fileId: fileId
        })
            .then((response: any) => {
                const stplitArray = response.headers['content-disposition'].split(';');
                const element = stplitArray.find((elem: string) => elem.includes('filename'));
                const name = element.split('=')[1];
                downloadPDF(response, name);
            })
            .catch(() => {
                messageApi.error('Downloading Error. Check your internet connection');
            });
    };

    const downloadPDF = (response: any, filename: any) => {
        let blob = new Blob([response.data], {
            type: response.headers['content-type']
        });

        if (filename.includes('pdf')) {
            let headerLine = response.headers['content-disposition'];

            if (headerLine) {
                let startFileNameIndex = headerLine.indexOf('"') + 1;
                let endFileNameIndex = headerLine.lastIndexOf('"');
                filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
            }
        }

        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        let fileName = filename.replace(/"/g, '').trim();
        link.download = fileName;
        link.target = '_blank';

        const el = document.body;
        el.appendChild(link);

        link.click();
        setTrigger((prev: boolean) => !prev);
        messageApi.success('File has been downloaded successfully');
    };

    const statusImage = (status: string) => {
        if (status === 'Unverified') {
            return Unverified;
        }
        if (status === 'Verified') {
            return Verified;
        }
    };

    const handleKeyPressNumbersOnly = (e: ChangeEvent<HTMLInputElement>) => {
        let input = e.target.value;
        const onlyNumbers = input.replace(/[^0-9]/g, '');
        const limitedValue = parseInt(onlyNumbers, 10) > 100 ? '100' : onlyNumbers;
        e.target.value = limitedValue;
    };

    const classNameOfGrade = (key: string) => {
        if (key === 'A') {
            return 'light-Green';
        }
        if (key === 'B') {
            return 'Dark-Green';
        }
        if (key === 'C') {
            return 'Yellow';
        }
        if (key === 'D') {
            return 'Orange';
        }
        if (key === 'F') {
            return 'Brown';
        }
        if (key === 'E') {
            return 'Red';
        }
    };

    return (
        <>
            {data?.phase_tasks.length &&
                data?.phase_tasks.map((elem: taskProps) => {
                    if (elem.action === 'evaluate_3_supplier') {
                        if (role !== 'school_user') {
                            if (elem.status === 'pending_tr' && !elem?.approved && !elem.participants?.length) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                Supplier Shortlist
                                            </div>
                                        </div>
                                        {data.suppliers.map((item) => {
                                            if (elem.supplier_id === item.id) {
                                                return (
                                                    <div className="task-complete__supplier supplier ">
                                                        <div className="supplier__header with-switcher">
                                                            <div className="supplier__wrapper">
                                                                <img src={statusImage(item.company_status_name)} />{' '}
                                                                {item.company_status_name}
                                                            </div>
                                                            {item.incumbent && (
                                                                <div className="supplier__incumbent">
                                                                    <img src={attantion} />
                                                                    Incumbent Supplier
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="supplier__row">
                                                            <div className="supplier__wrap first">
                                                                <div className="supplier__title">
                                                                    ({item.name_prefix}) {item.name}
                                                                </div>
                                                                <div className="supplier__subtitle">
                                                                    {item.trading_name}
                                                                </div>
                                                            </div>
                                                            {item.manager_name ? (
                                                                <div className="supplier__wrap">
                                                                    <div className="supplier__title">
                                                                        {item.manager_name ? item.manager_name : ''}
                                                                    </div>
                                                                    <div className="supplier__subtitle">
                                                                        {item?.email ? item?.email : ''}{' '}
                                                                        {item?.phone_number ? item?.phone_number : ''}
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="supplier__wrap"></div>
                                                            )}

                                                            <Tooltip
                                                                color="#fff"
                                                                key={'#fff'}
                                                                overlayClassName="history"
                                                                title={
                                                                    <div className="grade-history">
                                                                        Grade History
                                                                        {item.grade_history?.map((grade) => {
                                                                            return (
                                                                                <div className="grade-history__item">
                                                                                    <div className="grade-history__title">
                                                                                        {grade.phase_name}
                                                                                    </div>
                                                                                    <div className="grade-history__value">
                                                                                        {grade.grade.value_format ===
                                                                                        'Pass'
                                                                                            ? 'Pass'
                                                                                            : role !== 'school_user' &&
                                                                                              grade.grade.value_format}
                                                                                        {grade?.grade?.key !==
                                                                                            'pass' && (
                                                                                            <div
                                                                                                className={`grade-history__key ${classNameOfGrade(
                                                                                                    grade?.grade?.key
                                                                                                )}`}
                                                                                            >
                                                                                                {grade?.grade?.key}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                }
                                                            >
                                                                <img
                                                                    src={gradeHistory}
                                                                    style={{ margin: '0 10px 0 0 ', cursor: 'pointer' }}
                                                                />
                                                            </Tooltip>

                                                            <img
                                                                src={editIcon}
                                                                className="supplier__delete"
                                                                onClick={() => {
                                                                    setTaskId(item.id);
                                                                    setSupplierData({
                                                                        manager: {
                                                                            name: item.manager_name,
                                                                            email: item.email,
                                                                            phone_number: item.phone_number
                                                                        },
                                                                        company: {
                                                                            name: item.name
                                                                        },
                                                                        id: item.id
                                                                    });
                                                                    setIsEditModal(true);
                                                                }}
                                                            />
                                                        </div>
                                                        <div
                                                            className="supplier-SU__white-btn slots"
                                                            onClick={() => {
                                                                setTaskId(elem.id);
                                                                setIsEditSupplier(false);
                                                                setIsSupplierInfoModal(true);
                                                            }}
                                                        >
                                                            <img src={calendar} /> Slot for participants
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                return <></>;
                                            }
                                        })}
                                    </div>
                                );
                            }
                            if (elem.status === 'pending_tr' && !elem?.approved && elem.participants?.length) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete green`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                Supplier Shortlist
                                            </div>
                                        </div>
                                        {data.suppliers.map((item) => {
                                            if (elem.supplier_id === item.id) {
                                                return (
                                                    <div className="task-complete__supplier supplier ">
                                                        <div className="supplier__header with-switcher">
                                                            <div className="supplier__wrapper">
                                                                <img src={statusImage(item.company_status_name)} />{' '}
                                                                {item.company_status_name}
                                                            </div>
                                                            {item.incumbent && (
                                                                <div className="supplier__incumbent">
                                                                    <img src={attantion} />
                                                                    Incumbent Supplier
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="supplier__row">
                                                            <div className="supplier__wrap first">
                                                                <div className="supplier__title">
                                                                    ({item.name_prefix}) {item.name}
                                                                </div>
                                                                <div className="supplier__subtitle">
                                                                    {item.trading_name}
                                                                </div>
                                                            </div>
                                                            {item.manager_name ? (
                                                                <div className="supplier__wrap">
                                                                    <div className="supplier__title">
                                                                        {item.manager_name ? item.manager_name : ''}
                                                                    </div>
                                                                    <div className="supplier__subtitle">
                                                                        {item?.email ? item?.email : ''}{' '}
                                                                        {item?.phone_number ? item?.phone_number : ''}
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="supplier__wrap"></div>
                                                            )}

                                                            <Tooltip
                                                                color="#fff"
                                                                key={'#fff'}
                                                                overlayClassName="history"
                                                                title={
                                                                    <div className="grade-history">
                                                                        Grade History
                                                                        {item.grade_history?.map((grade) => {
                                                                            return (
                                                                                <div className="grade-history__item">
                                                                                    <div className="grade-history__title">
                                                                                        {grade.phase_name}
                                                                                    </div>
                                                                                    <div className="grade-history__value">
                                                                                        {grade.grade.value_format ===
                                                                                        'Pass'
                                                                                            ? 'Pass'
                                                                                            : role !== 'school_user' &&
                                                                                              grade.grade.value_format}
                                                                                        {grade?.grade?.key !==
                                                                                            'pass' && (
                                                                                            <div
                                                                                                className={`grade-history__key ${classNameOfGrade(
                                                                                                    grade?.grade?.key
                                                                                                )}`}
                                                                                            >
                                                                                                {grade?.grade?.key}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                }
                                                            >
                                                                <img
                                                                    src={gradeHistory}
                                                                    style={{
                                                                        margin: '0 10px 0 0 ',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                />
                                                            </Tooltip>

                                                            <img
                                                                src={editIcon}
                                                                className="supplier__delete"
                                                                onClick={() => {
                                                                    setTaskId(item.id);
                                                                    setSupplierData({
                                                                        manager: {
                                                                            name: item.manager_name,
                                                                            email: item.email,
                                                                            phone_number: item.phone_number
                                                                        },
                                                                        company: {
                                                                            name: item.name
                                                                        },
                                                                        id: item.id
                                                                    });
                                                                    setIsEditModal(true);
                                                                }}
                                                            />
                                                        </div>
                                                        <Divider style={{ margin: '12px 0' }} />
                                                        <div
                                                            className="task-complete__info-wrap"
                                                            style={{ margin: '12px', width: '96%' }}
                                                        >
                                                            <div className="task-complete__wrap">
                                                                <div
                                                                    className="task-complete__name"
                                                                    style={{ width: '140px', padding: '0' }}
                                                                >
                                                                    Meeting Date
                                                                </div>
                                                                <div className="task-complete__name">Time</div>
                                                            </div>
                                                            <div className="task-complete__wrap">
                                                                <div
                                                                    className="task-complete__value"
                                                                    style={{ width: '140px', padding: '0' }}
                                                                >
                                                                    <img src={calendar} />
                                                                    {elem.meeting_slot?.[0].date}
                                                                </div>
                                                                <div className="task-complete__value">
                                                                    <ClockIcon
                                                                        fill="#001965"
                                                                        style={{ margin: '0 8px 0 0' }}
                                                                    />
                                                                    {elem.meeting_slot?.[0].time_from} -{' '}
                                                                    {elem.meeting_slot?.[0].time_to}
                                                                </div>
                                                            </div>

                                                            <div
                                                                className="task-complete__wrap"
                                                                style={{ margin: '10px 0 0 0' }}
                                                            >
                                                                <div
                                                                    className="task-complete__name"
                                                                    style={{ width: '70px' }}
                                                                >
                                                                    Participants{' '}
                                                                </div>
                                                            </div>
                                                            <div className="task-complete__participants participants">
                                                                {elem.participants.map((part: PartI) => {
                                                                    return (
                                                                        <div className="participants__participant">
                                                                            <div className="participants__name tooltip">
                                                                                {part.full_name}
                                                                            </div>
                                                                            {isToolTip('.tooltip') && (
                                                                                <div className="hover-content">
                                                                                    {part.full_name}
                                                                                </div>
                                                                            )}
                                                                            <div className="participants__title tooltip">
                                                                                {part.title}
                                                                            </div>{' '}
                                                                            {isToolTip('.tooltip') && (
                                                                                <div className="hover-content">
                                                                                    {part.title}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>

                                                            {elem.files.tender_representative?.[0]?.name && (
                                                                <div className="task-complete__info">
                                                                    <div className="task-complete__wrap column">
                                                                        <div className="task-complete__name">
                                                                            Uploaded File
                                                                        </div>
                                                                        <div className="task-complete__value file">
                                                                            {elem.files.tender_representative[0]?.name}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {elem.comments?.tender_representative && (
                                                                <div className="task-complete__info">
                                                                    <div className="task-complete__wrap column">
                                                                        <div className="task-complete__name">
                                                                            My Comment
                                                                        </div>
                                                                        <div className="task-complete__value">
                                                                            {elem.comments.tender_representative}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {role !== 'super_admin' && (
                                                                <img
                                                                    src={edit}
                                                                    className="task-complete__edit"
                                                                    onClick={() => {
                                                                        setTaskId(elem.id);
                                                                        setIsEditSupplier(true);
                                                                        setIsSupplierInfoModal(true);
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                return <></>;
                                            }
                                        })}
                                    </div>
                                );
                            }

                            if (
                                (elem.status === 'need_approve_tr' || elem.status === 'pending_su') &&
                                !elem?.approved
                            ) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                Supplier Shortlist
                                            </div>
                                        </div>
                                        {data.suppliers.map((item) => {
                                            if (elem.supplier_id === item.id) {
                                                return (
                                                    <div className="task-complete__supplier supplier ">
                                                        <div className="supplier__header with-switcher">
                                                            <div className="supplier__wrapper">
                                                                <img src={statusImage(item.company_status_name)} />{' '}
                                                                {item.company_status_name}
                                                            </div>
                                                            {item.incumbent && (
                                                                <div className="supplier__incumbent">
                                                                    <img src={attantion} />
                                                                    Incumbent Supplier
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="supplier__row">
                                                            <div className="supplier__wrap first">
                                                                <div className="supplier__title">
                                                                    ({item.name_prefix}) {item.name}
                                                                </div>
                                                                <div className="supplier__subtitle">
                                                                    {item.trading_name}
                                                                </div>
                                                            </div>
                                                            {item.manager_name ? (
                                                                <div className="supplier__wrap">
                                                                    <div className="supplier__title">
                                                                        {item.manager_name ? item.manager_name : ''}
                                                                    </div>
                                                                    <div className="supplier__subtitle">
                                                                        {item?.email ? item?.email : ''}{' '}
                                                                        {item?.phone_number ? item?.phone_number : ''}
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="supplier__wrap"></div>
                                                            )}

                                                            <Tooltip
                                                                color="#fff"
                                                                key={'#fff'}
                                                                overlayClassName="history"
                                                                title={
                                                                    <div className="grade-history">
                                                                        Grade History
                                                                        {item.grade_history?.map((grade) => {
                                                                            return (
                                                                                <div className="grade-history__item">
                                                                                    <div className="grade-history__title">
                                                                                        {grade.phase_name}
                                                                                    </div>
                                                                                    <div className="grade-history__value">
                                                                                        {grade.grade.value_format ===
                                                                                        'Pass'
                                                                                            ? 'Pass'
                                                                                            : role !== 'school_user' &&
                                                                                              grade.grade.value_format}

                                                                                        {grade?.grade?.key !==
                                                                                            'pass' && (
                                                                                            <div
                                                                                                className={`grade-history__key ${classNameOfGrade(
                                                                                                    grade?.grade?.key
                                                                                                )}`}
                                                                                            >
                                                                                                {grade?.grade?.key}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                }
                                                            >
                                                                <img
                                                                    src={gradeHistory}
                                                                    style={{ margin: '0 10px 0 0 ', cursor: 'pointer' }}
                                                                />
                                                            </Tooltip>

                                                            <img
                                                                src={editIcon}
                                                                className="supplier__delete"
                                                                onClick={() => {
                                                                    setTaskId(item.id);
                                                                    setSupplierData({
                                                                        manager: {
                                                                            name: item.manager_name,
                                                                            email: item.email,
                                                                            phone_number: item.phone_number
                                                                        },
                                                                        company: {
                                                                            name: item.name
                                                                        },
                                                                        id: item.id
                                                                    });
                                                                    setIsEditModal(true);
                                                                }}
                                                            />
                                                        </div>
                                                        <Divider style={{ margin: '12px 0' }} />
                                                        <div
                                                            className="task-complete__info-wrap"
                                                            style={{ margin: '12px', width: '96%' }}
                                                        >
                                                            <div className="task-complete__wrap">
                                                                <div
                                                                    className="task-complete__name"
                                                                    style={{ width: '140px', padding: '0' }}
                                                                >
                                                                    Meeting Date
                                                                </div>
                                                                <div className="task-complete__name">Time</div>
                                                            </div>
                                                            <div className="task-complete__wrap">
                                                                <div
                                                                    className="task-complete__value"
                                                                    style={{ width: '140px', padding: '0' }}
                                                                >
                                                                    <img src={calendar} />
                                                                    {elem.meeting_slot?.[0].date}
                                                                </div>
                                                                <div className="task-complete__value">
                                                                    <ClockIcon
                                                                        fill="#001965"
                                                                        style={{ margin: '0 8px 0 0' }}
                                                                    />
                                                                    {elem.meeting_slot?.[0].time_from} -{' '}
                                                                    {elem.meeting_slot?.[0].time_to}
                                                                </div>
                                                            </div>

                                                            <div
                                                                className="task-complete__wrap"
                                                                style={{ margin: '10px 0 0 0' }}
                                                            >
                                                                <div
                                                                    className="task-complete__name"
                                                                    style={{ width: '70px' }}
                                                                >
                                                                    Participants{' '}
                                                                </div>
                                                            </div>
                                                            <div className="task-complete__participants participants">
                                                                {elem.participants.map((part: PartI) => {
                                                                    return (
                                                                        <div className="participants__participant">
                                                                            <div className="participants__name tooltip">
                                                                                {part.full_name}
                                                                            </div>
                                                                            {isToolTip('.tooltip') && (
                                                                                <div className="hover-content">
                                                                                    {part.full_name}
                                                                                </div>
                                                                            )}
                                                                            <div className="participants__title tooltip">
                                                                                {part.title}
                                                                            </div>{' '}
                                                                            {isToolTip('.tooltip') && (
                                                                                <div className="hover-content">
                                                                                    {part.title}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>

                                                            {elem.files.tender_representative?.[0]?.name && (
                                                                <>
                                                                    <div className="task-complete__info">
                                                                        <div className="task-complete__wrap column">
                                                                            <div className="task-complete__name">
                                                                                Uploaded File
                                                                            </div>
                                                                            <div className="task-complete__value file">
                                                                                {
                                                                                    elem.files.tender_representative[0]
                                                                                        ?.name
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                            {elem.comments?.tender_representative && (
                                                                <div className="task-complete__info">
                                                                    <div className="task-complete__wrap column">
                                                                        <div className="task-complete__name">
                                                                            My Comment
                                                                        </div>
                                                                        <div className="task-complete__value">
                                                                            {elem.comments.tender_representative}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}

                                                            <div>
                                                                {role !== 'super_admin' && (
                                                                    <div className="tasks__checkbox-wrapper evaluate-3">
                                                                        <div className="tasks__checkbox-container">
                                                                            <div
                                                                                className={`tasks__checkbox ${
                                                                                    elem.supplier_no_show === 'yes'
                                                                                        ? 'active'
                                                                                        : ''
                                                                                }`}
                                                                                onClick={() => {
                                                                                    if (elem.supplier_no_show === 'yes')
                                                                                        return;
                                                                                    setTaskId(elem.id);
                                                                                    setNoShowModal(true);
                                                                                }}
                                                                            ></div>
                                                                            <div>No Show</div>
                                                                        </div>
                                                                        <div
                                                                            className="tasks__checkbox-container"
                                                                            onClick={() => {
                                                                                if (
                                                                                    isLoadingTookPlace ||
                                                                                    elem.supplier_took_place === 'yes'
                                                                                )
                                                                                    return;
                                                                                updateTaskFunc(elem.id);
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className={`tasks__checkbox ${
                                                                                    elem.supplier_took_place === 'yes'
                                                                                        ? 'active'
                                                                                        : ''
                                                                                }`}
                                                                            ></div>
                                                                            <div>Took Place</div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                return <></>;
                                            }
                                        })}
                                    </div>
                                );
                            }
                            // if (
                            //     (elem.status === 'need_approve_tr' || elem.status === 'pending_su') &&
                            //     !elem?.approved
                            // ) {
                            //     return (
                            //         <div
                            //             key={elem.id}
                            //             className={`tasks__task-complete task-complete ${
                            //                 elem.supplier_took_place === 'yes' || elem.supplier_no_show === 'yes'
                            //                     ? 'green'
                            //                     : ''
                            //             }`}
                            //         >
                            //             <div className="task-complete__title">
                            //                 <div className="task-complete__title-row ">
                            //                     <img src={SupplierIcon} />
                            //                     <div className="supplier__name-info">
                            //                         <div className="supplier__name">
                            //                             {elem.name}
                            //                             {elem.supplier_incumbent_status === 'yes' && (
                            //                                 <div className="supplier__incumbent">
                            //                                     <img src={attantion} />
                            //                                     Incumbent Supplier
                            //                                 </div>
                            //                             )}
                            //                         </div>
                            //                         <div className="supplier__email">{elem.supplier_email}</div>
                            //                     </div>
                            //                 </div>
                            //             </div>
                            //             <div className="task-complete__info-wrap">
                            //                 <div className="task-complete__wrap">
                            //                     <div className="task-complete__name" style={{ width: '180px' }}>
                            //                         Meeting Date
                            //                     </div>
                            //                     <div className="task-complete__name">Time</div>
                            //                 </div>
                            //                 <div className="task-complete__wrap">
                            //                     <div className="task-complete__value" style={{ width: '180px' }}>
                            //                         <img src={calendar} />
                            //                         {elem.meeting_slot[0].date}
                            //                     </div>
                            //                     <div className="task-complete__value">
                            //                         <ClockIcon fill="#001965" />
                            //                         {elem.meeting_slot[0].time_from} - {elem.meeting_slot[0].time_to}
                            //                     </div>
                            //                 </div>

                            //                 <div className="task-complete__wrap" style={{ margin: '10px 0 0 0' }}>
                            //                     <div className="task-complete__name" style={{ width: '70px' }}>
                            //                         Participants{' '}
                            //                     </div>
                            //                 </div>
                            //                 <div className="task-complete__participants participants">
                            //                     {elem.participants.map((part: PartI) => {
                            //                         return (
                            //                             <div className="participants__participant">
                            //                                 <div className="participants__name">{part.full_name}</div>
                            //                                 <div className="participants__title">{part.title}</div>
                            //                             </div>
                            //                         );
                            //                     })}
                            //                 </div>

                            //                 {elem.files?.tender_representative?.[0]?.name && (
                            //                     <div className="task-complete__info">
                            //                         <div className="task-complete__wrap column">
                            //                             <div className="task-complete__name">Uploaded File</div>
                            //                             <div className="task-complete__value file">
                            //                                 {elem.files.tender_representative[0]?.name}
                            //                             </div>
                            //                         </div>
                            //                     </div>
                            //                 )}
                            //                 {elem.comments.tender_representative && (
                            //                     <div className="task-complete__info">
                            //                         <div className="task-complete__wrap column">
                            //                             <div className="task-complete__name">My Comment</div>
                            //                             <div className="task-complete__value">
                            //                                 {elem.comments.tender_representative}
                            //                             </div>
                            //                         </div>
                            //                     </div>
                            //                 )}
                            //             </div>
                            //         </div>
                            //     );
                            // }
                        }
                        if (role === 'school_user') {
                            if (elem.status === 'pending_su' && !elem?.approved && elem.participants?.length) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete ${
                                            data.can_send_task ? 'green' : ''
                                        }`}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                Supplier Shortlist
                                            </div>
                                            {/* <div className="task-complete__title-row">
                                                <div
                                                    className="supplier-SU__white-btn"
                                                    onClick={() => {
                                                        downloadProfileSummary(data?.id).then((res) => {
                                                            const blobUrl = window.URL.createObjectURL(res?.data);
                                                            window.open(blobUrl, '_blank');
                                                        });
                                                    }}
                                                >
                                                    Supplier Profile Summary
                                                    <img src={download} className={'supplier-SU__white-btn-icon'} />
                                                </div>
                                            </div> */}
                                        </div>

                                        <div
                                            className="task-complete__info-wrap"
                                            style={{ margin: '20px 0 0 0', width: '100%', padding: '0' }}
                                        >
                                            <div className="task-complete__supplier-list" style={{ padding: '0' }}>
                                                {data?.suppliers.map((item: SupplierI) => {
                                                    if (item.id !== elem.supplier_id) return;
                                                    return (
                                                        <>
                                                            <div className="task-complete__supplier  supplier-SU">
                                                                <div className="supplier-SU__row">
                                                                    <div className="supplier__column">
                                                                        <div className="supplier__row">
                                                                            <Tooltip
                                                                                title="A verified status indicates that the supplier`s company documentation has been reviewed and confirmed"
                                                                                overlayClassName={` ${
                                                                                    item.company_status_name ===
                                                                                    'Verified'
                                                                                        ? 'custom-tooltip-disabled left'
                                                                                        : 'custom-tooltip '
                                                                                }`}
                                                                            >
                                                                                <div className="supplier_status">
                                                                                    <img
                                                                                        src={statusImage(
                                                                                            item.company_status_name
                                                                                        )}
                                                                                    />{' '}
                                                                                    {item.company_status_name}
                                                                                </div>
                                                                            </Tooltip>
                                                                            {item.incumbent && (
                                                                                <div className="supplier__incumbent">
                                                                                    <img src={attantion} />
                                                                                    Incumbent Supplier
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div className="supplier-SU__name">
                                                                            ({item.name_prefix}) {item.name}
                                                                        </div>
                                                                        <div className="supplier-SU__trading_name">
                                                                            {item.trading_name}
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="supplier__row"
                                                                        style={{
                                                                            justifyContent: 'end',
                                                                            position: 'relative'
                                                                        }}
                                                                    >
                                                                        <Tooltip
                                                                            color="#fff"
                                                                            key={'#fff'}
                                                                            overlayClassName="history"
                                                                            title={
                                                                                <div className="grade-history">
                                                                                    Grade History
                                                                                    {item.grade_history?.map(
                                                                                        (grade) => {
                                                                                            return (
                                                                                                <div className="grade-history__item">
                                                                                                    <div className="grade-history__title">
                                                                                                        {
                                                                                                            grade.phase_name
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="grade-history__value">
                                                                                                        {grade.grade
                                                                                                            .value_format ===
                                                                                                        'Pass'
                                                                                                            ? 'Pass'
                                                                                                            : grade
                                                                                                                  .grade
                                                                                                                  .value_format}
                                                                                                        {grade?.grade
                                                                                                            ?.key !==
                                                                                                            'pass' && (
                                                                                                            <div
                                                                                                                className={`grade-history__key ${classNameOfGrade(
                                                                                                                    grade
                                                                                                                        ?.grade
                                                                                                                        ?.key
                                                                                                                )}`}
                                                                                                            >
                                                                                                                {
                                                                                                                    grade
                                                                                                                        ?.grade
                                                                                                                        ?.key
                                                                                                                }
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </div>
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={gradeHistory}
                                                                                style={{
                                                                                    margin: '0 10px 0 0 ',
                                                                                    cursor: 'pointer'
                                                                                }}
                                                                            />
                                                                        </Tooltip>
                                                                        <div></div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {openState.find((state) => state.id === item.id)?.open ? (
                                                                <div className="supplier-SU__additional">
                                                                    <div className="supplier-SU__gray_row">
                                                                        <div className="supplier-SU__item">
                                                                            <div className="supplier-SU__item-name">
                                                                                B-BBEE
                                                                            </div>
                                                                            <div className="supplier-SU__item-value">
                                                                                {item.b_bbee}
                                                                            </div>
                                                                        </div>
                                                                        <div className="supplier-SU__item">
                                                                            <div className="supplier-SU__item-name">
                                                                                Number of Employees
                                                                            </div>
                                                                            <div className="supplier-SU__item-value">
                                                                                {item.employees_number}
                                                                            </div>
                                                                        </div>
                                                                        <div className="supplier-SU__item last">
                                                                            <div className="supplier-SU__item-name">
                                                                                Annual Revenue
                                                                            </div>
                                                                            <div className="supplier-SU__item-value">
                                                                                {item.annual_revenue}
                                                                            </div>
                                                                        </div>
                                                                        <a
                                                                            href={item.website}
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            <div className="supplier-SU__white-btn">
                                                                                Visit Website <img src={Visit} />
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                    {item.conflict_of_interest && (
                                                                        <div className="supplier-SU__add-text">
                                                                            <div className="supplier-SU__item-name">
                                                                                Potential conflict of interest
                                                                            </div>
                                                                            <div className="supplier-SU__conflict">
                                                                                <img src={messageIcon} />{' '}
                                                                                {item.conflict_of_interest}
                                                                            </div>
                                                                        </div>
                                                                    )}

                                                                    <div className="supplier-SU__add-text">
                                                                        {item.references.length > 0 && (
                                                                            <div className="supplier-SU__item-name">
                                                                                References:{' '}
                                                                            </div>
                                                                        )}
                                                                        <div className="supplier-SU__row-references">
                                                                            {item.references.map((ref) => {
                                                                                return (
                                                                                    <div className="supplier-SU__reference reference">
                                                                                        <div className="reference__item rating">
                                                                                            {ref.entity_name}
                                                                                            {ref?.rating && (
                                                                                                <div className="rating__item">
                                                                                                    {star()}
                                                                                                    {ref.rating}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                        <div className="reference__name">
                                                                                            {ref.contact_name}
                                                                                        </div>
                                                                                        <div className="reference__email">
                                                                                            {ref.email}{' '}
                                                                                            {ref.phone_number}
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        className="supplier-SU__show"
                                                                        onClick={() => {
                                                                            const index = openState.findIndex(
                                                                                (state) => item.id === state.id
                                                                            );

                                                                            if (index !== -1) {
                                                                                const updatedStates = openState.map(
                                                                                    (state, i) =>
                                                                                        i === index
                                                                                            ? { ...state, open: false }
                                                                                            : state
                                                                                );
                                                                                setOpenState(updatedStates);
                                                                            }
                                                                        }}
                                                                    >
                                                                        Hide Details{' '}
                                                                        <img
                                                                            src={arrow}
                                                                            style={{ transform: 'rotate(180deg)' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    className="supplier-SU__show"
                                                                    onClick={() => {
                                                                        const index = openState.findIndex(
                                                                            (state) => item.id === state.id
                                                                        );

                                                                        if (index !== -1) {
                                                                            const updatedStates = openState.map(
                                                                                (state, i) =>
                                                                                    i === index
                                                                                        ? { ...state, open: true }
                                                                                        : state
                                                                            );
                                                                            setOpenState(updatedStates);
                                                                        }
                                                                    }}
                                                                >
                                                                    Show Details <img src={arrow} />
                                                                </div>
                                                            )}
                                                        </>
                                                    );
                                                })}
                                            </div>
                                            <div style={{ padding: '12px' }}>
                                                <div className="task-complete__wrap">
                                                    <div
                                                        className="task-complete__name"
                                                        style={{ width: '140px', padding: '0' }}
                                                    >
                                                        Meeting Date
                                                    </div>
                                                    <div className="task-complete__name">Time</div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div
                                                        className="task-complete__value"
                                                        style={{ width: '140px', padding: '0' }}
                                                    >
                                                        <img src={calendar} />
                                                        {elem.meeting_slot?.[0].date}
                                                    </div>
                                                    <div className="task-complete__value">
                                                        <ClockIcon fill="#001965" style={{ margin: '0 8px 0 0' }} />
                                                        {elem.meeting_slot?.[0].time_from} -{' '}
                                                        {elem.meeting_slot?.[0].time_to}
                                                    </div>
                                                </div>

                                                <div className="task-complete__wrap" style={{ margin: '10px 0 0 0' }}>
                                                    <div className="task-complete__name" style={{ width: '70px' }}>
                                                        Participants{' '}
                                                    </div>
                                                </div>
                                                <div className="task-complete__participants participants">
                                                    {elem.participants.map((part: PartI) => {
                                                        return (
                                                            <div className="participants__participant">
                                                                <div className="participants__name tooltip">
                                                                    {part.full_name}
                                                                </div>
                                                                {isToolTip('.tooltip') && (
                                                                    <div className="hover-content">
                                                                        {part.full_name}
                                                                    </div>
                                                                )}
                                                                <div className="participants__title tooltip">
                                                                    {part.title}
                                                                </div>{' '}
                                                                {isToolTip('.tooltip') && (
                                                                    <div className="hover-content">{part.title}</div>
                                                                )}
                                                            </div>
                                                        );
                                                    })}
                                                </div>

                                                {elem.files.tender_representative?.[0]?.name && (
                                                    <div className="task-complete__info">
                                                        <div className="task-complete__wrap column">
                                                            <div className="task-complete__name">Uploaded File</div>
                                                            <div className="task-complete__value file">
                                                                {elem.files.tender_representative[0]?.name}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`task-complete__download-btn `}
                                                            onClick={() => {
                                                                downloadFunc(
                                                                    elem.files.tender_representative[0]?.uuid,
                                                                    elem.id
                                                                );
                                                            }}
                                                        >
                                                            <img src={downloadIcon} className="upload-download-icon" />
                                                            Download
                                                        </div>
                                                    </div>
                                                )}
                                                {elem.comments?.tender_representative && (
                                                    <div className="task-complete__info">
                                                        <div className="task-complete__wrap column">
                                                            <div className="task-complete__name">My Comment</div>
                                                            <div className="task-complete__value">
                                                                {elem.comments.tender_representative}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                            if (
                                (elem.status === 'need_approve_tr' || elem.status === 'pending_tr') &&
                                !elem?.approved &&
                                elem.participants?.length
                            ) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete ${
                                            data.can_send_task ? 'green' : ''
                                        }`}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                Supplier Shortlist
                                            </div>
                                            <div className="task__pending" key={elem.id}>
                                                <img src={pendingIcon} />
                                                Pending
                                            </div>
                                        </div>
                                        <div
                                            className="task-complete__info-wrap"
                                            style={{ margin: '20px 0 0 0', width: '100%', padding: '0' }}
                                        >
                                            <div className="task-complete__supplier-list" style={{ padding: '0' }}>
                                                {data?.suppliers.map((item: SupplierI) => {
                                                    if (item.id !== elem.supplier_id) return;
                                                    return (
                                                        <>
                                                            <div className="task-complete__supplier supplier-SU">
                                                                <div className="supplier-SU__row">
                                                                    <div className="supplier__column">
                                                                        <div className="supplier__row">
                                                                            <Tooltip
                                                                                title="A verified status indicates that the supplier`s company documentation has been reviewed and confirmed"
                                                                                overlayClassName={` ${
                                                                                    item.company_status_name ===
                                                                                    'Verified'
                                                                                        ? 'custom-tooltip-disabled left'
                                                                                        : 'custom-tooltip '
                                                                                }`}
                                                                            >
                                                                                <div className="supplier_status">
                                                                                    <img
                                                                                        src={statusImage(
                                                                                            item.company_status_name
                                                                                        )}
                                                                                    />{' '}
                                                                                    {item.company_status_name}
                                                                                </div>
                                                                            </Tooltip>
                                                                            {item.incumbent && (
                                                                                <div className="supplier__incumbent">
                                                                                    <img src={attantion} />
                                                                                    Incumbent Supplier
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div className="supplier-SU__name">
                                                                            ({item.name_prefix}) {item.name}
                                                                        </div>
                                                                        <div className="supplier-SU__trading_name">
                                                                            {item.trading_name}
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="supplier__row"
                                                                        style={{
                                                                            justifyContent: 'end',
                                                                            position: 'relative'
                                                                        }}
                                                                    >
                                                                        <Tooltip
                                                                            color="#fff"
                                                                            key={'#fff'}
                                                                            overlayClassName="history"
                                                                            title={
                                                                                <div className="grade-history">
                                                                                    Grade History
                                                                                    {item.grade_history?.map(
                                                                                        (grade) => {
                                                                                            return (
                                                                                                <div className="grade-history__item">
                                                                                                    <div className="grade-history__title">
                                                                                                        {
                                                                                                            grade.phase_name
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="grade-history__value">
                                                                                                        {grade.grade
                                                                                                            .value_format ===
                                                                                                        'Pass'
                                                                                                            ? 'Pass'
                                                                                                            : role !==
                                                                                                                  'school_user' &&
                                                                                                              grade
                                                                                                                  .grade
                                                                                                                  .value_format}
                                                                                                        {grade?.grade
                                                                                                            ?.key !==
                                                                                                            'pass' && (
                                                                                                            <div
                                                                                                                className={`grade-history__key ${classNameOfGrade(
                                                                                                                    grade
                                                                                                                        ?.grade
                                                                                                                        ?.key
                                                                                                                )}`}
                                                                                                            >
                                                                                                                {
                                                                                                                    grade
                                                                                                                        ?.grade
                                                                                                                        ?.key
                                                                                                                }
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </div>
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={gradeHistory}
                                                                                style={{
                                                                                    margin: '0 10px 0 0 ',
                                                                                    cursor: 'pointer'
                                                                                }}
                                                                            />
                                                                        </Tooltip>
                                                                        <div></div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {openState.find((state) => state.id === item.id)?.open ? (
                                                                <div className="supplier-SU__additional">
                                                                    <div className="supplier-SU__gray_row">
                                                                        <div className="supplier-SU__item">
                                                                            <div className="supplier-SU__item-name">
                                                                                B-BBEE
                                                                            </div>
                                                                            <div className="supplier-SU__item-value">
                                                                                {item.b_bbee}
                                                                            </div>
                                                                        </div>
                                                                        <div className="supplier-SU__item">
                                                                            <div className="supplier-SU__item-name">
                                                                                Number of Employees
                                                                            </div>
                                                                            <div className="supplier-SU__item-value">
                                                                                {item.employees_number}
                                                                            </div>
                                                                        </div>
                                                                        <div className="supplier-SU__item last">
                                                                            <div className="supplier-SU__item-name">
                                                                                Annual Revenue
                                                                            </div>
                                                                            <div className="supplier-SU__item-value">
                                                                                {item.annual_revenue}
                                                                            </div>
                                                                        </div>
                                                                        <a
                                                                            href={item.website}
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            <div className="supplier-SU__white-btn">
                                                                                Visit Website <img src={Visit} />
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                    {item.conflict_of_interest && (
                                                                        <div className="supplier-SU__add-text">
                                                                            <div className="supplier-SU__item-name">
                                                                                Potential conflict of interest
                                                                            </div>
                                                                            <div className="supplier-SU__conflict">
                                                                                <img src={messageIcon} />{' '}
                                                                                {item.conflict_of_interest}
                                                                            </div>
                                                                        </div>
                                                                    )}

                                                                    <div className="supplier-SU__add-text">
                                                                        {item.references.length > 0 && (
                                                                            <div className="supplier-SU__item-name">
                                                                                References:{' '}
                                                                            </div>
                                                                        )}
                                                                        <div className="supplier-SU__row-references">
                                                                            {item.references.map((ref) => {
                                                                                return (
                                                                                    <div className="supplier-SU__reference reference">
                                                                                        <div className="reference__item rating">
                                                                                            {ref.entity_name}
                                                                                            {ref?.rating && (
                                                                                                <div className="rating__item">
                                                                                                    {star()}
                                                                                                    {ref.rating}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                        <div className="reference__name">
                                                                                            {ref.contact_name}
                                                                                        </div>
                                                                                        <div className="reference__email">
                                                                                            {ref.email}{' '}
                                                                                            {ref.phone_number}
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        className="supplier-SU__show"
                                                                        onClick={() => {
                                                                            const index = openState.findIndex(
                                                                                (state) => item.id === state.id
                                                                            );

                                                                            if (index !== -1) {
                                                                                const updatedStates = openState.map(
                                                                                    (state, i) =>
                                                                                        i === index
                                                                                            ? { ...state, open: false }
                                                                                            : state
                                                                                );
                                                                                setOpenState(updatedStates);
                                                                            }
                                                                        }}
                                                                    >
                                                                        Hide Details{' '}
                                                                        <img
                                                                            src={arrow}
                                                                            style={{ transform: 'rotate(180deg)' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    className="supplier-SU__show"
                                                                    onClick={() => {
                                                                        const index = openState.findIndex(
                                                                            (state) => item.id === state.id
                                                                        );

                                                                        if (index !== -1) {
                                                                            const updatedStates = openState.map(
                                                                                (state, i) =>
                                                                                    i === index
                                                                                        ? { ...state, open: true }
                                                                                        : state
                                                                            );
                                                                            setOpenState(updatedStates);
                                                                        }
                                                                    }}
                                                                >
                                                                    Show Details <img src={arrow} />
                                                                </div>
                                                            )}
                                                        </>
                                                    );
                                                })}
                                            </div>
                                            <div style={{ padding: '12px' }}>
                                                <div className="task-complete__wrap">
                                                    <div
                                                        className="task-complete__name"
                                                        style={{ width: '140px', padding: '0' }}
                                                    >
                                                        Meeting Date
                                                    </div>
                                                    <div className="task-complete__name">Time</div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div
                                                        className="task-complete__value"
                                                        style={{ width: '140px', padding: '0' }}
                                                    >
                                                        <img src={calendar} />
                                                        {elem.meeting_slot?.[0].date}
                                                    </div>
                                                    <div className="task-complete__value">
                                                        <ClockIcon fill="#001965" style={{ margin: '0 8px 0 0' }} />
                                                        {elem.meeting_slot?.[0].time_from} -{' '}
                                                        {elem.meeting_slot?.[0].time_to}
                                                    </div>
                                                </div>

                                                <div className="task-complete__wrap" style={{ margin: '10px 0 0 0' }}>
                                                    <div className="task-complete__name" style={{ width: '70px' }}>
                                                        Participants{' '}
                                                    </div>
                                                </div>
                                                <div className="task-complete__participants participants">
                                                    {elem.participants.map((part: PartI) => {
                                                        return (
                                                            <div className="participants__participant">
                                                                <div className="participants__name tooltip">
                                                                    {part.full_name}
                                                                </div>
                                                                {isToolTip('.tooltip') && (
                                                                    <div className="hover-content">
                                                                        {part.full_name}
                                                                    </div>
                                                                )}
                                                                <div className="participants__title tooltip">
                                                                    {part.title}
                                                                </div>{' '}
                                                                {isToolTip('.tooltip') && (
                                                                    <div className="hover-content">{part.title}</div>
                                                                )}
                                                            </div>
                                                        );
                                                    })}
                                                </div>

                                                {elem.files.tender_representative?.[0]?.name && (
                                                    <div className="task-complete__info">
                                                        <div className="task-complete__wrap column">
                                                            <div className="task-complete__name">Uploaded File</div>
                                                            <div className="task-complete__value file">
                                                                {elem.files.tender_representative[0]?.name}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`task-complete__download-btn `}
                                                            onClick={() => {
                                                                downloadFunc(
                                                                    elem.files.tender_representative[0]?.uuid,
                                                                    elem.id
                                                                );
                                                            }}
                                                        >
                                                            <img src={downloadIcon} className="upload-download-icon" />
                                                            Download
                                                        </div>
                                                    </div>
                                                )}
                                                {elem.comments?.tender_representative && (
                                                    <div className="task-complete__info">
                                                        <div className="task-complete__wrap column">
                                                            <div className="task-complete__name">My Comment</div>
                                                            <div className="task-complete__value">
                                                                {elem.comments.tender_representative}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        }
                    }
                })}

            {isSupplierInfoModal && (
                <SupplierInfoModal
                    openModal={isSupplierInfoModal}
                    setOpenModal={setIsSupplierInfoModal}
                    messageApi={messageApi}
                    data={data}
                    setTrigger={setTrigger}
                    taskId={taskId}
                    isEditSupplier={isEditSupplier}
                />
            )}
            {noShowModal && (
                <NoShowModal
                    openModal={noShowModal}
                    setOpenModal={setNoShowModal}
                    setTrigger={setTrigger}
                    taskId={taskId}
                    data={data}
                    setDeadlineModal={setDeadlineModal}
                    isSuccess={isSuccess}
                />
            )}
            {isEditModal && (
                <EditSupplierModal
                    openModal={isEditModal}
                    setOpenModal={setIsEditModal}
                    messageApi={messageApi}
                    setTrigger={setTrigger}
                    SupplierData={SupplierData}
                    data={data}
                />
            )}
        </>
    );
};
