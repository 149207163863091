import { useEffect, useRef, useState } from 'react';
import { Form } from 'antd';
import type { SelectProps } from 'antd';
import { useClickOutside } from '../../hooks/useClickOutSide';
import schoolImage from '../../assets/schoolImage.svg';
import arrow from '../../assets/arrow.svg';
import close from '../../assets/Close.svg';
import noResult from '../../assets/noResult.svg';
import defaultAvatar from '../../assets/DefaultAvatar.svg';
import InputSearch from './InputSearch';
import './index.scss';

interface FloatSelectProps extends SelectProps {
    required: boolean;
    floatLabel: string;
    err: boolean;
    setSearch: any;
    options: any;
    onChange: any;
    search: string;
    form: any;
    type?: string;
    hasError: () => boolean;
    setTouched?: (value: boolean) => void;
}

export const FloatSelect = (props: FloatSelectProps) => {
    const [openSelect, setOpenSelect] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);
    let {
        floatLabel,
        placeholder,
        value,
        required,
        err,
        setSearch,
        options,
        onChange,
        search,
        form,
        disabled,
        hasError,
        type = 'school',
        setTouched
    } = props;
    if (!placeholder) placeholder = floatLabel;

    useClickOutside(ref, () => setOpenSelect(false));

    const isOccupied = openSelect || value;

    useEffect(() => {
        if (openSelect) {
            setTouched && setTouched(true);
        }
    }, [openSelect]);

    const labelClass = isOccupied ? 'label as-label' : 'label as-placeholder';

    <img src={value ? '' : arrow} className={'image'} />;

    useEffect(() => {
        if (!openSelect) {
            setSearch('');
        }
    }, [openSelect]);

    return (
        <div className="float-label" ref={ref}>
            <div
                style={disabled ? { cursor: 'not-allowed' } : {}}
                className={`${
                    openSelect && !value
                        ? 'customSelect'
                        : openSelect && value
                        ? 'customSelec-with-value'
                        : value
                        ? type === 'common'
                            ? 'input-select-with-value common'
                            : 'input-select-with-value'
                        : 'input-select float-select'
                } ${err ? 'err' : ''} ${disabled ? 'disabled-select' : ''} ${hasError() && !value ? 'err' : ''}`}
                onClick={() => {
                    if (!openSelect && !disabled) {
                        setOpenSelect(true);
                    }
                }}
            >
                {!disabled && (
                    <>
                        {value && !openSelect ? (
                            <img
                                src={close}
                                className={`customSelect_image`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onChange(null);
                                }}
                            />
                        ) : (
                            <img
                                src={arrow}
                                className={`customSelect_image ${openSelect ? 'rotate' : ''}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setOpenSelect((prev) => !prev);
                                }}
                            />
                        )}
                    </>
                )}
                <label className={labelClass}>
                    {isOccupied ? floatLabel : placeholder} {required && <span className="red">*</span>}
                </label>

                {value && (
                    <div className={`select-option value`} style={disabled ? { cursor: 'not-allowed' } : {}}>
                        {type === 'common' ? (
                            <></>
                        ) : (
                            <div className="select-option__img">
                                {type === 'school' && <img src={value.preview ? value.preview : schoolImage} />}
                                {type === 'user' && (
                                    <img src={value.avatar_thumb ? value.avatar_thumb : defaultAvatar} />
                                )}
                                {type === 'tender' && (
                                    <img src={value.avatar_thumb ? value.avatar_thumb : defaultAvatar} />
                                )}
                            </div>
                        )}
                        <div className="select-option__wrap">
                            <div className="select-option__name">
                                {type === 'school' && value.name}
                                {type === 'user' && value.full_name}
                                {type === 'tender' && value.full_name}
                                {type === 'common' && value.name}
                            </div>
                            <div className="select-option__city">
                                {type === 'school' && value.city + ' ' + value.province?.name
                                    ? value.province?.name
                                    : value.province}
                                {type === 'user' && value.email}
                                {type === 'tender' && value.email}
                            </div>
                        </div>
                    </div>
                )}

                {openSelect && (
                    <div>
                        {floatLabel === 'School User' || type === 'common' ? (
                            ''
                        ) : (
                            <Form.Item style={{ width: '95%', margin: '0 0 0 10px' }}>
                                <InputSearch handleChange={(value) => setSearch(value)} />
                            </Form.Item>
                        )}

                        <div className="customSelect__options">
                            {options?.length ? (
                                options?.map((elem: any) => {
                                    return (
                                        <div
                                            className="select-option"
                                            onClick={() => {
                                                setOpenSelect(false);
                                                onChange(elem);
                                            }}
                                        >
                                            {type === 'common' ? (
                                                <></>
                                            ) : (
                                                <div className="select-option__img">
                                                    {type === 'school' && (
                                                        <img src={elem.preview ? elem.preview : schoolImage} />
                                                    )}
                                                    {type === 'user' && (
                                                        <img
                                                            src={elem.avatar_thumb ? elem.avatar_thumb : defaultAvatar}
                                                        />
                                                    )}
                                                    {type === 'tender' && (
                                                        <img
                                                            src={elem.avatar_thumb ? elem.avatar_thumb : defaultAvatar}
                                                        />
                                                    )}
                                                </div>
                                            )}
                                            <div className="select-option__wrap">
                                                <div className="select-option__name">
                                                    {type === 'school' && elem.name}
                                                    {type === 'user' && elem.full_name}
                                                    {type === 'tender' && elem.full_name}
                                                    {type === 'common' && elem.name}
                                                </div>
                                                <div className="select-option__city">
                                                    {type === 'school' && elem.city + ' ' + elem.province}
                                                    {type === 'user' && elem.email}
                                                    {type === 'tender' && elem.email}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="customSelect__no-result">
                                    <img src={noResult} />
                                    <div>No results found</div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
