import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import expiredLink from '../../assets/expiredLink.svg';
import logo from '../../assets/logo.svg';
import logoPhone from '../../assets/logo-width-to600.svg';
import './index.scss';
import { Button } from 'antd';
import moment from 'moment';

export const ExpiredLink = () => {
    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);

    function handleResize() {
        setScreenWidth(window.screen.width);
    }

    window.addEventListener('resize', handleResize);

    const location = useLocation();

    const navigate = useNavigate();
    return (
        <div className="home">
            <div className="home__logo-container">
                {screenWidth > 600 && <img src={logo} className="header__logo" />}
                {screenWidth <= 600 && <img src={logoPhone} className="header__logo" />}
            </div>
            <div className="home__main">
                <img src={expiredLink} />
                <h1 className="home__text">Link is not longer valid</h1>
                <Button
                    type="primary"
                    className="home__btn"
                    onClick={() => {
                        if (location?.search?.includes('action=supplier_self_registration')) {
                            window.location.href = 'https://schooladvisor.co.za/';
                        } else {
                            navigate('/login');
                        }
                    }}
                >
                    Go to the Home Page
                </Button>
            </div>
            <div className="home__footer">© copyright 2016 - {moment().format('YYYY')} SchoolAdvisor</div>
        </div>
    );
};
