import { useState, FC, useEffect } from 'react';
import { useSendTaskMutation, useUpdateTaskDeadlineMutation } from '../../api/tenders';
import { tenderDataProps } from '../../pages/TenderViewPage/types';
import { Button, Tooltip, message } from 'antd';
import { useAppSelector } from '../../hooks';
import AwardWelcome from '../../assets/AwardWelcome.svg';
import Notif from '../../assets/Notif.svg';
import arrow from '../../assets/arrow-right-white.svg';
import calendar from '../../assets/calendar-chedule.svg';

import attention from '../../assets/attention.svg';
import edit from '../../assets/editAdminIcon.svg';
import moment from 'moment';
import { TaskDeadlineModal } from '../Modals/TaskDeadline';

import { SpinnerIcon } from '../Icons/SpinnerIcon';
import { AwardSupplyAgreement } from './AwardPhaseComponents/AwardSupplyAgreement';
import './phases.scss';
import { ContersignedAgreement } from './AwardPhaseComponents/ContersignedAgreement';
import { CompanyDocumentation } from './AwardPhaseComponents/CompanyDocumentation';
import { CloseTenderForm } from './AwardPhaseComponents/CloseTenderForm';
import { SupplierReportCardPublishDate } from './EvaluatePhase4Components/SupplierReportCardPublishDate';
import { SupplierReportCard } from './EvaluatePhaseComponent/SupplierReportCard';
import { AllTasksCompleted } from './AllTasksCompleted';

interface PropsPhase {
    data: tenderDataProps;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    setConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AwardPhase: FC<PropsPhase> = ({ data, setTrigger, setConfirmModal }) => {
    const { role } = useAppSelector((state) => state.profileReducer);
    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);
    function handleResize() {
        setScreenWidth(window.screen.width);
    }
    window.addEventListener('resize', handleResize);
    const [deadlineModal, setDeadlineModal] = useState(false);
    const [SelectedDate, setSelectedDate] = useState<Date | string>(new Date());

    const [updateDeadline, { isLoading }] = useUpdateTaskDeadlineMutation();

    const updateDeadlineFunc = async () => {
        try {
            const formData = {
                id: data.id,
                data: { deadline_at: moment(SelectedDate).format('YYYY-MM-DD') }
            };
            await updateDeadline(formData).unwrap();
            await setDeadlineModal(false);
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    const [
        sendTask,
        { data: dataSendTask, isError: isErrorSendTask, isSuccess: isSuccessSendTask, isLoading: isLoadingSendTask }
    ] = useSendTaskMutation();

    const [messageApi, contextHolder] = message.useMessage();

    const sendTaskFunc = async () => {
        const formData = {
            id: data?.id
        };
        try {
            await sendTask(formData).unwrap();
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    useEffect(() => {
        if (isErrorSendTask) {
            messageApi.error('Something went wrong, please try again');
        }
        if (isSuccessSendTask) {
            messageApi.success(dataSendTask.message);
        }
    }, [isErrorSendTask, isSuccessSendTask]);

    return (
        <>
            {contextHolder}
            <div className={`tendermain__block tasks`}>
                {role === 'tender_representative' && (
                    <div className="tasks__header define">
                        <div className="count-wrapper">
                            Task Centre
                            <div className="count">{data?.phase_task_count}</div>
                        </div>

                        {(data?.phase_tasks?.[0]?.status === 'pending_tr' ||
                            data?.phase_tasks?.[0]?.status === 'need_resend_tr' ||
                            data?.phase_tasks?.[0]?.status === 'completed') &&
                            data?.phase_tasks?.[3]?.status !== 'pending_sa' &&
                            data?.phase_name !== 'Closed' && (
                                <div className="tasks__deadline-wrapper">
                                    <img src={attention} />
                                    {screenWidth > 500 ? 'Task' : ''} Deadline -{' '}
                                    {moment(data?.phase_deadline_at).format('YYYY-MM-DD')}
                                </div>
                            )}
                    </div>
                )}
                {role === 'super_admin' && (
                    <div className="tasks__header define">
                        <div className="count-wrapper">
                            Task Centre
                            <div className="count">{data?.phase_task_count}</div>
                        </div>

                        {data?.phase_tasks?.[0]?.status === 'pending_sa' && data?.phase_name !== 'Closed' && (
                            <div className="tasks__deadline-wrapper">
                                <img src={attention} />
                                {screenWidth > 500 ? 'Task' : ''} Deadline -{' '}
                                {moment(data?.phase_deadline_at).format('YYYY-MM-DD')}
                            </div>
                        )}
                    </div>
                )}
                {role === 'school_user' && (
                    <div className="tasks__header design">
                        <div className="count-wrapper">
                            Task Centre
                            <div className="count">{data?.phase_task_count}</div>
                        </div>
                        {data?.phase_tasks?.[0]?.status === 'pending_su' && data.phase_tasks.length === 1 && (
                            <div className="tasks__deadline-wrapper">
                                <img src={attention} />
                                {screenWidth > 500 ? 'Task' : ''} Deadline -{' '}
                                {moment(data?.tasks_deadline_at).format('YYYY-MM-DD')}
                            </div>
                        )}
                    </div>
                )}

                <div className="tasks__list">
                    {role !== 'school_user' && data.phase_tasks.length !== 4 && data.status !== 'archive' && (
                        <div className="tasks__info-wrap info-wrap" style={{ margin: '0 0 16px 0' }}>
                            <div className="info-wrap__deadline">Task Deadline for School User</div>
                            <div className="info-wrap__status">
                                {data?.tasks_deadline_at
                                    ? moment(data?.tasks_deadline_at).format('YYYY-MM-DD')
                                    : 'Not indicated'}
                            </div>
                            <div className="info-wrap__edit" onClick={() => setDeadlineModal(true)}>
                                <img src={edit} />
                                Edit
                            </div>
                        </div>
                    )}
                    {data.phase_tasks.length ? (
                        <>
                            {/* {role && role === 'school_user' && (data?.can_send_task || data?.can_update_phase) && (
                                <AllTasksCompleted />
                            )} */}
                            <SupplierReportCard
                                data={data}
                                messageApi={messageApi}
                                setTrigger={setTrigger}
                                role={role}
                            />
                            <AwardSupplyAgreement
                                data={data}
                                messageApi={messageApi}
                                setTrigger={setTrigger}
                                role={role}
                            />
                            <ContersignedAgreement
                                data={data}
                                messageApi={messageApi}
                                setTrigger={setTrigger}
                                role={role}
                            />
                            <CompanyDocumentation
                                data={data}
                                messageApi={messageApi}
                                setTrigger={setTrigger}
                                role={role}
                            />
                            <CloseTenderForm data={data} messageApi={messageApi} setTrigger={setTrigger} role={role} />
                        </>
                    ) : (
                        <>
                            {role === 'school_user' && !data.tasks_deadline_at && (
                                <div className="noTasks">
                                    <img src={AwardWelcome} />
                                    <div className="noTasks__title">Welcome to the Award Phase!</div>
                                    <div className="noTasks__subtitle">
                                        Watch this space - new tasks for you will be posted here as they become
                                        available.
                                    </div>
                                </div>
                            )}
                            {role === 'school_user' && data.tasks_deadline_at && (
                                <div className="noTasks">
                                    <img src={Notif} />
                                    <div className="noTasks__title">Negotiations are currently in progress</div>
                                    <div className="noTasks__subtitle" style={{ width: '395px' }}>
                                        The results of these discussions will be published in the Supplier Report Card
                                        on
                                    </div>
                                    <div className="noTasks__date">
                                        <img src={calendar} className="noTasks__image" />{' '}
                                        {moment(data.tasks_deadline_at).format('DD MMMM, YYYY')}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>

                {role === 'tender_representative' &&
                    data?.phase_tasks?.[0]?.status !== 'pending_su' &&
                    data?.phase_tasks?.[3]?.status !== 'pending_sa' &&
                    data?.phase_name !== 'Closed' &&
                    !data.can_update_phase && (
                        <div className="tasks__btn-wrap">
                            <div style={{ width: '47%' }}></div>

                            <Tooltip
                                title={
                                    !data?.can_send_task
                                        ? data.phase_tasks[0]?.status !== 'pending_su' &&
                                          !data.phase_tasks[0]?.approved &&
                                          !data.phase_tasks[0]?.declined
                                            ? data?.tasks_deadline_at
                                                ? 'To send this task, you need complete all Task Activities'
                                                : 'To send this task, please set a deadline for the school user first'
                                            : 'To send this task, you need complete all Task Activities'
                                        : ''
                                }
                                overlayClassName={` ${
                                    !data?.can_send_task && data.phase_tasks?.[0]?.status !== 'pending_su'
                                        ? 'custom-tooltip-disabled'
                                        : 'custom-tooltip'
                                }`}
                            >
                                <Button
                                    className={`tasks__next-btn ${data?.can_send_task ? '' : 'disabled'}`}
                                    type="primary"
                                    onClick={() => {
                                        if (!data?.can_send_task) {
                                            return;
                                        } else {
                                            if (!isLoadingSendTask) {
                                                sendTaskFunc();
                                            }
                                        }
                                    }}
                                >
                                    Send Task <img src={arrow} />
                                </Button>
                            </Tooltip>
                        </div>
                    )}

                {role === 'school_user' &&
                    data.phase_tasks.length > 0 &&
                    data?.phase_tasks[0].status !== 'need_resend_tr' &&
                    data?.phase_tasks[0].status !== 'completed' &&
                    data.phase_tasks[0].action !== 'award_countersigned_agreement' && (
                        <div className="tasks__btn-wrap">
                            <div></div>

                            <Tooltip
                                title="To send this task, you need to complete all the Task Activities"
                                overlayClassName={` ${
                                    !data?.can_send_task && data.phase_tasks?.[0]?.status !== 'need_resend_tr'
                                        ? 'custom-tooltip-disabled'
                                        : 'custom-tooltip'
                                }`}
                            >
                                <Button
                                    className={`tasks__next-btn ${data?.can_send_task ? '' : 'disabled'}`}
                                    type="primary"
                                    onClick={() => {
                                        if (!data?.can_send_task) {
                                            return;
                                        } else {
                                            if (!isLoadingSendTask) {
                                                sendTaskFunc();
                                            }
                                        }
                                    }}
                                >
                                    Submit <img src={arrow} />
                                </Button>
                            </Tooltip>
                        </div>
                    )}
                {role === 'super_admin' &&
                    data.phase_tasks.length > 0 &&
                    data?.phase_tasks[0].status === 'pending_sa' && (
                        <div className="tasks__btn-wrap">
                            <div></div>

                            {data?.phase_tasks[0].approved && !data.phase_tasks[0].declined && (
                                <Button
                                    className={`tasks__next-btn`}
                                    type="primary"
                                    onClick={() => {
                                        if (data?.can_update_phase) {
                                            setConfirmModal(true);
                                        } else {
                                            return;
                                        }
                                    }}
                                >
                                    Close Tender <img src={arrow} />
                                </Button>
                            )}
                            {data?.phase_tasks[0].declined && !data?.phase_tasks[0].approved && (
                                <Button
                                    className={`tasks__next-btn`}
                                    type="primary"
                                    onClick={() => {
                                        if (data?.can_send_task) {
                                            if (!isLoadingSendTask) {
                                                sendTaskFunc();
                                            }
                                        }
                                    }}
                                >
                                    Send Back <img src={arrow} />
                                </Button>
                            )}
                            {!data?.phase_tasks[0].declined && !data?.phase_tasks[0].approved && (
                                <Tooltip>
                                    <Button
                                        className={`tasks__next-btn ${data?.can_send_task ? '' : 'disabled'}`}
                                        type="primary"
                                    >
                                        Send Task <img src={arrow} />
                                    </Button>
                                </Tooltip>
                            )}
                        </div>
                    )}
            </div>
            {deadlineModal && (
                <TaskDeadlineModal
                    openModal={deadlineModal}
                    setOpenModal={setDeadlineModal}
                    SelectedDate={SelectedDate}
                    setSelectedDate={setSelectedDate}
                    updateDeadlineFunc={updateDeadlineFunc}
                    isLoading={isLoading}
                />
            )}
        </>
    );
};
