import { FC, useState, useEffect } from 'react';
import { Button, Form, message, Modal } from 'antd';
import { Select } from '../Fields/Select';
import { useDeclineTaskMutation } from '../../api/tenders';
import { Loader } from '../Loader/Loader';
import { tenderDataProps } from '../../pages/TenderViewPage/types';
import clock from '../../assets/clock.svg';
import close from '../../assets/RoundedClose.svg';
import { DatePickerInput } from '../Fields/DatePickerInput';
import moment from 'moment';

interface MessageApi {
    success: (message: string) => void;
    error: (message: string) => void;
}

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi?: MessageApi;
    data?: tenderDataProps;
    taskId: number | null;
    isEditSupplier: boolean;
}

interface TimeObject {
    value: string | null;
    id: number | null;
}

interface TimeSlot {
    date: string;
    time_from: { value: string };
    time_to: { value: string };
}

export const RescheduleModal: FC<ModalProps> = ({
    openModal,
    setOpenModal,
    messageApi,
    data,
    setTrigger,
    taskId,
    isEditSupplier
}) => {
    const [errors, setErrors] = useState<{ slot1: boolean; slot2: boolean }>({
        slot1: false,
        slot2: false
    });
    const [isDirty, setIsDirty] = useState(false);
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [formData, setFormData] = useState<any>({
        slot1: {
            date: '',
            time_from: '',
            time_to: ''
        },
        slot2: {
            date: '',
            time_from: '',
            time_to: ''
        }
    });
    const [duration, setDuration] = useState<any>();
    const [declineTask, { data: declinedData, isLoading, isSuccess }] = useDeclineTaskMutation();

    useEffect(() => {
        if (!data) return;
        const task = data?.phase_tasks.find((elem) => elem.action === 'define_phase_meeting');
        if (task?.slots?.length) {
            setFormData({
                slot1: {
                    date: new Date(task?.slots[0]?.date),
                    time_from: { value: task?.slots[0]?.time_from, id: task?.slots[0]?.time_from },
                    time_to: { value: task?.slots[0]?.time_to, id: task?.slots[0]?.time_to }
                },
                slot2: {
                    date: new Date(task?.slots[1]?.date),
                    time_from: { value: task?.slots[1]?.time_from, id: task?.slots[1]?.time_from },
                    time_to: { value: task?.slots[1]?.time_to, id: task?.slots[1]?.time_to }
                }
            });
        }
    }, [data]);

    useEffect(() => {
        if (isSuccess) {
            message.success(declinedData?.message);
            setOpenModal(false);
            setTrigger((prev: boolean) => !prev);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (!data) return;
        const task = data?.phase_tasks?.[0];
        if (!task) return;
        //@ts-ignore
        const [fromHours, fromMinutes] = task?.time_from?.split(':').map(Number);
        const [toHours, toMinutes] = task?.time_to?.split(':').map(Number);
        const fromTotalMinutes = fromHours * 60 + fromMinutes;
        const toTotalMinutes = toHours * 60 + toMinutes;
        const durationMinutes = toTotalMinutes - fromTotalMinutes;
        setDuration(durationMinutes);
    }, [data]);

    function generateTimeArray(): TimeObject[] {
        const timeArray: TimeObject[] = [
            { value: '07:00', id: 1 },
            { value: '07:30', id: 3 },
            { value: '08:00', id: 5 },
            { value: '08:30', id: 7 },
            { value: '09:00', id: 9 },
            { value: '09:30', id: 11 },
            { value: '10:00', id: 13 },
            { value: '10:30', id: 15 },
            { value: '11:00', id: 17 },
            { value: '11:30', id: 19 },
            { value: '12:00', id: 21 },
            { value: '12:30', id: 23 },
            { value: '13:00', id: 25 },
            { value: '13:30', id: 27 },
            { value: '14:00', id: 29 },
            { value: '14:30', id: 31 },
            { value: '15:00', id: 33 },
            { value: '15:30', id: 35 },
            { value: '16:30', id: 38 }
        ];
        return timeArray;
    }

    const timeArray: TimeObject[] = generateTimeArray();

    const handleFormSubmit = () => {
        let isValid = true;

        if (!formData.slot1.date || !formData.slot1.time_from) {
            setErrors((prev) => ({ ...prev, slot1: true }));
            isValid = false;
        }
        if (!formData.slot2.date || !formData.slot2.time_from) {
            setErrors((prev) => ({ ...prev, slot2: true }));
            isValid = false;
        }

        if (isValid) {
            const dataToDecline = {
                ids: {
                    tenderId: data?.id,
                    taskId: data?.phase_tasks.find((elem) => elem.action === 'define_phase_meeting')?.id
                },
                data: {
                    slots: [
                        {
                            date: moment(formData.slot1.date).format('YYYY-MM-DD'),
                            time_from: formData.slot1.time_from.value,
                            time_to: formData.slot1.time_to.value
                        },
                        {
                            date: moment(formData.slot2.date).format('YYYY-MM-DD'),
                            time_from: formData.slot2.time_from.value,
                            time_to: formData.slot2.time_to.value
                        }
                    ]
                }
            };
            declineTask(dataToDecline);
        }
    };

    const timeToMinutes = (time: string): number => {
        const [hours, minutes] = time?.split(':')?.map(Number);
        return hours * 60 + minutes;
    };

    const checkTimeOverlap = (slot1: TimeSlot, slot2: TimeSlot): boolean => {
        if (!slot1?.time_from || !slot2?.time_from || !slot1?.date || !slot2?.date) return false;

        const isSameDate = moment(slot1.date).format('DD:MM:YYYY') === moment(slot2.date).format('DD:MM:YYYY');

        if (!isSameDate) return false;

        const slot1Start = timeToMinutes(slot1.time_from.value);
        const slot1End = timeToMinutes(slot1.time_to.value);
        const slot2Start = timeToMinutes(slot2.time_from.value);
        const slot2End = timeToMinutes(slot2.time_to.value);

        return slot1Start < slot2End && slot2Start < slot1End;
    };

    const handleInputChange = (slot: string, date: any, name: string) => {
        setIsDirty(true);
        setErrors((prev) => ({ ...prev, [slot]: false }));

        setFormData((prevState: any) => {
            const updatedSlots: any = { ...prevState };
            if (name === 'time_from' && date?.value) {
                const [hours, minutes] = date?.value?.split(':')?.map(Number);
                const timeFromDate = new Date(1970, 0, 1, hours, minutes);

                if (duration) {
                    const timeToDate = new Date(timeFromDate.getTime() + duration * 60 * 1000);
                    const formattedTimeTo = timeToDate.toTimeString().slice(0, 5);
                    updatedSlots[slot].time_to = { id: formattedTimeTo, value: formattedTimeTo };
                }
            }

            updatedSlots[slot][name] = date;

            return { ...updatedSlots };
        });
    };

    return (
        <>
            <Modal
                className="modal-supplier-patricipants"
                destroyOnClose
                centered
                open={openModal}
                onOk={() => handleFormSubmit()}
                onCancel={() => {
                    if (isDirty) {
                        setIsConfirmModal(true);
                    } else {
                        setOpenModal(false);
                    }
                }}
                closeIcon={<img src={close} />}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            block
                            key="back"
                            onClick={() => {
                                if (isDirty) {
                                    setIsConfirmModal(true);
                                } else {
                                    setOpenModal(false);
                                }
                            }}
                            className="cancel"
                        >
                            Cancel
                        </Button>
                        <Button
                            block
                            key="submit"
                            type="primary"
                            onClick={handleFormSubmit}
                            disabled={checkTimeOverlap(formData.slot1, formData.slot2)}
                        >
                            {isLoading ? <Loader /> : 'Save'}
                        </Button>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header meeting" style={{ height: '88px' }}>
                        <div className="modal__title name-title">Reschedule Meeting?</div>
                    </div>

                    <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                        <div className="main__subtitle" style={{ margin: '0 0 6px 0' }}>
                            Provide at least two alternatives for the Define Phase Meeting{' '}
                        </div>

                        <Form onFinish={handleFormSubmit} onChange={() => setIsDirty(true)}>
                            <div className={`slot ${errors.slot1 ? 'error' : ''}`} id="participants">
                                <div className="slot__title">Slot 1</div>
                                <Form className="slot__form">
                                    <Form.Item>
                                        <DatePickerInput
                                            onChange={(value: any) => {
                                                setFormData((prev: any) => ({
                                                    ...prev,
                                                    slot1: { ...prev.slot1, date: value }
                                                }));
                                                setErrors((prev) => ({ ...prev, slot1: false }));
                                            }}
                                            value={formData.slot1.date}
                                            required={true}
                                            placeholder=""
                                            disabled={false}
                                            floatLabel="Meeting Date"
                                            filterDisabled={false}
                                        />
                                    </Form.Item>
                                    <div className="main__row">
                                        <Form.Item className="small-input-wrapper">
                                            <Select
                                                setChanged={() => {}}
                                                onChange={(data: string) =>
                                                    handleInputChange('slot1', data, 'time_from')
                                                }
                                                floatLabel="Time From"
                                                className="input"
                                                maxLength={60}
                                                required={true}
                                                hasError={() => false}
                                                options={timeArray}
                                                image={clock}
                                                value={formData.slot1.time_from}
                                            />
                                        </Form.Item>
                                        <Form.Item className="small-input-wrapper">
                                            <Select
                                                setChanged={() => {}}
                                                disabled={true}
                                                floatLabel="Time To"
                                                className="input"
                                                maxLength={60}
                                                required={true}
                                                hasError={() => false}
                                                options={timeArray}
                                                image={clock}
                                                value={formData.slot1.time_to}
                                            />
                                        </Form.Item>
                                    </div>
                                </Form>
                            </div>
                            {errors.slot1 && (
                                <div className="error-text" style={{ margin: '-15px 0 10px 0' }}>
                                    These are required fields
                                </div>
                            )}
                            <div className={`slot ${errors.slot2 ? 'error' : ''}`} id="participants">
                                <div className="slot__title">Slot 2</div>
                                <Form className="slot__form">
                                    <Form.Item className="input-wrapper">
                                        <DatePickerInput
                                            onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                                                setFormData((prev: any) => ({
                                                    ...prev,
                                                    slot2: { ...prev.slot2, date: value }
                                                }));
                                                setErrors((prev) => ({ ...prev, slot2: false }));
                                            }}
                                            value={formData.slot2.date}
                                            required={true}
                                            placeholder=""
                                            disabled={false}
                                            floatLabel="Meeting Date"
                                            filterDisabled={false}
                                        />
                                    </Form.Item>
                                    <div className="main__row">
                                        <Form.Item className="small-input-wrapper">
                                            <Select
                                                setChanged={() => {}}
                                                onChange={(data: string) =>
                                                    handleInputChange('slot2', data, 'time_from')
                                                }
                                                floatLabel="Time From"
                                                className="input"
                                                maxLength={60}
                                                required={true}
                                                hasError={() => false}
                                                options={timeArray}
                                                image={clock}
                                                value={formData.slot2.time_from}
                                            />
                                        </Form.Item>
                                        <Form.Item className="small-input-wrapper">
                                            <Select
                                                setChanged={() => {}}
                                                disabled={true}
                                                floatLabel="Time To"
                                                className="input"
                                                maxLength={60}
                                                required={true}
                                                hasError={() => false}
                                                options={timeArray}
                                                image={clock}
                                                value={formData.slot2.time_to}
                                            />
                                        </Form.Item>
                                    </div>
                                </Form>
                            </div>
                            {errors.slot2 && (
                                <div className="error-text" style={{ margin: '-15px 0 10px 0' }}>
                                    These are required fields
                                </div>
                            )}
                            {checkTimeOverlap(formData.slot1, formData.slot2) && (
                                <div
                                    className="error-text"
                                    style={{ width: '100%', textAlign: 'center', margin: '20px 0 15px 0' }}
                                >
                                    Time slots cannot overlap
                                </div>
                            )}
                        </Form>
                    </div>
                </div>
            </Modal>
            <Modal
                className="confirmModal"
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                setOpenModal(false);
                                setIsConfirmModal(false);
                            }}
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Your data won’t be saved! Are you sure you want to quit?</div>
            </Modal>
        </>
    );
};
