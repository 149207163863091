import { useState, FC, useEffect } from 'react';
import { Button, message, Modal, Tooltip } from 'antd';
import { taskProps, tenderDataProps } from '../../../pages/TenderViewPage/types';
import { CreateMeetingModal } from '../../Modals/CreateMeetingModal';
import { Loader } from '../../Loader/Loader';
import { useApproveTaskMutation, useResetActionMutation, useUpdateTaskMutation } from '../../../api/tenders';
import defineMeeting from '../../../assets/define-meeting.svg';
import plus from '../../../assets/Plus, Add.svg';
import calendar from '../../../assets/calendar-chedule.svg';
import clock from '../../../assets/clock.svg';
import pin from '../../../assets/pinIcon.svg';
import edit from '../../../assets/editAdminIcon.svg';
import deleteIcon from '../../../assets/DeleteIcon.svg';
import stopImage from '../../../assets/stopImage.svg';
import done from '../../../assets/Done, Check.svg';
import pendingIcon from '../../../assets/panding-circle.svg';
import i from '../../../assets/i.svg';
import editIcon from '../../../assets/editAdminIcon.svg';
import moment from 'moment';
import { RescheduleModal } from '../../Modals/RescheduleModal';
import { ClockIcon } from '../../Icons/ClockIcon';
import { SpinnerIcon } from '../../Icons/SpinnerIcon';

interface DefineMeetingTaskProps {
    data: tenderDataProps;
    role: string | null;
    setTaskId: React.Dispatch<React.SetStateAction<string | number | null>>;
    setIsCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    setDeadlineModal: React.Dispatch<React.SetStateAction<boolean>>;
    setApproveMeeting: React.Dispatch<React.SetStateAction<boolean>>;
    sendActivitiesFunc: any;
    checked: string;
    setChecked: React.Dispatch<React.SetStateAction<string>>;
    taskId: string | null | number;
    messageApi: any;
    isCreateModal: boolean;
    approveMeeting: boolean;
    isLoadingApproveTask: boolean;
    sendTaskFunc: () => void;
}

export const DefineMeetingTask: FC<DefineMeetingTaskProps> = ({
    data,
    role,
    setTaskId,
    setIsCreateModal,
    setTrigger,
    sendActivitiesFunc,
    checked,
    setChecked,
    taskId,
    messageApi,
    isCreateModal,
    sendTaskFunc,
    setApproveMeeting,
    isLoadingApproveTask,
    approveMeeting,
    setDeadlineModal
}) => {
    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);
    function handleResize() {
        setScreenWidth(window.screen.width);
    }
    window.addEventListener('resize', handleResize);
    const [openCreateMeetingModal, setOpenCreateMeetintModal] = useState<boolean>(false);
    const [declineMeeting, setDeclineMeeting] = useState(false);
    const [rescheduleModal, setRescheduleModal] = useState(false);
    const [resetTask, { data: resetTaskData, isSuccess, isLoading }] = useResetActionMutation();
    const [selectedSlot, setSelectedSlot] = useState<number | null>(null);
    const [updateTask, { isSuccess: isSuccessUpdated, isLoading: isLoadingUpdated }] = useUpdateTaskMutation();
    const [approveTask, { isLoading: isLoadingApprovedTask, isSuccess: isSuccessApproveTask }] =
        useApproveTaskMutation();

    const [isConfirmMeeting, setIsConfirmMeeting] = useState(false);

    useEffect(() => {
        if (isSuccess && resetTaskData) {
            message.success(resetTaskData.message);
            setDeclineMeeting(false);
            setTrigger((prev: boolean) => !prev);
        }
    }, [isSuccess, resetTaskData]);

    const deleteSlots = async () => {
        try {
            const formData = {
                tenderId: data?.id,
                taskId: taskId,
                data: { action: 'decline' }
            };
            await resetTask(formData).unwrap();
        } catch {}
    };

    useEffect(() => {
        if (isSuccessUpdated || isSuccessApproveTask) {
            setIsConfirmMeeting(false);
            setTrigger((prev) => !prev);
        }
    }, [isSuccessUpdated, isSuccessApproveTask]);

    const acceptTask = () => {
        if (isLoadingUpdated) return;
        const task = data?.phase_tasks?.find((elem) => elem.action === 'define_phase_meeting');
        const slot = selectedSlot === 1 ? task?.slots?.[0] : task?.slots?.[1];
        const formData = {
            ids: {
                tender_id: data?.id,
                task_id: taskId
            },
            data: {
                date: slot.date,
                time_from: slot.time_from,
                time_to: slot.time_to
            }
        };

        updateTask(formData).unwrap();
    };
    const approveTaskFunc = () => {
        if (isLoadingApprovedTask) return;
        const formData = {
            tenderId: data?.id,
            taskId: taskId
        };

        approveTask(formData).unwrap();
    };

    const isTheSameSlots = () => {
        const task = data?.phase_tasks?.find((elem) => elem.action === 'define_phase_meeting');
        //@ts-ignore
        const { date, time_from, time_to, slots } = task;

        return (
            slots?.find(
                (slot: any) => slot.date === date && slot.time_from === time_from && slot.time_to === time_to
            ) || null
        );
    };

    return (
        <>
            {data?.phase_tasks.length &&
                data?.phase_tasks.map((elem: taskProps) => {
                    if (elem.action === 'define_phase_meeting') {
                        if (role !== 'school_user') {
                            if (elem.status === 'pending_tr' && !elem.date && !elem.approved && !elem?.slots?.length) {
                                return (
                                    <>
                                        <div
                                            key={elem.id}
                                            className={`tasks__task task ${
                                                elem.status !== 'pending_tr' ? 'green' : ''
                                            }`}
                                        >
                                            <div className="task__title">
                                                <div className="task-complete__title-row">
                                                    <img src={defineMeeting} />
                                                    {elem?.name}
                                                </div>
                                                <div className="task-complete__title-row"></div>
                                            </div>
                                            {role === 'tender_representative' && (
                                                <img
                                                    src={plus}
                                                    className="task__plus"
                                                    onClick={() => {
                                                        setTaskId(elem.id);
                                                        setIsCreateModal(true);
                                                        setOpenCreateMeetintModal(true);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </>
                                );
                            }
                            if (
                                elem.status === 'need_resend_tr' &&
                                elem.date &&
                                !elem.approved &&
                                !elem?.slots?.length
                            ) {
                                return (
                                    <>
                                        <div
                                            key={elem.id}
                                            className={`tasks__task-complete task-complete green
                            `}
                                        >
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={defineMeeting} />
                                                    {elem?.name}
                                                </div>
                                                <div className="task-complete__title-row"></div>
                                            </div>
                                            <div className="task-complete__info-wrap">
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Date</div>
                                                    <div
                                                        className="task-complete__name"
                                                        style={{ margin: '0 77px 0 0' }}
                                                    >
                                                        Time
                                                    </div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value" style={{ padding: '0' }}>
                                                        <img src={calendar} />
                                                        {elem.date}
                                                    </div>
                                                    <div className="task-complete__value">
                                                        <img src={clock} />
                                                        {elem.time_from}- {elem.time_to}
                                                    </div>
                                                </div>

                                                {role !== 'super_admin' && (
                                                    <img
                                                        src={edit}
                                                        className="task-complete__edit"
                                                        onClick={() => {
                                                            setTaskId(elem.id);
                                                            setIsCreateModal(false);
                                                            setOpenCreateMeetintModal(true);
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            if (
                                elem.status === 'need_resend_tr' &&
                                elem.date &&
                                !elem.approved &&
                                elem?.slots?.length &&
                                !isTheSameSlots()
                            ) {
                                return (
                                    <>
                                        <div
                                            key={elem.id}
                                            className={`tasks__task-complete task-complete
                            `}
                                        >
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={defineMeeting} />
                                                    {elem?.name}
                                                </div>
                                                <div className="task-complete__title-row">
                                                    {' '}
                                                    <div className="task__pending" key={elem.id}>
                                                        New Slot Provided{' '}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div
                                                    className="task-complete__name"
                                                    style={{ width: '100%', margin: '15px 0 0 0' }}
                                                >
                                                    School User alternative slot(s)
                                                    <div>
                                                        <div className="task-complete__slots slots">
                                                            {elem.slots.map((slot: any, index: number) => {
                                                                return (
                                                                    <div className="slots__wrapper tr">
                                                                        <div className="slots__block checkbox disable">
                                                                            <div
                                                                                className={`tasks__checkbox disable`}
                                                                            ></div>
                                                                            Slot {index + 1}
                                                                        </div>
                                                                        <div className="slots__block TR">
                                                                            <div className="slots__name">Date</div>
                                                                            <div className="slots__value">
                                                                                <img src={calendar} />
                                                                                {slot.date}
                                                                            </div>
                                                                        </div>
                                                                        <div className="slots__block">
                                                                            <div className="slots__name">Time</div>
                                                                            <div className="slots__value">
                                                                                <ClockIcon
                                                                                    fill="#001965"
                                                                                    style={{ margin: '0 4px 0 0' }}
                                                                                />
                                                                                {slot.time_from} - {slot.time_to}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="task-complete__info-wrap">
                                                <div className="task-complete__wrap">
                                                    {' '}
                                                    <div className="task-complete__name">New Slot</div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Date</div>
                                                    <div
                                                        className="task-complete__name"
                                                        style={{ margin: '0 77px 0 0' }}
                                                    >
                                                        Time
                                                    </div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value" style={{ padding: '0' }}>
                                                        <img src={calendar} />
                                                        {elem.date}
                                                    </div>
                                                    <div className="task-complete__value">
                                                        <img src={clock} />
                                                        {elem.time_from}- {elem.time_to}
                                                    </div>
                                                </div>
                                                {role !== 'super_admin' && (
                                                    <>
                                                        {' '}
                                                        <img
                                                            src={edit}
                                                            className="task-complete__edit"
                                                            style={{ right: '60px' }}
                                                            onClick={() => {
                                                                setTaskId(elem.id);
                                                                setIsCreateModal(false);
                                                                setOpenCreateMeetintModal(true);
                                                            }}
                                                        />
                                                        <img
                                                            src={deleteIcon}
                                                            className="task-complete__edit"
                                                            onClick={() => setDeclineMeeting(true)}
                                                        />{' '}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            if (
                                elem.status === 'need_resend_tr' &&
                                elem.date &&
                                !elem.approved &&
                                elem?.slots?.length &&
                                isTheSameSlots()
                            ) {
                                return (
                                    <>
                                        <div
                                            key={elem.id}
                                            className={`tasks__task-complete task-complete green
                            `}
                                        >
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={defineMeeting} />
                                                    {elem?.name}
                                                </div>
                                                <div className="task-complete__title-row">
                                                    <div
                                                        className="task-complete__decline"
                                                        onClick={() => {
                                                            setTaskId(elem.id);
                                                            setIsCreateModal(true);
                                                            setOpenCreateMeetintModal(true);
                                                        }}
                                                    >
                                                        New Slot
                                                    </div>
                                                    <div className="task-complete__approved-active">
                                                        <img src={done} />
                                                        Accepted
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="note" style={{ margin: '15px 0' }}>
                                                <img src={i} />

                                                <div>
                                                    Reminder: meeting invites need to be sent manually after
                                                    confirmation.
                                                </div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div
                                                    className="task-complete__name"
                                                    style={{ width: '100%', margin: '0 0 0 0' }}
                                                >
                                                    School User alternative slot(s)
                                                    <div>
                                                        <div className="task-complete__slots slots">
                                                            <div className="slots__wrapper tr">
                                                                <div className="slots__block TR">
                                                                    <div className="slots__name">Date</div>
                                                                    <div className="slots__value">
                                                                        <img src={calendar} />
                                                                        {isTheSameSlots()?.date}
                                                                    </div>
                                                                </div>
                                                                <div className="slots__block">
                                                                    <div className="slots__name">Time</div>
                                                                    <div className="slots__value">
                                                                        <ClockIcon
                                                                            fill="#001965"
                                                                            style={{ margin: '0 4px 0 0' }}
                                                                        />
                                                                        {isTheSameSlots()?.time_from} -{' '}
                                                                        {isTheSameSlots()?.time_to}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                );
                            }

                            if (elem.status === 'pending_su') {
                                return (
                                    <>
                                        <div className={`tasks__task-complete task-complete grey`} key={elem.id}>
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={defineMeeting} />
                                                    {elem?.name}
                                                </div>

                                                <div className="task-complete__title-row">
                                                    <div className="task__pending" key={elem.id}>
                                                        <img src={pendingIcon} />
                                                        Pending
                                                    </div>
                                                </div>
                                            </div>
                                            {/* {elem.approved && (
                                                <div className="note" style={{ margin: '15px 0' }}>
                                                    <img src={i} />

                                                    <div>
                                                        Reminder: meeting invites need to be sent manually after
                                                        confirmation.
                                                    </div>
                                                </div>
                                            )} */}
                                            <div className="task-complete__title-row" style={{ padding: '10px 0 0 0' }}>
                                                <div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__name">Date</div>
                                                        <div
                                                            className="task-complete__name"
                                                            style={{ margin: '0 77px 0 0' }}
                                                        >
                                                            Time
                                                        </div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value" style={{ padding: '0' }}>
                                                            <img src={calendar} />
                                                            {elem.date}
                                                        </div>
                                                        <div className="task-complete__value">
                                                            <img src={clock} />
                                                            {elem.time_from}- {elem.time_to}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`tasks__resend  resend ${
                                                        data?.tasks_deadline_at ? '' : 'disable'
                                                    }`}
                                                    onClick={() => {
                                                        setTaskId(elem.id);
                                                        setIsCreateModal(false);
                                                        setOpenCreateMeetintModal(true);
                                                    }}
                                                >
                                                    <SpinnerIcon
                                                        fill={!data?.tasks_deadline_at ? '#b3bad1' : '#001965'}
                                                        style={{ margin: '0 10px 0 0' }}
                                                    />
                                                    Resend
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            if (elem.status === 'need_approve_tr' && elem.approved) {
                                return (
                                    <>
                                        <div
                                            key={elem.id}
                                            className={`tasks__task-complete task-complete  ${
                                                checked ? 'blue' : 'grey'
                                            }`}
                                        >
                                            <div className="task-complete__title">
                                                <div>
                                                    <div className="task-complete__title-row">
                                                        <img src={defineMeeting} />
                                                        {elem?.name}
                                                    </div>
                                                    <div className="task-complete__title-row"></div>
                                                </div>
                                                <div>
                                                    {role !== 'super_admin' && (
                                                        <div className="tasks__checkbox-wrapper">
                                                            <div className="tasks__checkbox-container">
                                                                <div
                                                                    className={`tasks__checkbox ${
                                                                        checked === 'no_show' ? 'active' : ''
                                                                    }`}
                                                                    onClick={() => setChecked('no_show')}
                                                                ></div>
                                                                <div>No Show</div>
                                                            </div>
                                                            <div
                                                                className="tasks__checkbox-container"
                                                                onClick={() => setChecked('took_place')}
                                                            >
                                                                <div
                                                                    className={`tasks__checkbox ${
                                                                        checked === 'took_place' ? 'active' : ''
                                                                    }`}
                                                                ></div>
                                                                <div>Took Place</div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="note" style={{ margin: '15px 0' }}>
                                                <img src={i} />

                                                <div>
                                                    Reminder: meeting invites need to be sent manually after
                                                    confirmation.
                                                </div>
                                            </div>
                                            <div className="task-complete__info-wrap">
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Date</div>
                                                    <div
                                                        className="task-complete__name"
                                                        style={{ margin: '0 77px 0 0' }}
                                                    >
                                                        Time
                                                    </div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value" style={{ padding: '0' }}>
                                                        <img src={calendar} />
                                                        {elem.date}
                                                    </div>
                                                    <div className="task-complete__value">
                                                        <img src={clock} />
                                                        {elem.time_from}- {elem.time_to}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            if (elem.status === 'pending_tr' && elem?.slots?.length) {
                                return (
                                    <div className={`tasks__task-complete task-complete`} key={elem.id}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={defineMeeting} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <div className="task-complete__title-row">
                                                    <div
                                                        className="task-complete__decline"
                                                        onClick={() => {
                                                            setTaskId(elem.id);
                                                            setIsCreateModal(true);
                                                            setOpenCreateMeetintModal(true);
                                                        }}
                                                    >
                                                        New Slot
                                                    </div>
                                                    <Tooltip
                                                        title="To accept the meeting, you must select a time slot first."
                                                        overlayClassName={` ${
                                                            !selectedSlot ? 'custom-tooltip-disabled' : 'custom-tooltip'
                                                        }`}
                                                    >
                                                        <div
                                                            className={`task-complete__approved ${
                                                                !selectedSlot ? 'disabled-blue-button' : ''
                                                            }`}
                                                            onClick={() => {
                                                                if (selectedSlot) {
                                                                    acceptTask();
                                                                }
                                                            }}
                                                        >
                                                            Accept
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="task-complete__wrap">
                                            <div
                                                className="task-complete__name"
                                                style={{ width: '100%', margin: '15px 0 0 0' }}
                                            >
                                                School User alternative slot(s)
                                                <div>
                                                    <div className="task-complete__slots slots">
                                                        {elem.slots.map((slot: any, index: number) => {
                                                            return (
                                                                <div
                                                                    className="slots__wrapper tr"
                                                                    onClick={() => setSelectedSlot(index + 1)}
                                                                >
                                                                    <div className="slots__block checkbox">
                                                                        <div
                                                                            className={`tasks__checkbox ${
                                                                                selectedSlot === index + 1
                                                                                    ? 'active'
                                                                                    : ''
                                                                            }`}
                                                                        ></div>
                                                                        Slot {index + 1}
                                                                    </div>
                                                                    <div className="slots__block TR">
                                                                        <div className="slots__name">Date</div>
                                                                        <div className="slots__value">
                                                                            <img src={calendar} />
                                                                            {slot.date}
                                                                        </div>
                                                                    </div>
                                                                    <div className="slots__block">
                                                                        <div className="slots__name">Time</div>
                                                                        <div className="slots__value">
                                                                            <ClockIcon
                                                                                fill="#001965"
                                                                                style={{ margin: '0 4px 0 0' }}
                                                                            />
                                                                            {slot.time_from} - {slot.time_to}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        }
                        if (role === 'school_user') {
                            if (elem.status === 'pending_su' && !elem.approved && !elem?.slots?.length) {
                                return (
                                    <div className={`tasks__task-complete task-complete`} key={elem.id}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={defineMeeting} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <div
                                                    className="task-complete__decline"
                                                    onClick={() => {
                                                        setTaskId(elem.id);
                                                        setRescheduleModal(true);
                                                    }}
                                                >
                                                    Reschedule
                                                </div>

                                                <div
                                                    className={`task-complete__approved`}
                                                    onClick={() => {
                                                        setIsConfirmMeeting(true);
                                                    }}
                                                >
                                                    Confirm Meeting
                                                </div>
                                            </div>
                                        </div>
                                        <div className="note" style={{ margin: '15px 0' }}>
                                            <img src={i} />
                                            <div>
                                                Proposed meeting date and time. Confirm or Reschedule to receive an
                                                invite.
                                            </div>
                                        </div>
                                        <div className="task-complete__wrap">
                                            <div className="task-complete__name">Date</div>
                                            <div className="task-complete__name" style={{ margin: '0 77px 0 0' }}>
                                                Time
                                            </div>
                                        </div>
                                        <div className="task-complete__wrap">
                                            <div className="task-complete__value" style={{ padding: '0' }}>
                                                <img src={calendar} />
                                                {elem.date}
                                            </div>
                                            <div className="task-complete__value">
                                                <img src={clock} />
                                                {elem.time_from}- {elem.time_to}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                            if (elem.status === 'pending_su' && elem.declined && elem.slots.length) {
                                return (
                                    <div className={`tasks__task-complete task-complete red`} key={elem.id}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={defineMeeting} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <div className="task-complete__decline-active">
                                                    <img src={stopImage} />
                                                    Proposed for Rescheduling{' '}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="note" style={{ margin: '15px 0' }}>
                                            <img src={i} />
                                            <div>
                                                Proposed meeting date and time. Confirm or Reschedule to receive an
                                                invite.
                                            </div>
                                        </div>
                                        <div className="task-complete__wrap">
                                            <div className="task-complete__name">Date</div>
                                            <div className="task-complete__name" style={{ margin: '0 77px 0 0' }}>
                                                Time
                                            </div>
                                        </div>
                                        <div className="task-complete__wrap">
                                            <div className="task-complete__value" style={{ padding: '0' }}>
                                                <img src={calendar} />
                                                {elem.date}
                                            </div>
                                            <div className="task-complete__value">
                                                <img src={clock} />
                                                {elem.time_from}- {elem.time_to}
                                            </div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__name" style={{ width: '100%' }}>
                                                    My alternative slot(s)
                                                    <div>
                                                        <div className="task-complete__slots slots">
                                                            {elem.slots.map((slot: any) => {
                                                                return (
                                                                    <div className="slots__wrapper">
                                                                        <div className="slots__block">
                                                                            <div className="slots__name">Date</div>
                                                                            <div className="slots__value">
                                                                                <img src={calendar} />
                                                                                {slot.date}
                                                                            </div>
                                                                        </div>
                                                                        <div className="slots__block">
                                                                            <div className="slots__name">Time</div>
                                                                            <div className="slots__value">
                                                                                <ClockIcon
                                                                                    fill="#001965"
                                                                                    style={{ margin: '0 4px 0 0' }}
                                                                                />
                                                                                {slot.time_from} - {slot.time_to}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                            <img
                                                                src={editIcon}
                                                                className="supplier__delete"
                                                                onClick={() => {
                                                                    setTaskId(elem.id);
                                                                    setRescheduleModal(true);
                                                                }}
                                                            />
                                                            <img
                                                                src={deleteIcon}
                                                                className="supplier__delete"
                                                                onClick={() => setDeclineMeeting(true)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                            if (elem.status === 'need_resend_tr') {
                                return (
                                    <div className={`tasks__task-complete task-complete red`} key={elem.id}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={defineMeeting} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <div className="task-complete__decline-active">
                                                    <img src={stopImage} />
                                                    Proposed for Rescheduling{' '}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="note" style={{ margin: '15px 0' }}>
                                            <img src={i} />
                                            <div>
                                                Proposed meeting date and time. Confirm or Reschedule to receive an
                                                invite.
                                            </div>
                                        </div>
                                        <div className="task-complete__wrap">
                                            <div className="task-complete__name">Date</div>
                                            <div className="task-complete__name" style={{ margin: '0 77px 0 0' }}>
                                                Time
                                            </div>
                                        </div>
                                        <div className="task-complete__wrap">
                                            <div className="task-complete__value" style={{ padding: '0' }}>
                                                <img src={calendar} />
                                                {elem.date}
                                            </div>
                                            <div className="task-complete__value">
                                                <img src={clock} />
                                                {elem.time_from}- {elem.time_to}
                                            </div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__name" style={{ width: '100%' }}>
                                                    My alternative slot(s)
                                                    <div>
                                                        <div className="task-complete__slots slots">
                                                            {elem.slots.map((slot: any) => {
                                                                return (
                                                                    <div className="slots__wrapper">
                                                                        <div className="slots__block">
                                                                            <div className="slots__name">Date</div>
                                                                            <div className="slots__value">
                                                                                <img src={calendar} />
                                                                                {slot.date}
                                                                            </div>
                                                                        </div>
                                                                        <div className="slots__block">
                                                                            <div className="slots__name">Time</div>
                                                                            <div className="slots__value">
                                                                                <ClockIcon
                                                                                    fill="#001965"
                                                                                    style={{ margin: '0 4px 0 0' }}
                                                                                />
                                                                                {slot.time_from} - {slot.time_to}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                            <img
                                                                src={editIcon}
                                                                className="supplier__delete"
                                                                onClick={() => {
                                                                    setTaskId(elem.id);
                                                                    setRescheduleModal(true);
                                                                }}
                                                            />
                                                            <img
                                                                src={deleteIcon}
                                                                className="supplier__delete"
                                                                onClick={() => setDeclineMeeting(true)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                            if (elem.status === 'pending_tr' && elem.declined && elem.slots.length) {
                                return (
                                    <div className={`tasks__task-complete task-complete`} key={elem.id}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={defineMeeting} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <div className="task__pending" key={elem.id}>
                                                    <img src={pendingIcon} />
                                                    Pending
                                                </div>
                                            </div>
                                        </div>
                                        <div className="note" style={{ margin: '15px 0' }}>
                                            <img src={i} />
                                            <div>
                                                Proposed meeting date and time. Confirm or Reschedule to receive an
                                                invite.
                                            </div>
                                        </div>
                                        <div className="task-complete__wrap">
                                            <div className="task-complete__name">Date</div>
                                            <div className="task-complete__name" style={{ margin: '0 77px 0 0' }}>
                                                Time
                                            </div>
                                        </div>
                                        <div className="task-complete__wrap">
                                            <div className="task-complete__value disable" style={{ padding: '0' }}>
                                                <img src={calendar} />
                                                {elem?.sent_slot?.date}
                                            </div>
                                            <div className="task-complete__value disable">
                                                <img src={clock} />
                                                {elem?.sent_slot?.time_from}- {elem?.sent_slot?.time_to}
                                            </div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__name" style={{ width: '100%' }}>
                                                    My alternative slot(s)
                                                    <div>
                                                        <div className="task-complete__slots slots">
                                                            {elem.slots.map((slot: any) => {
                                                                return (
                                                                    <div className="slots__wrapper">
                                                                        <div className="slots__block">
                                                                            <div className="slots__name">Date</div>
                                                                            <div className="slots__value">
                                                                                <img src={calendar} />
                                                                                {slot.date}
                                                                            </div>
                                                                        </div>
                                                                        <div className="slots__block">
                                                                            <div className="slots__name">Time</div>
                                                                            <div className="slots__value">
                                                                                <ClockIcon
                                                                                    fill="#001965"
                                                                                    style={{ margin: '0 4px 0 0' }}
                                                                                />
                                                                                {slot.time_from} - {slot.time_to}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                            if (elem.status === 'pending_su' && elem.approved) {
                                return (
                                    <div className={`tasks__task-complete task-complete green`} key={elem.id}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={defineMeeting} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <div
                                                    className="task-complete__decline"
                                                    onClick={() => {
                                                        setTaskId(elem.id);
                                                        setRescheduleModal(true);
                                                    }}
                                                >
                                                    Reschedule
                                                </div>
                                                <div className="task-complete__approved-active">
                                                    <img src={done} />
                                                    Confirmed
                                                </div>
                                            </div>
                                        </div>
                                        <div className="note" style={{ margin: '15px 0' }}>
                                            <img src={i} />
                                            <div>
                                                Proposed meeting date and time. Confirm or Reschedule to receive an
                                                invite.
                                            </div>
                                        </div>
                                        <div className="task-complete__wrap">
                                            <div className="task-complete__name">Date</div>
                                            <div className="task-complete__name" style={{ margin: '0 77px 0 0' }}>
                                                Time
                                            </div>
                                        </div>
                                        <div className="task-complete__wrap">
                                            <div className="task-complete__value" style={{ padding: '0' }}>
                                                <img src={calendar} />
                                                {elem.date}
                                            </div>
                                            <div className="task-complete__value">
                                                <img src={clock} />
                                                {elem.time_from}- {elem.time_to}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                            if (elem.status === 'need_approve_tr') {
                                return (
                                    <div className={`tasks__task-complete task-complete`} key={elem.id}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={defineMeeting} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <div className="task__pending" key={elem.id}>
                                                    <img src={pendingIcon} />
                                                    Pending
                                                </div>
                                            </div>
                                        </div>
                                        <div className="note" style={{ margin: '15px 0' }}>
                                            <img src={i} />
                                            <div>
                                                Proposed meeting date and time. Confirm or Reschedule to receive an
                                                invite.
                                            </div>
                                        </div>
                                        <div className="task-complete__wrap">
                                            <div className="task-complete__name">Date</div>
                                            <div className="task-complete__name" style={{ margin: '0 77px 0 0' }}>
                                                Time
                                            </div>
                                        </div>
                                        <div className="task-complete__wrap">
                                            <div className="task-complete__value" style={{ padding: '0' }}>
                                                <img src={calendar} />
                                                {elem.date}
                                            </div>
                                            <div className="task-complete__value">
                                                <img src={clock} />
                                                {elem.time_from}- {elem.time_to}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                            if (elem.status === 'completed') {
                                return (
                                    <div className={`tasks__task-complete task-complete`} key={elem.id}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={defineMeeting} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <div className="task__pending" key={elem.id}>
                                                    <img src={pendingIcon} />
                                                    Pending
                                                </div>
                                            </div>
                                        </div>
                                        <div className="note" style={{ margin: '15px 0' }}>
                                            <img src={i} />
                                            <div>
                                                Proposed meeting date and time. Confirm or Reschedule to receive an
                                                invite.
                                            </div>
                                        </div>
                                        <div className="task-complete__wrap">
                                            <div className="task-complete__name">Date</div>
                                            <div className="task-complete__name" style={{ margin: '0 77px 0 0' }}>
                                                Time
                                            </div>
                                        </div>
                                        <div className="task-complete__wrap">
                                            <div className="task-complete__value" style={{ padding: '0' }}>
                                                <img src={calendar} />
                                                {elem.date}
                                            </div>
                                            <div className="task-complete__value">
                                                <img src={clock} />
                                                {elem.time_from}- {elem.time_to}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                            if (elem.status === 'declined') {
                                return (
                                    <div className={`tasks__task-complete task-complete red`} key={elem.id}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={defineMeeting} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <div className="task-complete__decline-active">
                                                    <img src={stopImage} />
                                                    Declined
                                                </div>
                                            </div>
                                        </div>
                                        <div className="note" style={{ margin: '15px 0' }}>
                                            <img src={i} />
                                            <div>
                                                Proposed meeting date and time. Confirm or Reschedule to receive an
                                                invite.
                                            </div>
                                        </div>
                                        <div className="task-complete__wrap">
                                            <div className="task-complete__name">Date</div>
                                            <div className="task-complete__name" style={{ margin: '0 77px 0 0' }}>
                                                Time
                                            </div>
                                        </div>
                                        <div className="task-complete__wrap">
                                            <div className="task-complete__value" style={{ padding: '0' }}>
                                                <img src={calendar} />
                                                {elem.date}
                                            </div>
                                            <div className="task-complete__value">
                                                <img src={clock} />
                                                {elem.time_from}- {elem.time_to}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        }
                    }
                })}

            {openCreateMeetingModal && (
                <CreateMeetingModal
                    openModal={openCreateMeetingModal}
                    setOpenModal={setOpenCreateMeetintModal}
                    data={data}
                    taskId={taskId}
                    messageApi={messageApi}
                    setTrigger={setTrigger}
                    isCreateModal={isCreateModal}
                />
            )}
            {approveMeeting && (
                <Modal
                    className="confirmModal"
                    centered
                    open={approveMeeting}
                    width={660}
                    closeIcon={<></>}
                    footer={
                        <div
                            style={{
                                display: 'flex',
                                justifyItems: 'space-between',
                                gap: '16px',
                                width: '100%',
                                margin: '25px 0 0 0'
                            }}
                        >
                            <Button key="back" onClick={() => setApproveMeeting(false)} block className="cancel">
                                Cancel
                            </Button>
                            <Button type="primary" style={{ width: '100%' }} onClick={sendTaskFunc}>
                                {isLoadingApproveTask ? <Loader /> : 'Confirm'}
                            </Button>
                        </div>
                    }
                >
                    <div className="modal-confirm">
                        <div className="modal-confirm__subtitle" style={{ color: '#4D5E93' }}>
                            Are you sure you want to Confirm the Define Phase Meeting on{' '}
                            <span>{data?.phase_tasks[0]?.date}</span> from{' '}
                            <span>{data?.phase_tasks[0]?.time_from}</span> to{' '}
                            <span>{data?.phase_tasks[0]?.time_to}</span>?
                        </div>
                    </div>
                </Modal>
            )}
            {isConfirmMeeting && (
                <Modal
                    className="confirmModal"
                    centered
                    open={isConfirmMeeting}
                    width={660}
                    closeIcon={<></>}
                    footer={
                        <div
                            style={{
                                display: 'flex',
                                justifyItems: 'space-between',
                                gap: '16px',
                                width: '100%',
                                margin: '25px 0 0 0'
                            }}
                        >
                            <Button key="back" onClick={() => setIsConfirmMeeting(false)} block className="cancel">
                                Cancel
                            </Button>
                            <Button type="primary" style={{ width: '100%' }} onClick={approveTaskFunc}>
                                {isLoadingApprovedTask ? <Loader /> : 'Yes, Confirm'}
                            </Button>
                        </div>
                    }
                >
                    <div className="modal-confirm">
                        <div className="modal-confirm__subtitle" style={{ color: '#4D5E93' }}>
                            <span>{moment(data?.phase_tasks[0]?.date).format('DD MMM YYYY')}</span> from{' '}
                            <span>{data?.phase_tasks[0]?.time_from}</span> to{' '}
                            <span>{data?.phase_tasks[0]?.time_to}</span>
                        </div>
                    </div>
                </Modal>
            )}
            {rescheduleModal && (
                <RescheduleModal
                    openModal={rescheduleModal}
                    setOpenModal={setRescheduleModal}
                    setTrigger={setTrigger}
                    taskId={1}
                    isEditSupplier={false}
                    data={data}
                />
            )}
            {declineMeeting && (
                <Modal
                    className="confirmModal"
                    centered
                    open={declineMeeting}
                    width={660}
                    closeIcon={<></>}
                    footer={
                        <div
                            style={{
                                display: 'flex',
                                justifyItems: 'space-between',
                                gap: '16px',
                                width: '100%',
                                margin: '25px 0 0 0'
                            }}
                        >
                            <Button key="back" onClick={() => setDeclineMeeting(false)} block className="cancel">
                                Cancel
                            </Button>
                            <Button style={{ width: '100%' }} type="primary" onClick={deleteSlots} className="">
                                {isLoading ? <Loader /> : 'Yes, Delete'}
                            </Button>
                        </div>
                    }
                >
                    <div className="modal-confirm">
                        <div className="modal-confirm__title" style={{ color: '#4D5E93' }}>
                            Delete Reschedule?{' '}
                        </div>
                        <div className="modal-confirm__subtitle" style={{ color: '#4D5E93' }}>
                            Are you sure you want delete provided alternatives for the Define Phase Meeting?{' '}
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};
