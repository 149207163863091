import axios from 'axios';
import service from './service';

const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://api-dev.thehive.schooladvisor.co.za/api/v1';

export const createAbortController = () => {
    return new AbortController();
};

export const UpdateTaskFile = (data: any, signal: any) => {
    return service.post(`tenders/${data.ids.tender_id}/tasks/${data.ids.task_id}`, data.data, { signal });
};

export const AwardSupplier = (data: any, signal: any) => {
    return service.post(`tenders/${data.ids.tender_id}/suppliers/${data.ids.supplier_id}/shortlist`, data.data, {
        signal
    });
};

export const createDocument = (data: any, signal: any) => {
    return service.post(`/tenders/${data.ids.tenderId}/tasks`, data.data, { signal });
};

export const declineTask = (data: any, signal: any) => {
    return service.post(`/tenders/${data.ids.tenderId}/tasks/${data.ids.taskId}/decline`, data.data, { signal });
};

export const downloadFileFunc = (data: any) => {
    return axios({
        method: 'get',
        url: `${baseUrl}/tenders/${data.tenderId}/tasks/${data.taskId}/files/${data.fileId}/download`,
        responseType: 'blob',
        headers: {
            Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('authUser') || sessionStorage.getItem('authUser') || '{}')?.authToken
            }`,
            'Content-Type': 'application/json'
        }
    });
};

export const downloadProfileSummary = (tenderId: number) => {
    return axios({
        method: 'get',
        url: `${baseUrl}/tenders/${tenderId}/suppliers/generate-profile-summary`,
        responseType: 'blob',
        headers: {
            Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('authUser') || sessionStorage.getItem('authUser') || '{}')?.authToken
            }`,
            Accept: 'application/pdf'
        }
    });
};
export const downloadTPA = (signature: string) => {
    return axios({
        method: 'get',
        url: `${baseUrl}/supplier-companies/tender-registration/download-tpa${signature}`,
        responseType: 'blob',
        headers: {
            Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('authUser') || sessionStorage.getItem('authUser') || '{}')?.authToken
            }`,
            Accept: 'application/pdf'
        }
    });
};
export const downloadExecutiveSummary = (tenderId: number) => {
    return axios({
        method: 'get',
        url: `${baseUrl}/tenders/${tenderId}/suppliers/generate-executive-summary`,
        responseType: 'blob',
        headers: {
            Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('authUser') || sessionStorage.getItem('authUser') || '{}')?.authToken
            }`,
            Accept: 'application/pdf'
        }
    });
};

export const downloadSingleFileFunc = (uuid: string) => {
    return axios({
        method: 'get',
        url: `${baseUrl}/file-manager/download-single/${uuid}`,
        responseType: 'blob',
        headers: {
            Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('authUser') || sessionStorage.getItem('authUser') || '{}')?.authToken
            }`,
            'Content-Type': 'application/json'
        }
    });
};
