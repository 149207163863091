import { FC, useState } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import { taskProps, tenderDataProps } from '../../../pages/TenderViewPage/types';
import { downloadFileFunc } from '../../../api/axiosCruds';
import { useApproveTaskMutation, useDeleteFileMutation } from '../../../api/tenders';
import { Loader } from '../../Loader/Loader';
import pin from '../../../assets/pinIcon.svg';
import KickoffIcon from '../../../assets/KickoffIcon.svg';
import plus from '../../../assets/Plus, Add.svg';
import pendingIcon from '../../../assets/panding-circle.svg';
import Questionnaire from '../../../assets/Define Phase Questionnaire.svg';
import messageGrey from '../../../assets/messageGrey.svg';
import done from '../../../assets/Done, Check.svg';
import i from '../../../assets/i.svg';
import downloadIcon from '../../../assets/Documents, File, Download.svg';
import ReceivedFile from '../../../assets/ReceivedFile.svg';
import { KickoffQuestionnaire } from '../../Modals/KickoffQuestionnaire';
import Visit from '../../../assets/Visit.svg';
import { SpinnerIcon } from '../../Icons/SpinnerIcon';
import edit from '../../../assets/editAdminIcon.svg';

interface DefineQuestionnaireTaskProps {
    data: tenderDataProps;
    role: string | null;
    setTaskId: React.Dispatch<React.SetStateAction<string | number | null>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi: any;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    taskId: null | string | number;
}

export interface PrevData {
    link: string;
    comment: string;
}

export const DefineQuestionnaireTask: FC<DefineQuestionnaireTaskProps> = ({
    data,
    role,
    setTaskId,
    messageApi,
    setTrigger,
    taskId,
    setLoading
}) => {
    const [isKickOffModal, setIsKickOffModal] = useState<boolean>(false);
    const [isDeleteFileModal, setIsDeleteFileModal] = useState<boolean>(false);
    const [prevData, setPrevData] = useState<PrevData>({
        link: '',
        comment: ''
    });
    const [approveTask] = useApproveTaskMutation();
    const [isOpenedLink, setIsOpenedLink] = useState(false);

    const [deleteFile, { isLoading: isLoadingDeleteFile }] = useDeleteFileMutation();

    const deleteFileFunc = async () => {
        if (role === 'school_user') {
            try {
                const formData = {
                    tenderId: data.id,
                    taskId: taskId,
                    file: data?.phase_tasks?.find((elem: any) => elem.id === taskId)?.files.school_user[0]?.uuid || null
                };
                await deleteFile(formData).unwrap();
                await setIsDeleteFileModal(false);
                await setTrigger((prev: boolean) => !prev);
            } catch {}
        } else {
            try {
                const formData = {
                    tenderId: data.id,
                    taskId: taskId,
                    file:
                        data?.phase_tasks?.find((elem: any) => elem.id === taskId)?.files.tender_representative[0]
                            ?.uuid || null
                };
                await deleteFile(formData).unwrap();
                await setIsDeleteFileModal(false);
                await setTrigger((prev: boolean) => !prev);
            } catch {}
        }
    };

    const completeTaskFunc = async (taskId: number) => {
        try {
            const formData = {
                tenderId: data?.id,
                taskId: taskId
            };
            await approveTask(formData).unwrap();
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    const downloadFunc = (fileId: string, taskId: number) => {
        setLoading(true);
        downloadFileFunc({
            tenderId: data.id,
            taskId: taskId,
            fileId: fileId
        })
            .then((response: any) => {
                const stplitArray = response?.headers['content-disposition']?.split(';');
                const element = stplitArray?.find((elem: string) => elem?.includes('filename'));
                const name = element?.split('=')?.[1];
                downloadPDF(response, name);
            })
            .catch((err) => {
                messageApi.error('Downloading Error. Check your internet connection');
            })
            .finally(() => setLoading(false));
    };

    const downloadPDF = (response: any, filename: any) => {
        let blob = new Blob([response.data], {
            type: response.headers['content-type']
        });

        if (filename.includes('pdf')) {
            let headerLine = response.headers['content-disposition'];

            if (headerLine) {
                let startFileNameIndex = headerLine.indexOf('"') + 1;
                let endFileNameIndex = headerLine.lastIndexOf('"');
                filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
            }
        }

        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        let fileName = filename.replace(/"/g, '').trim();
        link.download = fileName;
        link.target = '_blank';

        const el = document.body;
        el.appendChild(link);

        link.click();
        setTrigger((prev: boolean) => !prev);
        messageApi.success('File has been downloaded successfully');
    };

    return (
        <>
            {data?.phase_tasks.length &&
                data?.phase_tasks.map((elem: taskProps) => {
                    if (elem.action === 'define_phase_questionnaire') {
                        if (role !== 'school_user') {
                            if (elem.status === 'pending_tr' && !elem?.approved) {
                                return (
                                    <div key={elem.id} className={`tasks__task task `}>
                                        <div className="task__title">
                                            <div className="task-complete__title-row">
                                                <img src={Questionnaire} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row"></div>
                                        </div>
                                        {role === 'tender_representative' && (
                                            <img
                                                src={plus}
                                                className="task__plus"
                                                onClick={() => {
                                                    setTaskId(elem.id);
                                                    setIsKickOffModal(true);
                                                }}
                                            />
                                        )}
                                    </div>
                                );
                            }
                            if (elem.status === 'need_resend_tr' && !elem?.approved) {
                                return (
                                    <>
                                        <div key={elem.id} className={`tasks__task-complete task-complete green`}>
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={KickoffIcon} />
                                                    {elem?.name}
                                                </div>
                                                <div className="task-complete__title-row"> </div>
                                            </div>
                                            <div className="task-complete__info-wrap">
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Link to Questionnaire</div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value">
                                                        <img src={pin} />
                                                        <div
                                                            className="task-complete__link"
                                                            style={{ width: '100%', padding: '0 150px 0 0' }}
                                                        >
                                                            <a href={elem?.link ? elem?.link : ''} target="_blanc">
                                                                {elem.link}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                                {elem.comments?.tender_representative && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__name">My Comment</div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value">
                                                                {elem.comments?.tender_representative}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                {role !== 'super_admin' && (
                                                    <img
                                                        src={edit}
                                                        className="task-complete__edit"
                                                        onClick={() => {
                                                            setTaskId(elem.id);
                                                            setIsKickOffModal(true);
                                                            setPrevData({
                                                                link: elem.link ? elem.link : '',
                                                                comment: elem.comments?.tender_representative
                                                            });
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            if (elem.status === 'pending_su') {
                                return (
                                    <>
                                        <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={KickoffIcon} />
                                                    {elem?.name}
                                                </div>
                                                <div className="task-complete__title-row">
                                                    <div className="task__pending" key={elem.id}>
                                                        <img src={pendingIcon} />
                                                        Pending
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="task-complete__info-wrap">
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Link to Questionnaire</div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value">
                                                        <img src={pin} />
                                                        <div
                                                            className="task-complete__link"
                                                            style={{ width: '100%', padding: '0 150px 0 0' }}
                                                        >
                                                            <a href={elem?.link ? elem?.link : ''} target="_blanc">
                                                                {elem.link}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                                {elem.comments?.tender_representative_sent && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__name">My Comment</div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value">
                                                                {elem.comments?.tender_representative_sent}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                {role !== 'super_admin' && (
                                                    <div
                                                        className={`tasks__resend task-complete__edit resend`}
                                                        onClick={() => {
                                                            setTaskId(elem.id);
                                                            setIsKickOffModal(true);
                                                            setPrevData({
                                                                link: elem.link ? elem.link : '',
                                                                comment: elem.comments?.tender_representative_sent
                                                            });
                                                        }}
                                                    >
                                                        <SpinnerIcon
                                                            fill={'#001965'}
                                                            style={{ margin: '0 10px 0 0' }}
                                                        />
                                                        Resend
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            if (elem.status === 'pending_tr' && elem?.approved) {
                                return (
                                    <>
                                        <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={KickoffIcon} />
                                                    {elem?.name}
                                                </div>
                                                <div className="task-complete__title-row">
                                                    {' '}
                                                    <span className="task__approved-active">
                                                        <img src={done} />
                                                        Completed by School User{' '}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="task-complete__info-wrap">
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Link to Questionnaire</div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value">
                                                        <img src={pin} />
                                                        <div
                                                            className="task-complete__link"
                                                            style={{ width: '100%', padding: '0 100px 0 0' }}
                                                        >
                                                            <a href={elem?.link ? elem?.link : ''} target="_blanc">
                                                                {elem.link}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                                {elem.comments?.tender_representative_sent && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__name">My Comment</div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value">
                                                                {elem.comments?.tender_representative_sent}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {role !== 'super_admin' && (
                                                    <Tooltip
                                                        title={
                                                            'To resend this task, please set a deadline for the school user first'
                                                        }
                                                        overlayClassName={` ${
                                                            !data?.tasks_deadline_at
                                                                ? 'custom-tooltip-disabled'
                                                                : 'custom-tooltip'
                                                        }`}
                                                    >
                                                        <div
                                                            className={`tasks__resend task-complete__edit resend ${
                                                                data?.tasks_deadline_at ? '' : 'disable'
                                                            }`}
                                                            onClick={() => {
                                                                if (!data?.tasks_deadline_at) return;
                                                                setTaskId(elem.id);
                                                                setIsKickOffModal(true);
                                                                setPrevData({
                                                                    link: elem.link ? elem.link : '',
                                                                    comment: elem.comments?.tender_representative_sent
                                                                });
                                                            }}
                                                        >
                                                            <SpinnerIcon
                                                                fill={!data?.tasks_deadline_at ? '#b3bad1' : '#001965'}
                                                                style={{ margin: '0 10px 0 0' }}
                                                            />
                                                            Resend
                                                        </div>
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                        }
                        if (role === 'school_user') {
                            if (elem.status === 'pending_su') {
                                return (
                                    <>
                                        <div
                                            key={elem.id}
                                            className={`tasks__task-complete task-complete ${
                                                elem.approved ? 'green' : ''
                                            }`}
                                        >
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={Questionnaire} />
                                                    {elem?.name}{' '}
                                                </div>
                                                <div className="task-complete__title-row">
                                                    {elem.approved ? (
                                                        <span className="task__approved-active">
                                                            <img src={done} />
                                                            Completed
                                                        </span>
                                                    ) : (
                                                        <Tooltip
                                                            title="To mark this task as complete, you need complete the questionnaire first."
                                                            overlayClassName={` ${
                                                                !isOpenedLink
                                                                    ? 'custom-tooltip-disabled'
                                                                    : 'custom-tooltip'
                                                            }`}
                                                        >
                                                            <div
                                                                className={`task-complete__approved ${
                                                                    !isOpenedLink ? 'disabled-blue-button' : ''
                                                                }`}
                                                                onClick={() => {
                                                                    if (isOpenedLink) {
                                                                        completeTaskFunc(elem.id);
                                                                    }
                                                                }}
                                                                style={{ margin: '0 0 0 16px' }}
                                                            >
                                                                Complete
                                                            </div>
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            </div>
                                            <div>
                                                <>
                                                    <div className="task-complete__info">
                                                        <div className="task-complete__wrap column">
                                                            <a
                                                                href={elem.link ? elem.link : ''}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                onClick={() => setIsOpenedLink(true)}
                                                            >
                                                                <div className="supplier-SU__white-btn">
                                                                    Open Questionnaire <img src={Visit} />
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>

                                                    {elem.comments.tender_representative_sent && (
                                                        <>
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__value school-user">
                                                                    <img src={messageGrey} />
                                                                    {elem.comments.tender_representative_sent}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            </div>
                                        </div>
                                    </>
                                );
                            }

                            // if (
                            //     (elem.status === 'pending_su' && !elem?.approved && elem.files.school_user[0]?.uuid) ||
                            //     elem.status === 'resend_su'
                            // ) {
                            //     return (
                            //         <>
                            //             <div key={elem.id} className={`tasks__task-complete task-complete green`}>
                            //                 <div className="task-complete__title">
                            //                     <div className="task-complete__title-row">
                            //                         <img src={Questionnaire} />
                            //                         {elem?.name}{' '}
                            //                         <Tooltip
                            //                             title={
                            //                                 <div style={{ fontWeight: '400' }}>
                            //                                     This document clarifies our understanding of your
                            //                                     requirements and the criteria you wish to use for
                            //                                     evaluating suppliers. It will be the basis for any
                            //                                     tender materials we create and will guide the evaluation
                            //                                     process.
                            //                                     <br />
                            //                                     <span style={{ fontWeight: '600' }}>Instruction</span>:
                            //                                     Please review the Define Phase Questionnaire. If you’re
                            //                                     happy with it approve it. If you have any adjustments,
                            //                                     upload the revised, marked-up document and send it back
                            //                                     to us.
                            //                                 </div>
                            //                             }
                            //                             overlayClassName={'custom-tooltip-disabled'}
                            //                         >
                            //                             <img src={i} className="i" />
                            //                         </Tooltip>
                            //                     </div>
                            //                     <div className="task-complete__title-row"></div>
                            //                 </div>
                            //                 <div>
                            //                     <>
                            //                         <div className="task-complete__info">
                            //                             <div className="task-complete__wrap column">
                            //                                 <div className="task-complete__name">Received File</div>
                            //                                 <div className="task-complete__value file">
                            //                                     <img
                            //                                         src={ReceivedFile}
                            //                                         className="upload-download-icon"
                            //                                     />
                            //                                     {elem.files.tender_representative[0]?.name}
                            //                                 </div>
                            //                             </div>
                            //                             <div className="task-complete__wrap">
                            //                                 <div
                            //                                     className={`task-complete__download-btn `}
                            //                                     onClick={() => {
                            //                                         downloadFunc(
                            //                                             elem.files.tender_representative[0]?.uuid,
                            //                                             elem.id
                            //                                         );
                            //                                     }}
                            //                                 >
                            //                                     <img
                            //                                         src={downloadIcon}
                            //                                         className="upload-download-icon"
                            //                                     />
                            //                                     Download
                            //                                 </div>
                            //                             </div>
                            //                         </div>

                            //                         {elem.comments.files[elem.files.tender_representative[0]?.uuid] && (
                            //                             <>
                            //                                 <div className="task-complete__wrap">
                            //                                     <div className="task-complete__value school-user">
                            //                                         <img src={messageGrey} />
                            //                                         {
                            //                                             elem.comments.files[
                            //                                                 elem.files.tender_representative[0]?.uuid
                            //                                             ]
                            //                                         }
                            //                                     </div>
                            //                                 </div>
                            //                             </>
                            //                         )}
                            //                     </>
                            //                     <div className="task-complete__info-wrap">
                            //                         <div className="task-complete__wrap">
                            //                             <div className="task-complete__name">Uploaded File</div>
                            //                         </div>
                            //                         <div className="task-complete__wrap">
                            //                             <div className="task-complete__value file">
                            //                                 <img src={ReceivedFile} className="upload-download-icon" />
                            //                                 {elem.files.school_user[0]?.name}
                            //                             </div>
                            //                         </div>

                            //                         {elem.comments.files[elem.files.school_user[0]?.uuid] && (
                            //                             <>
                            //                                 <div className="task-complete__wrap">
                            //                                     <div className="task-complete__name">My Comment</div>
                            //                                 </div>
                            //                                 <div className="task-complete__wrap">
                            //                                     <div className="task-complete__value">
                            //                                         {
                            //                                             elem.comments.files[
                            //                                                 elem.files.school_user[0]?.uuid
                            //                                             ]
                            //                                         }
                            //                                     </div>
                            //                                 </div>
                            //                             </>
                            //                         )}
                            //                         <img
                            //                             src={deleteIcon}
                            //                             className="task-complete__edit"
                            //                             onClick={() => {
                            //                                 setTaskId(elem.id);
                            //                                 setIsDeleteFileModal(true);
                            //                             }}
                            //                         />
                            //                     </div>
                            //                 </div>
                            //             </div>
                            //         </>
                            //     );
                            // }
                            if (elem.status === 'pending_tr') {
                                return (
                                    <>
                                        <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={Questionnaire} />
                                                    {elem?.name}{' '}
                                                </div>
                                                <div className="task-complete__title-row">
                                                    {elem.approved ? (
                                                        <span className="task__approved-active">
                                                            <img src={done} />
                                                            Completed
                                                        </span>
                                                    ) : (
                                                        <Tooltip
                                                            title="To mark this task as complete, you need complete the questionnaire first."
                                                            overlayClassName={` ${
                                                                !isOpenedLink
                                                                    ? 'custom-tooltip-disabled'
                                                                    : 'custom-tooltip'
                                                            }`}
                                                        >
                                                            <div
                                                                className={`task-complete__approved ${
                                                                    !isOpenedLink ? 'disabled-blue-button' : ''
                                                                }`}
                                                                onClick={() => {
                                                                    if (isOpenedLink) {
                                                                        completeTaskFunc(elem.id);
                                                                    }
                                                                }}
                                                                style={{ margin: '0 0 0 16px' }}
                                                            >
                                                                Complete
                                                            </div>
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            </div>
                                            <div>
                                                <>
                                                    <div className="task-complete__info">
                                                        <div className="task-complete__wrap column">
                                                            <a
                                                                href={elem.link ? elem.link : ''}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                onClick={() => setIsOpenedLink(true)}
                                                            >
                                                                <div className="supplier-SU__white-btn">
                                                                    Open Questionnaire <img src={Visit} />
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>

                                                    {elem.comments.tender_representative_sent && (
                                                        <>
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__value school-user">
                                                                    <img src={messageGrey} />
                                                                    {elem.comments.tender_representative_sent}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            if (elem.status === 'completed' && elem?.approved) {
                                return (
                                    <>
                                        <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={Questionnaire} />
                                                    {elem?.name}{' '}
                                                </div>
                                                <div className="task-complete__title-row">
                                                    {elem.approved ? (
                                                        <span className="task__approved-active">
                                                            <img src={done} />
                                                            Completed
                                                        </span>
                                                    ) : (
                                                        <Tooltip
                                                            title="To mark this task as complete, you need complete the questionnaire first."
                                                            overlayClassName={` ${
                                                                !isOpenedLink
                                                                    ? 'custom-tooltip-disabled'
                                                                    : 'custom-tooltip'
                                                            }`}
                                                        >
                                                            <div
                                                                className={`task-complete__approved ${
                                                                    !isOpenedLink ? 'disabled-blue-button' : ''
                                                                }`}
                                                                onClick={() => {
                                                                    if (isOpenedLink) {
                                                                        completeTaskFunc(elem.id);
                                                                    }
                                                                }}
                                                                style={{ margin: '0 0 0 16px' }}
                                                            >
                                                                Complete
                                                            </div>
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            </div>
                                            <div>
                                                <>
                                                    <div className="task-complete__info">
                                                        <div className="task-complete__wrap column">
                                                            <a
                                                                href={elem.link ? elem.link : ''}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                onClick={() => setIsOpenedLink(true)}
                                                            >
                                                                <div className="supplier-SU__white-btn">
                                                                    Open Questionnaire <img src={Visit} />
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>

                                                    {elem.comments.tender_representative_sent && (
                                                        <>
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__value school-user">
                                                                    <img src={messageGrey} />
                                                                    {elem.comments.tender_representative_sent}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            if (elem.status === 'need_approve_tr') {
                                return (
                                    <>
                                        <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={Questionnaire} />
                                                    {elem?.name}{' '}
                                                    <Tooltip
                                                        title={
                                                            <div style={{ fontWeight: '400' }}>
                                                                This document clarifies our understanding of your
                                                                requirements and the criteria you wish to use for
                                                                evaluating suppliers. It will be the basis for any
                                                                tender materials we create and will guide the evaluation
                                                                process.
                                                                <br />
                                                                <span style={{ fontWeight: '600' }}>Instruction</span>:
                                                                Please review the Define Phase Questionnaire. If you’re
                                                                happy with it approve it. If you have any adjustments,
                                                                upload the revised, marked-up document and send it back
                                                                to us.
                                                            </div>
                                                        }
                                                        overlayClassName={'custom-tooltip-disabled'}
                                                    >
                                                        <img src={i} className="i" />
                                                    </Tooltip>
                                                </div>
                                                <div className="task-complete__title-row">
                                                    {' '}
                                                    <div className="task__pending" key={elem.id}>
                                                        <img src={pendingIcon} />
                                                        Pending
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <>
                                                    <div className="task-complete__info">
                                                        <div className="task-complete__wrap column">
                                                            <div className="task-complete__name">Received File</div>
                                                            <div className="task-complete__value file">
                                                                <img
                                                                    src={ReceivedFile}
                                                                    className="upload-download-icon"
                                                                />
                                                                {elem.files.tender_representative[0]?.name}
                                                            </div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div
                                                                className={`task-complete__download-btn `}
                                                                onClick={() => {
                                                                    downloadFunc(
                                                                        elem.files.tender_representative[0]?.uuid,
                                                                        elem.id
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src={downloadIcon}
                                                                    className="upload-download-icon"
                                                                />
                                                                Download
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {elem.comments.files[elem.files.tender_representative[0]?.uuid] && (
                                                        <>
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__value school-user">
                                                                    <img src={messageGrey} />
                                                                    {
                                                                        elem.comments.files[
                                                                            elem.files.tender_representative[0]?.uuid
                                                                        ]
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                                {elem.comments.files[elem.files.school_user[0]?.uuid] && (
                                                    <div className="task-complete__info-wrap">
                                                        <div className="task-complete__info">
                                                            <div className="task-complete__wrap column">
                                                                <div className="task-complete__name">Uploaded File</div>
                                                                <div className="task-complete__value file">
                                                                    {elem.files.school_user[0]?.name}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <>
                                                            <div className="task-complete__wrap column">
                                                                <div className="task-complete__name">My Comment</div>
                                                                <div className="task-complete__value">
                                                                    {
                                                                        elem.comments.files[
                                                                            elem.files.school_user[0]?.uuid
                                                                        ]
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                        }
                    }
                })}

            {isKickOffModal && (
                <KickoffQuestionnaire
                    openModal={isKickOffModal}
                    setOpenModal={setIsKickOffModal}
                    messageApi={messageApi}
                    data={data}
                    taskId={taskId}
                    setTrigger={setTrigger}
                    prevData={prevData}
                />
            )}
            {isDeleteFileModal && (
                <Modal
                    className="confirmModal"
                    centered
                    open={isDeleteFileModal}
                    width={660}
                    closeIcon={<></>}
                    footer={
                        <div
                            style={{
                                display: 'flex',
                                justifyItems: 'space-between',
                                gap: '16px',
                                width: '100%',
                                margin: '25px 0 0 0'
                            }}
                        >
                            <Button key="back" onClick={() => setIsDeleteFileModal(false)} block className="cancel">
                                Cancel
                            </Button>
                            <button className="quit" style={{ width: '100%' }} onClick={deleteFileFunc}>
                                {isLoadingDeleteFile ? <Loader /> : 'Delete'}
                            </button>
                        </div>
                    }
                >
                    <div className="modal-confirm">
                        <div className="modal-confirm__subtitle" style={{ color: '#4D5E93' }}>
                            Are you sure you want to delete the file?
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};
