import { useState, FC, useEffect, SetStateAction } from 'react';
import { useSendTaskMutation, useUpdateTaskDeadlineMutation } from '../../api/tenders';
import { tenderDataProps } from '../../pages/TenderViewPage/types';
import { Button, Tooltip, message, Modal } from 'antd';
import { useAppSelector } from '../../hooks';
import LetterIcon from '../../assets/LetterIcon.svg';
import Notif from '../../assets/Notif.svg';
import arrow from '../../assets/arrow-right-white.svg';
import calendar from '../../assets/calendar-chedule.svg';

import attention from '../../assets/attention.svg';
import edit from '../../assets/editAdminIcon.svg';
import moment from 'moment';
import { TaskDeadlineModal } from '../Modals/TaskDeadline';
import './phases.scss';
import { SupplierReportCardPublishDate } from './EvaluatePhase4Components/SupplierReportCardPublishDate';
import { SupplierList } from './EvaluatePhaseComponent/SupplierList';
import { SupplierReportCard } from './EvaluatePhaseComponent/SupplierReportCard';
import { LetterOfIntent } from './EvaluatePhase4Components/LetterOfIntent';
import { MultiAwardModal } from '../Modals/MultiAwardModal';
import { SpinnerIcon } from '../Icons/SpinnerIcon';
import { MultiAward } from './EvaluatePhase4Components/MultiAward';
import { AllTasksCompleted } from './AllTasksCompleted';
import { SupplierListNew } from './DesignPhaseComponents/SupplierListNew';
import { Loader } from '../Loader/Loader';

interface PropsPhase {
    data: tenderDataProps;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    setConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EvaluatePhase4: FC<PropsPhase> = ({ data, setTrigger, setConfirmModal }) => {
    const { role } = useAppSelector((state) => state.profileReducer);
    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);
    function handleResize() {
        setScreenWidth(window.screen.width);
    }
    window.addEventListener('resize', handleResize);
    const [deadlineModal, setDeadlineModal] = useState(false);
    const [SelectedDate, setSelectedDate] = useState<Date | string>(new Date());
    const [isMultiAward, setIsMultiAward] = useState(false);
    const [infoModal, setInfoModal] = useState(false);

    const [updateDeadline, { isLoading }] = useUpdateTaskDeadlineMutation();

    const updateDeadlineFunc = async () => {
        try {
            const formData = {
                id: data.id,
                data: { deadline_at: moment(SelectedDate).format('YYYY-MM-DD') }
            };
            await updateDeadline(formData).unwrap();
            await setDeadlineModal(false);
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    const [
        sendTask,
        { data: dataSendTask, isError: isErrorSendTask, isSuccess: isSuccessSendTask, isLoading: isLoadingSendTask }
    ] = useSendTaskMutation();

    const [messageApi, contextHolder] = message.useMessage();

    const sendTaskFunc = async () => {
        const formData = {
            id: data?.id
        };
        try {
            await sendTask(formData).unwrap();
        } catch {}
    };

    useEffect(() => {
        if (isErrorSendTask) {
            messageApi.error('Something went wrong, please try again');
        }
        if (isSuccessSendTask) {
            messageApi.success(dataSendTask.message);
            if (role === 'school_user') {
                setInfoModal(true);
            } else {
                setTrigger((prev) => !prev);
            }
        }
    }, [isErrorSendTask, isSuccessSendTask]);

    return (
        <>
            {contextHolder}
            <div className={`tendermain__block tasks`}>
                {role !== 'school_user' &&
                    (data?.phase_tasks?.[0]?.status === 'pending_tr' ||
                        data?.phase_tasks?.[0]?.status === 'need_resend_tr') && (
                        <div className="tasks__header define">
                            <div className="count-wrapper">
                                Task Centre
                                <div className="count">{data?.phase_task_count}</div>
                            </div>
                            <div className="tasks__deadline-wrapper">
                                <img src={attention} />
                                {screenWidth > 500 ? 'Task' : ''} Deadline -{' '}
                                {moment(data?.phase_deadline_at).format('YYYY-MM-DD')}
                            </div>
                        </div>
                    )}
                {role === 'school_user' && (
                    <div className="tasks__header design">
                        <div className="count-wrapper">
                            Task Centre
                            <div className="count">{data?.phase_task_count}</div>
                        </div>
                        {data?.phase_tasks?.[0]?.action === 'evaluate_4_report_card' &&
                            data?.phase_tasks?.[0]?.status === 'pending_su' && (
                                <div className="tasks__deadline-wrapper">
                                    <img src={attention} />
                                    {screenWidth > 500 ? 'Task' : ''} Deadline -{' '}
                                    {moment(data?.tasks_deadline_at).format('YYYY-MM-DD')}
                                </div>
                            )}
                    </div>
                )}

                <div className="tasks__list">
                    {role !== 'school_user' && data.phase_tasks[0].action !== 'evaluate_4_publish_date' && (
                        <div className="tasks__info-wrap info-wrap" style={{ margin: '0 0 16px 0' }}>
                            <div className="info-wrap__deadline">Task Deadline for School User</div>
                            <div className="info-wrap__status">
                                {data?.tasks_deadline_at
                                    ? moment(data?.tasks_deadline_at).format('YYYY-MM-DD')
                                    : 'Not indicated'}
                            </div>
                            <div className="info-wrap__edit" onClick={() => setDeadlineModal(true)}>
                                <img src={edit} />
                                Edit
                            </div>
                        </div>
                    )}
                    {data.phase_tasks.length ? (
                        <>
                            {/* {role && role === 'school_user' && (data?.can_send_task || data?.can_update_phase) && (
                                <AllTasksCompleted />
                            )} */}
                            <SupplierReportCardPublishDate data={data} setTrigger={setTrigger} />
                            <SupplierReportCard
                                data={data}
                                role={role}
                                setTrigger={setTrigger}
                                messageApi={messageApi}
                            />
                            <LetterOfIntent data={data} role={role} setTrigger={setTrigger} messageApi={messageApi} />
                            <SupplierListNew data={data} role={role} messageApi={messageApi} setTrigger={setTrigger} />
                            <MultiAward data={data} />
                        </>
                    ) : (
                        <>
                            {role === 'school_user' && !data.tasks_deadline_at && (
                                <div className="noTasks">
                                    <img src={LetterIcon} />
                                    <div className="noTasks__title">Welcome to Evaluation Gate 4!</div>
                                    <div className="noTasks__subtitle">
                                        Watch this space - new tasks for you will be posted here as they become
                                        available.
                                    </div>
                                </div>
                            )}
                            {role === 'school_user' && data.tasks_deadline_at && (
                                <div className="noTasks">
                                    <img src={Notif} />
                                    <div className="noTasks__title">Negotiations are currently in progress</div>
                                    <div className="noTasks__subtitle" style={{ width: '395px' }}>
                                        The results of these discussions will be published in the Supplier Report Card
                                        on
                                    </div>
                                    <div className="noTasks__date">
                                        <img src={calendar} className="noTasks__image" />{' '}
                                        {moment(data.tasks_deadline_at).format('DD MMMM, YYYY')}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>

                {role === 'tender_representative' &&
                    data.phase_tasks[0].action === 'evaluate_4_publish_date' &&
                    data?.phase_tasks?.[0]?.status === 'pending_tr' &&
                    !data.can_update_phase && (
                        <div className="tasks__btn-wrap">
                            <div style={{ width: '47%' }}></div>

                            <Tooltip
                                title={
                                    !data?.can_send_task
                                        ? 'To send this task, you need to complete all the Task Activities'
                                        : ''
                                }
                                overlayClassName={` ${
                                    !data?.can_send_task ? 'custom-tooltip-disabled' : 'custom-tooltip'
                                }`}
                            >
                                <Button
                                    className={`tasks__next-btn ${data?.can_send_task ? '' : 'disabled'}`}
                                    type="primary"
                                    onClick={() => {
                                        if (!data?.can_send_task) {
                                            return;
                                        } else {
                                            if (!isLoadingSendTask) {
                                                sendTaskFunc();
                                            }
                                        }
                                    }}
                                >
                                    Notify School <img src={arrow} />
                                </Button>
                            </Tooltip>
                        </div>
                    )}

                {role === 'tender_representative' &&
                    data.phase_tasks[0].action === 'evaluate_4_publish_date' &&
                    data.phase_tasks[0].date &&
                    data?.phase_tasks[0].status === 'need_resend_tr' &&
                    !data.can_update_phase && (
                        <div className="tasks__btn-wrap">
                            <div style={{ width: '47%' }}></div>

                            <Tooltip
                                title={
                                    !data?.can_send_task
                                        ? 'To send this task, you need to complete all the Task Activities'
                                        : ''
                                }
                                overlayClassName={` ${
                                    !data?.can_send_task ? 'custom-tooltip-disabled' : 'custom-tooltip'
                                }`}
                            >
                                <Button
                                    className={`tasks__next-btn ${data?.can_send_task ? '' : 'disabled'}`}
                                    type="primary"
                                    onClick={() => {
                                        if (!data?.can_send_task) {
                                            return;
                                        } else {
                                            if (!isLoadingSendTask) {
                                                sendTaskFunc();
                                            }
                                        }
                                    }}
                                >
                                    Next task <img src={arrow} />
                                </Button>
                            </Tooltip>
                        </div>
                    )}
                {role === 'tender_representative' &&
                    data?.phase_tasks?.[0]?.status !== 'pending_su' &&
                    data.phase_tasks[1]?.status !== 'need_resend_tr' &&
                    data.phase_tasks[0]?.status !== 'need_resend_tr' &&
                    data.phase_tasks[0].action !== 'evaluate_4_publish_date' &&
                    !data.can_update_phase && (
                        <div className="tasks__btn-wrap">
                            <div style={{ width: '47%' }}></div>

                            <Tooltip
                                title={
                                    !data?.can_send_task && data.phase_tasks[1]?.status !== 'pending_su'
                                        ? !data?.tasks_deadline_at
                                            ? 'To send this task, please set a deadline for the school user first'
                                            : !data?.suppliers?.every((elem) => elem.grade?.value)
                                            ? 'To send this task, you need to assign a score to each supplier first'
                                            : 'To send this task, you need to complete all the Task Activities'
                                        : ''
                                }
                                overlayClassName={` ${
                                    !data?.can_send_task && data.phase_tasks?.[0]?.status !== 'pending_su'
                                        ? 'custom-tooltip-disabled'
                                        : 'custom-tooltip'
                                }`}
                            >
                                <Button
                                    className={`tasks__next-btn ${data?.can_send_task ? '' : 'disabled'}`}
                                    type="primary"
                                    onClick={() => {
                                        if (!data?.can_send_task) {
                                            return;
                                        } else {
                                            if (!isLoadingSendTask) {
                                                sendTaskFunc();
                                            }
                                        }
                                    }}
                                >
                                    Send Task <img src={arrow} />
                                </Button>
                            </Tooltip>
                        </div>
                    )}

                {role === 'school_user' &&
                    data.phase_tasks.length > 0 &&
                    data?.phase_tasks[0].status !== 'need_resend_tr' && (
                        <div className="tasks__btn-wrap">
                            <div></div>

                            <Tooltip
                                title="To send this task, you need to complete all the Task Activities"
                                overlayClassName={` ${
                                    !data?.can_send_task && data.phase_tasks?.[0]?.status !== 'need_resend_tr'
                                        ? 'custom-tooltip-disabled'
                                        : 'custom-tooltip'
                                }`}
                            >
                                <Button
                                    className={`tasks__next-btn ${data?.can_send_task ? '' : 'disabled'}`}
                                    type="primary"
                                    onClick={() => {
                                        if (!data?.can_send_task) {
                                            return;
                                        } else {
                                            if (data?.is_show_multi_award) {
                                                setIsMultiAward(true);
                                            } else {
                                                if (!isLoadingSendTask) {
                                                    sendTaskFunc();
                                                }
                                            }
                                        }
                                    }}
                                >
                                    Submit
                                    <img src={arrow} />
                                </Button>
                            </Tooltip>
                        </div>
                    )}
                {role === 'tender_representative' &&
                    data?.phase_tasks?.[0]?.status === 'need_resend_tr' &&
                    data?.phase_tasks[0].action !== 'evaluate_4_publish_date' && (
                        <div className="tasks__btn-wrap">
                            <div style={{ width: '47%' }}></div>
                            <Tooltip
                                title="To send this task, please set a deadline for the school user first"
                                overlayClassName={` ${
                                    !data?.can_send_task ? 'custom-tooltip-disabled' : 'custom-tooltip'
                                }`}
                            >
                                <div
                                    className={`tasks__resend ${data.can_send_task ? '' : 'disable'}`}
                                    onClick={() => {
                                        if (data.can_send_task) {
                                            sendTaskFunc();
                                        }
                                    }}
                                >
                                    <SpinnerIcon
                                        fill={data.can_send_task ? '#001965' : '#b3bad1 '}
                                        style={{ margin: '0 10px 0 0' }}
                                    />
                                    Resend All Suppliers
                                </div>
                            </Tooltip>
                            <Button
                                className={`tasks__next-btn ${data.can_update_phase ? '' : 'disabled'}`}
                                type="primary"
                                onClick={() => {
                                    if (data.can_update_phase) {
                                        setConfirmModal(true);
                                    }
                                }}
                            >
                                Next Phase <img src={arrow} />
                            </Button>
                        </div>
                    )}
            </div>
            {deadlineModal && (
                <TaskDeadlineModal
                    openModal={deadlineModal}
                    setOpenModal={setDeadlineModal}
                    SelectedDate={SelectedDate}
                    setSelectedDate={setSelectedDate}
                    updateDeadlineFunc={updateDeadlineFunc}
                    isLoading={isLoading}
                />
            )}
            {isMultiAward && (
                <MultiAwardModal
                    data={data}
                    openModal={isMultiAward}
                    setOpenModal={setIsMultiAward}
                    setTrigger={setTrigger}
                />
            )}
            <Modal
                className="confirmModal"
                centered
                open={infoModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            className={`tasks__next-btn`}
                            style={{ width: '100%' }}
                            type="primary"
                            onClick={() => {
                                setInfoModal(false);
                                setTrigger((prev) => !prev);
                            }}
                        >
                            Got it{' '}
                        </Button>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header meeting" style={{ height: '88px' }}>
                        <div className="modal__title name-title">Important Information</div>
                    </div>

                    <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                        <div className="main__title" style={{ margin: '0 0 6px 0' }}>
                            Unsuccessful suppliers will only be notified once the agreement with your appointed supplier
                            has been finalised.
                            <br />
                            <br />
                            If you there is a change in supplier and your current supplier has not been successful, we
                            recommend booking a meeting with them as a courtesy to inform them of the outcome and
                            support a smooth transition before formal notifications are sent.
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
