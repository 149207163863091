import { RouteObject } from 'react-router-dom';
import { Login } from '../pages/Auth/Login';
import { MainLayout } from '../components/Layout';
import { PrivateRoutes } from './PrivateRoutes';
import { ForgotPassword } from '../pages/Auth/ForgotPassword';
import { ResetPassword } from '../pages/Auth/ResetPassword';
import { LimitExceeded } from '../pages/Auth/LimitExceeded';
import { ExpiredLink } from '../pages/ExpiredLink';
import { NotFound } from '../pages/NotFound';
import { AdminPanel } from '../pages/AdminPanel';
import { Dashboard } from '../pages/Dashboard';
import { PastTenders } from '../pages/PastTenders';
import { SignUp } from '../pages/SignUp';
import { ManageProfile } from '../pages/ManageProfile';
import { EmailVarification } from '../pages/EmailVarification';
import { TenderViewPage } from '../pages/TenderViewPage/TenderViewPage';
import { SupplierVarification } from '../pages/SupplierVarification/SupplierVarification';
import { SuppSelfRegistration } from '../pages/SuppSelfRegistration';
import { EmailConfirmation } from '../pages/EmailConfirmation';
import { SupplierFeedback } from '../pages/SupplierFeedback/SupplierFeedback';

const authRoutes: RouteObject[] = [
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/register/supplier',
        element: <SignUp />
    },
    {
        path: '/supplier-company/tender-registration',
        element: <SuppSelfRegistration />
    },
    {
        path: '/supplier-company/email-confirmed',
        element: <EmailConfirmation />
    },
    {
        path: '/supplier-company/verification',
        element: <SupplierVarification />
    },
    {
        path: '/supplier-company/sign-tpa',
        element: <SupplierVarification />
    },
    {
        path: '/supplier-company/unverification',
        element: <SupplierVarification />
    },
    {
        path: '/supplier-company/unverified',
        element: <SupplierVarification />
    },
    {
        path: '/forgot-password',
        element: <ForgotPassword />
    },
    { path: '/forgot-password/limit-exceeded', element: <LimitExceeded /> },
    {
        path: '/reset-password',
        element: <ResetPassword />
    },
    {
        path: '/sign-up*',
        element: <SignUp />
    },
    {
        path: '/email-verification*',
        element: <EmailVarification />
    },
    {
        path: '/supplier-company/feedback',
        element: <SupplierFeedback />
    }
];

const privateRoutes: RouteObject[] = [
    {
        path: 'dashboard',
        element: <Dashboard />
    },
    {
        path: 'admin-panel',
        element: <AdminPanel />
    },
    {
        path: 'past-tenders',
        element: <PastTenders />
    },
    {
        path: 'profile',
        element: <ManageProfile />
    },
    {
        path: '/tender/:id',
        element: <TenderViewPage />
    }
];

const commonRoutes: RouteObject[] = [
    {
        path: '/expired',
        element: <ExpiredLink />
    },

    {
        path: '*',
        element: <NotFound />
    },
    {
        path: '/not-found',
        element: <NotFound />
    }
];

export const routes = [
    {
        element: <PrivateRoutes />,
        children: [
            {
                path: '/',
                element: <MainLayout />,
                children: privateRoutes
            }
        ]
    },
    ...authRoutes,
    ...commonRoutes
];
