import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { Button, Modal, Form, Spin, message, Checkbox, Divider, Tooltip } from 'antd';
import { yupSync } from '../../utils';
import { FloatInput } from '../Fields/FloatInput';
import { FloatSelect } from '../Fields/FloatSelect';
import {
    useCreateSupplierFormMutation,
    useCreateUserMutation,
    useEditUserMutation,
    useLazyGetSupplierDocumentsQuery,
    useLazyGetSupplierQuery,
    useLazyGetSupplierStatusesQuery,
    useLazyGetUserQuery,
    useRejectDocumentsSupplierMutation,
    useResendDocumentsMutation,
    useResendInviteMutation,
    useUpdateStatusSupplierMutation,
    useUpdateSupplierMutation
} from '../../api/users';
import { PhoneNumberInput } from '../Fields/PhoneNumberInput';
import { Loader } from '../Loader/Loader';
import { usePreventSpaceTyping } from '../../hooks/usePreventSpaceTyping';
import { CustomErrorType } from '../../api/types/global';

import close from '../../assets/RoundedClose.svg';
import penEdit from '../../assets/pen-edit.svg';
import RoundedArrowLeft from '../../assets/RoundedArrowLeft.svg';

import * as Yup from 'yup';
import './Modals.scss';
import { useGetSchoolTypesQuery } from '../../api/common';
import { CustomMultiSelect } from '../Fields/CustomMultiSelect';
import { ArrowLeft } from '../Icons/ArrowLeft';
import { useLocation } from 'react-router-dom';
import email from '../../assets/mail.svg';
import Phone from '../../assets/Phone.svg';
import sendDocIcon from '../../assets/SendDocIcon.svg';
import fileIcon from '../../assets/documentSupp.svg';
import arrow from '../../assets/arrow.svg';
import { useClickOutside } from '../../hooks/useClickOutSide';
import { BlacklistSupplier } from './BlacklistSupplier';
import noDoc from '../../assets/no-documents.svg';
import { UploadFileSupplier } from './UploadFileSupplier';
import { UploadFile } from 'antd/lib/upload/interface';
import Requested from '../../assets/Requested.svg';
import { RequestDocumentModal } from './RequestDocumentModal';
import UploadNewTpa from '../../assets/UploadNewTpa.svg';
import { RejectDocSuppModal } from './RejectDocSuppModal';
import moment from 'moment';
import { downloadSingleFileFunc } from '../../api/axiosCruds';

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<{ type: string; open: boolean; id: number | null }>>;
    type: string;
    userId: number | null;
    refetch: () => void;
    statusImage: (status: string) => string | undefined;
}

const validationSchema = Yup.object().shape({
    name: Yup.string().required('This is a required field'),
    contact_name: Yup.string().required('This is a required field'),
    job: Yup.string().required('This is a required field'),
    trading_name: Yup.string(),
    website: Yup.string()
        .required('This is a required field')
        .matches(/^(https?:\/\/)/, 'Oops! Your link is not correct'),
    tpa_link: Yup.string().matches(/^(https?:\/\/)/, 'Oops! Your link is not correct'),
    email: Yup.string().email('Enter your email: e.g. email@domain.com').required('This is a required field'),
    secondary_email: Yup.string().email('Enter your email: e.g. email@domain.com'),
    b_bbee: Yup.object().required('This is a required field'),
    annual_revenue: Yup.object().required('This is a required field'),
    employees_number: Yup.object().required('This is a required field'),
    phone_number: Yup.string()
        .required('This is a required field')
        .matches(/^[^_]*$/, 'This is a required field'),
    registration_num: Yup.string()
        .required('This is a required field')
        .matches(/^[^_]*$/, 'This is a required field')
});

export const ModalSupplier: FC<ModalProps> = ({ openModal, setOpenModal, type, userId, refetch, statusImage }) => {
    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);
    function handleResize() {
        setScreenWidth(window.screen.width);
    }
    const ref: any = useRef();
    useClickOutside(ref, () => setIsOpenStatus(false));
    window.addEventListener('resize', handleResize);
    const [messageApi, contextHolder] = message.useMessage();
    const [isOpenStatus, setIsOpenStatus] = useState(false);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [school, setSchool] = useState<any>(null);
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [changed, setChanged] = useState<boolean>(false);
    const { data: dataSchoolTypes } = useGetSchoolTypesQuery({});
    const [getUserTrigger, { data: dataUser, isSuccess: getUserSuccess }] = useLazyGetSupplierQuery();
    const [getStatuses, { data: statuses }] = useLazyGetSupplierStatusesQuery();
    const [getDocuments, { data: documents }] = useLazyGetSupplierDocumentsQuery();
    const [isBlacklisted, setIsBlacklisted] = useState(false);
    const [resendDocuments, { isLoading: isLoadingResend, isSuccess: isSuccessResend }] = useResendDocumentsMutation();
    const [rejectDocuments, { isLoading: isLoadingReject, isSuccess: isSuccessReject }] =
        useRejectDocumentsSupplierMutation();
    const [isOpenRejectDocument, setIsOpenRejectDocument] = useState(false);

    useEffect(() => {
        if (isSuccessResend) {
            message.success('Request has been sent');
        }
    }, [isSuccessResend]);
    useEffect(() => {
        if (isSuccessReject) {
            message.success('Request has been sent');
        }
    }, [isSuccessReject]);

    const [invite, { isSuccess: inviteSuccess }] = useResendInviteMutation();
    const { handleKeyPress } = usePreventSpaceTyping();

    const [step, setStep] = useState('general');

    const [createSupplier, { isSuccess, isError: isErrorCreateSupplier, isLoading: isLoadingCreateSupplier }] =
        useCreateSupplierFormMutation();
    const [
        updateSupplier,
        { isSuccess: isSuccessUpdateSupplier, isError: isErrorUpdateSupplier, isLoading: isLoadingUpdateSupplier }
    ] = useUpdateSupplierMutation();
    const [updateStatus, { data: successStatusData, isSuccess: StatusUpdatedSuccessfully }] =
        useUpdateStatusSupplierMutation();

    const [selectedArea, setSelectedArea] = useState<any[]>([]);
    const [filtredAreas, setFiltredAreas] = useState<any[]>([]);

    const [selectedProvinnces, setSelectedProvinnces] = useState<any[]>([]);
    const [filtredProvinnces, setFiltredProvinnces] = useState<any[]>([]);

    const [supplierData, setSupplierData] = useState<any>();

    const [checkbox, setCheckbox] = useState(false);
    const [openUploadTPA, setOpenUploadTPA] = useState(false);
    const [requestDoc, setRequestDoc] = useState(false);
    const [requestNewTpa, setRequestNewTpa] = useState(false);
    const [filesTpa, setFilesTpa] = useState<UploadFile[]>([]);

    const [selectedEmployees, setSelectedEmployees] = useState<any>();
    const [selectedRevenue, setSelectedRevenue] = useState<any>();
    const [selectedBbee, setSelectedBbee] = useState<any>();
    const [touched, setTouched] = useState({
        areas: false,
        provinces: false,
        employees: false,
        revenue: false,
        bbee: false
    });
    const [errors, setError] = useState({
        expense_areas: false,
        provinces: false,
        employees: false,
        revenue: false,
        bbee: false,
        registration_num: false
    });
    const [TPAlink, setTPALink] = useState('');

    const getUserTriggerFunc = () => {
        getUserTrigger(userId);
        getStatuses(userId);
        getDocuments(userId);
    };

    useEffect(() => {
        if (StatusUpdatedSuccessfully && successStatusData) {
            message.success(successStatusData.message);
            setIsOpenStatus(false);
            getUserTriggerFunc();
        }
    }, [StatusUpdatedSuccessfully, successStatusData]);

    useEffect(() => {
        if (isErrorCreateSupplier) {
            setError((prev) => ({ ...prev, registration_num: true }));
        }
    }, [isErrorCreateSupplier]);

    useEffect(() => {
        if (isSuccess || isSuccessUpdateSupplier) {
            if (step === 'general') {
                setStep('contact_info');
            }
            if (step === 'contact_info' && isSuccess) {
                messageApi.success('The supplier was successfully created');
                setOpenModal({ open: false, type: '', id: null });
                refetch();
            }
            if (step === 'contact_info' && isSuccessUpdateSupplier) {
                messageApi.success('The supplier was successfully updated');
                setOpenModal({ open: true, type: 'view', id: dataUser?.data?.id });
                refetch();
            }
        }
    }, [isSuccess, isSuccessUpdateSupplier]);

    useEffect(() => {
        if (!selectedArea.length && touched.areas) {
            setError((prev) => ({ ...prev, expense_areas: true }));
        } else {
            setError((prev) => ({ ...prev, expense_areas: false }));
        }
        if (!selectedEmployees && touched.employees) {
            setError((prev) => ({ ...prev, employees: true }));
        } else {
            setError((prev) => ({ ...prev, employees: false }));
        }
        if (!selectedRevenue && touched.revenue) {
            setError((prev) => ({ ...prev, revenue: true }));
        } else {
            setError((prev) => ({ ...prev, revenue: false }));
        }
        if (!selectedBbee && touched.bbee) {
            setError((prev) => ({ ...prev, bbee: true }));
        } else {
            setError((prev) => ({ ...prev, bbee: false }));
        }
        if (!selectedProvinnces.length && touched.provinces) {
            setError((prev) => ({ ...prev, provinces: true }));
        } else {
            setError((prev) => ({ ...prev, provinces: false }));
        }

        if (!dataSchoolTypes?.data?.expense_areas) return;
        const areas = [...dataSchoolTypes?.data?.expense_areas];
        const filteredArray: any = areas.filter(
            (item) =>
                !selectedArea.some((secondItem) => {
                    return secondItem.id === item.id;
                })
        );
        setFiltredAreas(filteredArray);
    }, [
        selectedArea,
        dataSchoolTypes?.data?.expense_areas,
        selectedEmployees,
        selectedProvinnces,
        selectedRevenue,
        selectedBbee,
        touched
    ]);

    const selectItem = (
        area: any,
        setFiltredArray: Dispatch<SetStateAction<any[]>>,
        setSelectedItems: Dispatch<SetStateAction<any[]>>,
        selectedItem: any[],
        data: any[]
    ) => {
        if (area.id === 'selectedAll') {
            const ids = data?.map((elem: any) => elem);
            setFiltredArray([]);
            setSelectedItems(ids);
        } else {
            const newSelectedAreas = [...selectedItem, area];
            setSelectedItems(newSelectedAreas);
        }
    };

    const deleteItem = (
        id: string | number,
        setSelectedItems: Dispatch<SetStateAction<any[]>>,
        selectedItem: any[]
    ) => {
        const areas = [...selectedItem];
        const filteredArray: any = areas.filter((item) => item.id !== id);
        setSelectedItems(filteredArray);
    };

    useEffect(() => {
        if (dataSchoolTypes?.data?.expense_areas) {
            setFiltredAreas(dataSchoolTypes?.data?.expense_areas);
        }
    }, [dataSchoolTypes?.data?.expense_areas]);

    useEffect(() => {
        if (!dataSchoolTypes?.data?.provinces) return;
        const provinces = [...dataSchoolTypes?.data?.provinces];
        const filteredArray: any = provinces.filter(
            (item) =>
                !selectedProvinnces.some((secondItem) => {
                    return secondItem.id === item.id;
                })
        );
        setFiltredProvinnces(filteredArray);
    }, [selectedProvinnces, dataSchoolTypes?.data?.provinces]);

    useEffect(() => {
        if (inviteSuccess) {
            messageApi.success('The invite has been resent successfully');
            setOpenModal({ open: false, type: '', id: null });
            refetch();
        }
    }, [inviteSuccess]);

    useEffect(() => {
        if (type !== 'create' && openModal) {
            getUserTriggerFunc();
        }
    }, [type, openModal]);

    useEffect(() => {
        if (!dataUser) return;
        form.setFieldsValue({
            name: dataUser.data.name ? dataUser.data.name : '',
            trading_name: dataUser.data.trading_name ? dataUser.data.trading_name : '',
            registration_num: dataUser.data.registration_num ? dataUser.data.registration_num : '',
            website: dataUser.data.website ? dataUser.data.website : '',
            contact_name: dataUser.data.primary_contact?.name ? dataUser.data.primary_contact?.name : '',
            phone_number: dataUser.data.primary_contact?.phone_number
                ? dataUser.data.primary_contact?.phone_number
                : '',
            job: dataUser.data.primary_contact?.job_title ? dataUser.data.primary_contact?.job_title : '',
            email: dataUser.data.primary_contact?.email ? dataUser.data.primary_contact?.email : '',
            secondary_email: dataUser.data.primary_contact?.secondary_email
                ? dataUser.data.primary_contact?.secondary_email
                : '',
            tpa_link: dataUser?.data?.tpa_link ? dataUser?.data?.tpa_link : ''
        });
        setCheckbox(dataUser?.data?.tpa_link ? true : false);
        setSelectedArea(dataUser.data.expense_areas);
        setSelectedProvinnces(dataUser.data.provinces);
        setSelectedEmployees(dataUser.data.employees_number);
        setSelectedRevenue(dataUser.data.annual_revenue);
        setSelectedBbee(dataUser.data.b_bbee);
    }, [getUserSuccess, dataUser, form]);

    const handleSubmit = () => {
        let isValid = true;
        const errorObj = { ...errors };

        if (selectedArea.length === 0) {
            errorObj.expense_areas = true;
            isValid = false;
        }
        if (selectedProvinnces.length === 0) {
            errorObj.provinces = true;
            isValid = false;
        }
        if (!selectedEmployees) {
            errorObj.employees = true;
            isValid = false;
        }
        if (!selectedRevenue) {
            errorObj.revenue = true;
            isValid = false;
        }
        if (!selectedBbee) {
            errorObj.bbee = true;
            isValid = false;
        }
        setError(errorObj);

        form.validateFields()
            .then((values: any) => {
                if (!isValid) return;
                if (step === 'general') {
                    const data = {
                        step: 1,
                        id: dataUser?.data?.id,
                        data: {
                            company: {
                                name: values.name,
                                trading_name: values.trading_name,
                                registration_num: values.registration_num,
                                website: values.website,
                                expense_areas: selectedArea?.map((elem: any) => elem.id),
                                provinces: selectedProvinnces?.map((elem: any) => elem.id),
                                employees_number: selectedEmployees?.id,
                                b_bbee: selectedBbee?.id,
                                annual_revenue: selectedRevenue?.id
                            }
                        }
                    };

                    setSupplierData({
                        name: values.name,
                        trading_name: values.trading_name,
                        registration_num: values.registration_num,
                        website: values.website
                    });
                    if (type === 'create') {
                        createSupplier(data);
                    }
                    if (type === 'edit') {
                        updateSupplier(data);
                    }
                }

                if (step === 'contact_info') {
                    const data = {
                        step: 2,
                        id: dataUser?.data?.id,
                        data: {
                            company: {
                                name: supplierData.name,
                                trading_name: supplierData.trading_name,
                                registration_num: supplierData.registration_num,
                                website: supplierData.website,
                                expense_areas: selectedArea?.map((elem: any) => elem.id),
                                provinces: selectedProvinnces?.map((elem: any) => elem.id),
                                employees_number: selectedEmployees?.id,
                                b_bbee: selectedBbee?.id,
                                annual_revenue: selectedRevenue?.id
                            },
                            contact: {
                                name: values.contact_name,
                                job_title: values.job,
                                email: values.email,
                                secondary_email: values.secondary_email,
                                phone_number: values.phone_number,
                                send_tpa: checkbox,
                                tpa_link: checkbox ? TPAlink : ''
                            }
                        }
                    };
                    if (type === 'create') {
                        createSupplier(data);
                    }
                    if (type === 'edit') {
                        updateSupplier(data);
                    }
                }
            })
            .catch((errors) => {
                console.log(errors);
            });
    };

    const openFile = (uuid: string) => {
        downloadSingleFileFunc(uuid)
            .then((response: any) => {
                const blob = new Blob([response.data], {
                    type: response.headers['content-type']
                });
                const fileURL = URL.createObjectURL(blob);

                window.open(fileURL, '_blank');
            })
            .catch(() => {
                messageApi.error('Opening file Error. Check your internet connection');
            });
    };

    const star = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path
                    d="M3.86361 10.4163C3.61007 10.5486 3.30335 10.5253 3.07271 10.3562C2.84208 10.1871 2.72765 9.90153 2.77765 9.61994L3.18232 7.30047L1.47861 5.66779C1.27036 5.46919 1.19404 5.16899 1.28216 4.89505C1.37028 4.62111 1.60731 4.4217 1.89228 4.38175L4.25777 4.04361L5.32571 1.91323C5.45234 1.65773 5.71284 1.49609 5.99799 1.49609C6.28315 1.49609 6.54365 1.65773 6.67027 1.91323L7.73822 4.04361L10.1037 4.38175C10.3887 4.4217 10.6257 4.62111 10.7138 4.89505C10.8019 5.16899 10.7256 5.46919 10.5174 5.66779L8.81367 7.30047L9.21834 9.62044C9.26834 9.90203 9.15391 10.1876 8.92328 10.3567C8.69264 10.5258 8.38592 10.5491 8.13238 10.4168L5.99799 9.31381L3.86361 10.4163Z"
                    fill="#E3A11F"
                    stroke="#E3A11F"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        );
    };

    return (
        <>
            {contextHolder}
            <Modal
                destroyOnClose
                className={`${type === 'view' || type === 'view_pending' ? 'view' : ''} modal-supplier`}
                centered
                open={openModal}
                onOk={() => handleSubmit()}
                onCancel={() => {
                    if (form.isFieldsTouched() || changed) {
                        setIsConfirmModal(true);
                    } else {
                        setOpenModal({ open: false, type: '', id: null });
                        refetch();
                    }
                }}
                closeIcon={<img src={close} />}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        {step === 'general' && (
                            <>
                                <Button
                                    block
                                    key="back"
                                    onClick={() => {
                                        if (form.isFieldsTouched() || changed) {
                                            setIsConfirmModal(true);
                                        } else {
                                            setOpenModal({ open: false, type: '', id: null });
                                            refetch();
                                        }
                                    }}
                                    className="cancel"
                                >
                                    Cancel
                                </Button>
                                <Button block key="submit" type="primary" onClick={() => handleSubmit()}>
                                    {isLoadingCreateSupplier ? <Loader /> : 'Next'}
                                </Button>
                            </>
                        )}
                    </div>
                }
            >
                <Spin spinning={loading} size="large">
                    <div className="modal">
                        <div className={`modal__header addsupplier ${type === 'edit' ? 'edit-supplier-header' : ''}`}>
                            {type === 'view' && (
                                <div className="addsupplier">
                                    <div className="addsupplier__company_name">{dataUser?.data?.name}</div>
                                    <div className="addsupplier__trading_name">{dataUser?.data?.trading_name}</div>
                                    <div className="addsupplier__status">
                                        <div
                                            className={`supplier_status_onmodal ${isOpenStatus ? 'open' : ''}`}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => setIsOpenStatus(true)}
                                            ref={ref}
                                        >
                                            <div className="supplier_status_onmodal__status">
                                                <img src={statusImage(dataUser?.data?.status_name)} />
                                                {dataUser?.data.status_name}
                                                {statuses?.data.length - 1 > 0 && (
                                                    <img src={arrow} className="supplier_status_onmodal__image" />
                                                )}
                                            </div>
                                            {isOpenStatus && (
                                                <>
                                                    {statuses?.data?.map((elem: { name: string; key: string }) => {
                                                        if (elem.name === dataUser?.data.status_name) return;
                                                        return (
                                                            <div
                                                                className="supplier_status_onmodal__status"
                                                                onClick={() => {
                                                                    if (elem.key === 'blacklisted') {
                                                                        setIsBlacklisted(true);
                                                                    } else {
                                                                        updateStatus({
                                                                            id: dataUser?.data?.id,
                                                                            data: {
                                                                                status: elem.key
                                                                            }
                                                                        });
                                                                    }
                                                                }}
                                                            >
                                                                <img src={statusImage(elem.name)} />
                                                                {elem.name}
                                                            </div>
                                                        );
                                                    })}
                                                </>
                                            )}
                                        </div>
                                    </div>

                                    {dataUser?.data?.status_name === 'Blacklisted' ? (
                                        <div className="addsupplier__comment">
                                            <div className="addsupplier__comment-row">
                                                <div>Comment</div>
                                                <div>
                                                    {moment(dataUser?.data?.blacklisted_at).format('DD MMM, YYYY')}
                                                </div>
                                            </div>
                                            <div className="addsupplier__comment-value">
                                                {dataUser?.data?.payload?.blacklist_comment}
                                            </div>
                                        </div>
                                    ) : (
                                        <div style={{ height: '40px' }}></div>
                                    )}
                                </div>
                            )}
                            {type === 'edit' && <div className="modal__title">Edit</div>}
                            {(type === 'view' || type === 'view_pending') && (
                                <div
                                    className="modal__edit"
                                    onClick={() => setOpenModal((prevState) => ({ ...prevState, type: 'edit' }))}
                                >
                                    <img src={penEdit} />
                                    <div>Edit</div>
                                </div>
                            )}
                        </div>
                        {(type === 'create' || type === 'edit') && (
                            <div className="modal__main supplier">
                                <div className={`supplier__steps ${step === 'contact_info' ? 'active' : ''}`}>
                                    <div className={`supplier__step active ${step === 'contact_info' ? 'done' : ''}`}>
                                        <div className="supplier__number">
                                            {step === 'contact_info' ? (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M16.6673 5.41309L7.50065 14.5798L3.33398 10.4131"
                                                        stroke="white"
                                                        stroke-width="1.5"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                </svg>
                                            ) : (
                                                '1'
                                            )}
                                        </div>{' '}
                                        Company Info
                                    </div>{' '}
                                    - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
                                    <div className={`supplier__step ${step === 'contact_info' ? 'active' : ''}`}>
                                        <div className="supplier__number">2</div> Contact Info
                                    </div>
                                </div>
                                <div className="supplier__subtitle" style={{ margin: '0 0 10px 0' }}>
                                    Fields marked with <span style={{ color: '#E80A0F' }}>*</span> are mandatory
                                </div>
                                {step === 'general' && (
                                    <Form form={form} onFinish={handleSubmit} validateTrigger="onBlur">
                                        <div className="fields__block">
                                            <Form.Item
                                                className="input-wrapper"
                                                name="name"
                                                rules={yupSync('name', validationSchema, true)}
                                            >
                                                <FloatInput
                                                    floatLabel="Full Name of Legal Entity"
                                                    className="input"
                                                    maxLength={128}
                                                    required={true}
                                                    onBlur={() => yupSync('name', validationSchema, true)}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                className="input-wrapper"
                                                name="trading_name"
                                                rules={yupSync('trading_name', validationSchema, true)}
                                            >
                                                <FloatInput
                                                    floatLabel="Trading Name"
                                                    className="input"
                                                    maxLength={128}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                className="input-wrapper"
                                                name="registration_num"
                                                rules={yupSync('registration_num', validationSchema, true)}
                                            >
                                                <PhoneNumberInput
                                                    floatLabel="Company Registration Number"
                                                    className="input"
                                                    maxLength={256}
                                                    value={''}
                                                    placeholder={undefined}
                                                    required={true}
                                                    onChange={() =>
                                                        setError((prev) => ({ ...prev, registration_num: false }))
                                                    }
                                                    hasError={() => !!form.getFieldError('registration_num').length}
                                                    onBlur={() => yupSync('registration_num', validationSchema, true)}
                                                    mask={'9999/999999/99'}
                                                    error={errors.registration_num}
                                                />
                                            </Form.Item>
                                            {errors.registration_num && (
                                                <div className="error-text" style={{ margin: '-10px 0 10px 0' }}>
                                                    Oops! It looks like that Registration Number is already registered
                                                </div>
                                            )}
                                            <Form.Item
                                                className="input-wrapper"
                                                name="website"
                                                rules={yupSync('website', validationSchema, true)}
                                            >
                                                <FloatInput
                                                    floatLabel="Website"
                                                    className="input"
                                                    maxLength={1024}
                                                    required={true}
                                                    disabled={false}
                                                    onKeyPress={handleKeyPress}
                                                />
                                            </Form.Item>{' '}
                                            <div className="supplier__subtitle" style={{ margin: '8px 0 16px 0' }}>
                                                Select the expense areas you would like to receive tender notifications
                                                for
                                            </div>
                                            <Form.Item
                                                className="input-wrapper-multiselect"
                                                rules={yupSync('expense_areas', validationSchema, true)}
                                            >
                                                <CustomMultiSelect
                                                    required={true}
                                                    floatLabel={'Expense Area/s'}
                                                    err={false}
                                                    options={filtredAreas}
                                                    onChange={(item: any) => {
                                                        selectItem(
                                                            item,
                                                            setFiltredAreas,
                                                            setSelectedArea,
                                                            selectedArea,
                                                            dataSchoolTypes?.data?.expense_areas
                                                        );
                                                    }}
                                                    hasError={() => errors.expense_areas}
                                                    prefix={undefined}
                                                    value={selectedArea}
                                                    deleteItem={(id: string | number) =>
                                                        deleteItem(id, setSelectedArea, selectedArea)
                                                    }
                                                    deleteAllItems={setSelectedArea}
                                                    setTouched={(value) => {
                                                        setTouched((prev) => ({ ...prev, areas: value }));
                                                        setChanged(true);
                                                    }}
                                                />
                                            </Form.Item>
                                        </div>
                                        {errors.expense_areas && (
                                            <div className="error-text" style={{ margin: '-10px 0 10px 0' }}>
                                                This is a required field
                                            </div>
                                        )}

                                        <div className="fields__block">
                                            <Form.Item
                                                className="input-wrapper-multiselect"
                                                rules={yupSync('provinces', validationSchema, true)}
                                            >
                                                <CustomMultiSelect
                                                    required={true}
                                                    floatLabel={'Provincial Coverage'}
                                                    err={false}
                                                    options={filtredProvinnces}
                                                    onChange={(item: any) =>
                                                        selectItem(
                                                            item,
                                                            setFiltredProvinnces,
                                                            setSelectedProvinnces,
                                                            selectedProvinnces,
                                                            dataSchoolTypes?.data?.provinces
                                                        )
                                                    }
                                                    hasError={() => errors.provinces}
                                                    prefix={undefined}
                                                    value={selectedProvinnces}
                                                    deleteItem={(id: string | number) =>
                                                        deleteItem(id, setSelectedProvinnces, selectedProvinnces)
                                                    }
                                                    deleteAllItems={setSelectedProvinnces}
                                                    setTouched={(value) => {
                                                        setTouched((prev) => ({ ...prev, provinces: value }));
                                                        setChanged(true);
                                                    }}
                                                />
                                            </Form.Item>
                                            {errors.provinces && (
                                                <div className="error-text" style={{ margin: '-10px 0 10px 0' }}>
                                                    This is a required field
                                                </div>
                                            )}
                                        </div>
                                        <div className="fields__block">
                                            <Form.Item
                                                className="input-wrapper"
                                                rules={yupSync('employees_number', validationSchema, true)}
                                            >
                                                <FloatSelect
                                                    type="common"
                                                    required={true}
                                                    floatLabel={'# of Employees'}
                                                    err={false}
                                                    className="input-select"
                                                    setSearch={() => {}}
                                                    options={dataSchoolTypes?.data?.sc_employees_number}
                                                    onChange={setSelectedEmployees}
                                                    value={selectedEmployees}
                                                    search={''}
                                                    form={form}
                                                    hasError={() => errors.employees}
                                                    setTouched={(value) => {
                                                        setTouched((prev) => ({ ...prev, employees: value }));
                                                        setChanged(true);
                                                    }}
                                                />
                                            </Form.Item>
                                            {errors.employees && (
                                                <div className="error-text" style={{ margin: '-10px 0 10px 0' }}>
                                                    This is a required field
                                                </div>
                                            )}
                                        </div>
                                        <div className="fields__block">
                                            <div
                                                className="fields__row"
                                                style={{ display: 'flex', justifyContent: 'space-between' }}
                                            >
                                                <Form.Item
                                                    className="small-input-wrapper"
                                                    rules={yupSync('annual_revenue', validationSchema, true)}
                                                >
                                                    <FloatSelect
                                                        type="common"
                                                        required={true}
                                                        floatLabel={'Annual Revenue'}
                                                        err={false}
                                                        className="input-select"
                                                        setSearch={() => {}}
                                                        options={dataSchoolTypes?.data?.sc_annual_revenue}
                                                        onChange={setSelectedRevenue}
                                                        value={selectedRevenue}
                                                        search={''}
                                                        form={form}
                                                        hasError={() => errors.revenue}
                                                        setTouched={(value) => {
                                                            setTouched((prev) => ({ ...prev, revenue: value }));
                                                            setChanged(true);
                                                        }}
                                                    />
                                                    {errors.revenue && (
                                                        <div
                                                            className="error-text"
                                                            style={{ margin: '33px 0 -55px 0' }}
                                                        >
                                                            This is a required field
                                                        </div>
                                                    )}
                                                </Form.Item>
                                                <Form.Item
                                                    className="small-input-wrapper"
                                                    rules={yupSync('b_bbee', validationSchema, true)}
                                                >
                                                    <FloatSelect
                                                        type="common"
                                                        required={true}
                                                        floatLabel={'B-BBEE'}
                                                        err={false}
                                                        className="input-select"
                                                        setSearch={() => {}}
                                                        options={dataSchoolTypes?.data?.sc_b_bbee}
                                                        onChange={setSelectedBbee}
                                                        value={selectedBbee}
                                                        search={''}
                                                        form={form}
                                                        hasError={() => errors.bbee}
                                                        setTouched={(value) => {
                                                            setTouched((prev) => ({ ...prev, bbee: value }));
                                                            setChanged(true);
                                                        }}
                                                    />
                                                    {errors.bbee && (
                                                        <div
                                                            className="error-text"
                                                            style={{ margin: '33px 0 -55px 0' }}
                                                        >
                                                            This is a required field
                                                        </div>
                                                    )}
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                                {step === 'contact_info' && (
                                    <div className="main__block">
                                        <div className="main__form form">
                                            <div className="signup__fields fields">
                                                <Form form={form} onFinish={handleSubmit} validateTrigger="onBlur">
                                                    <div className="fields__block">
                                                        <Form.Item
                                                            className={`${
                                                                screenWidth > 600 ? 'input-wrapper' : 'input-wrapper'
                                                            }`}
                                                            name="contact_name"
                                                            rules={yupSync('contact_name', validationSchema, true)}
                                                        >
                                                            <FloatInput
                                                                floatLabel="Contact Name"
                                                                className="input"
                                                                maxLength={60}
                                                                required={true}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            className="input-wrapper"
                                                            name="job"
                                                            rules={yupSync('job', validationSchema, true)}
                                                        >
                                                            <FloatInput
                                                                floatLabel="Job Title"
                                                                className="input"
                                                                maxLength={60}
                                                                required={true}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            className="input-wrapper"
                                                            name="phone_number"
                                                            rules={yupSync('phone_number', validationSchema, true)}
                                                        >
                                                            <PhoneNumberInput
                                                                floatLabel="Contact Phone number"
                                                                className="input"
                                                                maxLength={256}
                                                                value={''}
                                                                placeholder={undefined}
                                                                required={true}
                                                                onChange={() => {}}
                                                                hasError={() =>
                                                                    !!form.getFieldError('phone_number').length
                                                                }
                                                                onBlur={() =>
                                                                    yupSync('phone_number', validationSchema, true)
                                                                }
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            className="input-wrapper"
                                                            name="email"
                                                            rules={yupSync('email', validationSchema, true)}
                                                        >
                                                            <FloatInput
                                                                floatLabel="Primary Email"
                                                                className="input"
                                                                maxLength={60}
                                                                required={true}
                                                                onKeyPress={handleKeyPress}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            className="input-wrapper"
                                                            name="secondary_email"
                                                            rules={yupSync('secondary_email', validationSchema, true)}
                                                        >
                                                            <FloatInput
                                                                floatLabel="Secondary Email"
                                                                className="input"
                                                                maxLength={60}
                                                                onKeyPress={handleKeyPress}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                    {type === 'create' && (
                                                        <Form.Item className="input-wrapper">
                                                            <Checkbox
                                                                checked={checkbox}
                                                                onChange={() => setCheckbox((prev) => !prev)}
                                                            >
                                                                <div className="checkbox_text">
                                                                    Request Company Documentation
                                                                </div>
                                                            </Checkbox>
                                                        </Form.Item>
                                                    )}
                                                    {type === 'create' && (
                                                        <Form.Item
                                                            className="input-wrapper"
                                                            name={checkbox ? 'tpa_link' : ''}
                                                            rules={
                                                                checkbox
                                                                    ? yupSync('tpa_link', validationSchema, true)
                                                                    : []
                                                            }
                                                        >
                                                            <FloatInput
                                                                floatLabel="Link to TPA"
                                                                className="input"
                                                                maxLength={1024}
                                                                required={checkbox ? true : false}
                                                                disabled={checkbox ? false : true}
                                                                onKeyPress={handleKeyPress}
                                                                onChange={(e: any) => setTPALink(e.target.value)}
                                                                value={TPAlink}
                                                            />
                                                        </Form.Item>
                                                    )}
                                                </Form>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyItems: 'space-between',
                                                alignItems: 'center',
                                                gap: '16px',
                                                width: '100%',
                                                margin: '38px 0 -31px 0'
                                            }}
                                        >
                                            <img
                                                src={RoundedArrowLeft}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => setStep('general')}
                                            />
                                            <Button block key="submit" type="primary" onClick={() => handleSubmit()}>
                                                {isLoadingCreateSupplier ? (
                                                    <Loader />
                                                ) : (
                                                    <>{type === 'create' ? 'Create' : type === 'edit' ? 'Edit' : ''}</>
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        {type === 'view' && (
                            <div className="view-supplier">
                                <div className="modal__main supplier row">
                                    <div className="supplier__column">
                                        <div className="addsupplier__contact_name">
                                            {dataUser?.data?.primary_contact?.name}
                                        </div>
                                        <div className="addsupplier__job_title">
                                            {dataUser?.data?.primary_contact?.job_title}
                                        </div>
                                    </div>
                                    <div className="supplier__column">
                                        <div className="supplier__email">
                                            <div className="supplier__email-wrap">
                                                <img src={email} />
                                                {dataUser?.data?.primary_contact?.email}
                                            </div>
                                            <div className="supplier__status primary">Primary</div>
                                        </div>
                                        {dataUser?.data?.primary_contact?.secondary_email && (
                                            <div className="supplier__email">
                                                <div className="supplier__email-wrap">
                                                    <img src={email} />
                                                    {dataUser?.data?.primary_contact?.secondary_email}
                                                </div>
                                                <div className="supplier__status secondary">Secondary</div>
                                            </div>
                                        )}
                                        <div className="supplier__email">
                                            <div className="supplier__email-wrap">
                                                <img src={Phone} />
                                                {dataUser?.data?.primary_contact?.phone_number}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Divider />
                                <div className="modal__main supplier row">
                                    <div className="supplier__column">
                                        <div className="supplier__row">
                                            <div className="supplier__title">Province</div>
                                            <div className="supplier__value">
                                                {dataUser?.data?.provinces?.map((elem: any) => elem.name).join(', ')}
                                            </div>
                                        </div>
                                        <Divider />
                                        <div className="supplier__row" style={{ padding: '0' }}>
                                            <div className="supplier__title">Expanse Area</div>
                                            <div className="supplier__value expense_areas">
                                                {dataUser?.data?.expense_areas?.map((elem: any, index: number) => (
                                                    <div className="expense_areas__item">
                                                        <div className="expense_areas__name-wrapper">
                                                            <div>{elem.name}</div>
                                                            <div className="expense_areas__ratings">
                                                                {elem.ratings?.map((rating: any, index: number) => {
                                                                    return (
                                                                        <Tooltip
                                                                            color="#fff"
                                                                            key={'#fff'}
                                                                            overlayClassName="rating"
                                                                            title={
                                                                                <div className="rating">
                                                                                    <div className="rating__row">
                                                                                        <div className="rating__title">
                                                                                            {rating.entity_name}
                                                                                        </div>
                                                                                        <div
                                                                                            key={index}
                                                                                            className="rating__rating"
                                                                                        >
                                                                                            {star()}
                                                                                            {rating.rating}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="rating__subtitle">
                                                                                        {rating.contact_name}
                                                                                    </div>
                                                                                    <div className="rating__text">
                                                                                        {rating.email}{' '}
                                                                                        {rating.phone_number}
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        >
                                                                            <div
                                                                                key={index}
                                                                                className="expense_areas__rating"
                                                                            >
                                                                                {star()}
                                                                                {rating.rating}
                                                                            </div>
                                                                        </Tooltip>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                        {index !== dataUser?.data?.expense_areas.length - 1 && (
                                                            <Divider />
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <Divider />
                                        <div className="supplier__row">
                                            <div className="supplier__title">Annual Revenue</div>
                                            <div className="supplier__value">
                                                {dataUser?.data?.annual_revenue?.name}
                                            </div>
                                        </div>
                                        <Divider />
                                        <div className="supplier__row">
                                            <div className="supplier__title"># of Employees</div>
                                            <div className="supplier__value">
                                                {dataUser?.data?.employees_number?.name}
                                            </div>
                                        </div>
                                        <Divider />
                                        <div className="supplier__row">
                                            <div className="supplier__title">Website</div>
                                            <a
                                                className="supplier__value"
                                                href={dataUser?.data?.website}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {dataUser?.data?.website}
                                            </a>
                                        </div>
                                        <Divider />
                                        <div className="supplier__row">
                                            <div className="supplier__title">B-BBEE</div>
                                            <div className="supplier__value">{dataUser?.data?.b_bbee?.name}</div>
                                        </div>
                                        <Divider />
                                        <div className="supplier__row" style={{ margin: '0 0 30px 0' }}>
                                            <div className="supplier__title">Company Registration #</div>
                                            <div className="supplier__value">{dataUser?.data?.registration_num}</div>
                                        </div>
                                    </div>
                                    <div className="supplier__column">
                                        <div className="documents">
                                            <div className="documents__title">Company Documents</div>
                                            {dataUser?.data?.document_status === 'requested' && (
                                                <div className="documents__requested requested">
                                                    <img src={Requested} />
                                                    <div className="requested__status">Requested</div>
                                                    <ul className="requested__list">
                                                        <li>Financial Accounts</li>
                                                        <li>B-BBEE Affidavit / Certificate</li>
                                                        <li>SARS Letter of Good Standing</li>
                                                        <li>Company Registration Certificate</li>
                                                    </ul>
                                                    {dataUser?.data?.status_name !== 'Pending TPA' && (
                                                        <div
                                                            className="documents__btn"
                                                            onClick={() => {
                                                                if (isLoadingResend) return;
                                                                setRequestDoc(true);
                                                                // resendDocuments(dataUser?.data?.id);
                                                            }}
                                                        >
                                                            {isLoadingResend ? (
                                                                <Loader color={'#001965'} />
                                                            ) : (
                                                                'Repeat Request'
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            {dataUser?.data?.document_status === 'received' && (
                                                <>
                                                    <div className="documents__row">
                                                        <div>
                                                            <div className="documents__doc-title">
                                                                Financial/Management Accounts
                                                            </div>
                                                            <div className="documents__doc">
                                                                <img src={fileIcon} />
                                                                <div className="documents__name">
                                                                    {documents?.data?.documents?.[0]?.name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="documents__download-wrap">
                                                            <img
                                                                src={sendDocIcon}
                                                                className="documents__download"
                                                                onClick={() =>
                                                                    openFile(documents?.data?.documents?.[0]?.uuid)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="documents__row">
                                                        <div>
                                                            <div className="documents__doc-title">
                                                                B-BBEE Certificate/Affidavit
                                                            </div>
                                                            <div className="documents__doc">
                                                                <img src={fileIcon} />
                                                                <div className="documents__name">
                                                                    {documents?.data?.documents?.[1]?.name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="documents__download-wrap">
                                                            <img
                                                                src={sendDocIcon}
                                                                className="documents__download"
                                                                onClick={() =>
                                                                    openFile(documents?.data?.documents?.[1]?.uuid)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="documents__row">
                                                        <div>
                                                            <div className="documents__doc-title">
                                                                SARS Letter of Good Standing
                                                            </div>
                                                            <div className="documents__doc">
                                                                <img src={fileIcon} />
                                                                <div className="documents__name">
                                                                    {documents?.data?.documents?.[2]?.name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="documents__download-wrap">
                                                            <img
                                                                src={sendDocIcon}
                                                                className="documents__download"
                                                                onClick={() =>
                                                                    openFile(documents?.data?.documents?.[2]?.uuid)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="documents__row">
                                                        <div>
                                                            <div className="documents__doc-title">
                                                                Company Registration Certificate
                                                            </div>
                                                            <div className="documents__doc">
                                                                <img src={fileIcon} />
                                                                <div className="documents__name">
                                                                    {documents?.data?.documents?.[3]?.name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="documents__download-wrap">
                                                            <img
                                                                src={sendDocIcon}
                                                                className="documents__download"
                                                                onClick={() =>
                                                                    openFile(documents?.data?.documents?.[3]?.uuid)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    {dataUser?.data?.status_name !== 'Pending TPA' &&
                                                        dataUser?.data?.status_name !== 'Pending' && (
                                                            <div
                                                                className="documents__btn"
                                                                onClick={() => setIsOpenRejectDocument(true)}
                                                            >
                                                                Reject Documents
                                                            </div>
                                                        )}
                                                    {dataUser?.data?.status_name !== 'Pending TPA' &&
                                                        dataUser?.data?.status_name === 'Pending' && (
                                                            <div
                                                                className="documents__btn"
                                                                onClick={() => {
                                                                    if (isLoadingResend) return;
                                                                    setRequestNewTpa(true);
                                                                    // resendDocuments(dataUser?.data?.id);
                                                                }}
                                                            >
                                                                {isLoadingResend ? (
                                                                    <Loader color={'#001965'} />
                                                                ) : (
                                                                    'Repeat Request'
                                                                )}
                                                            </div>
                                                        )}
                                                </>
                                            )}{' '}
                                            {dataUser?.data?.document_status === 'not_provided' && (
                                                <>
                                                    <div className="documents__no-documents">
                                                        <img src={noDoc} />
                                                        Not Provided
                                                    </div>
                                                    {dataUser?.data?.status_name !== 'Pending TPA' && (
                                                        <div
                                                            className="documents__btn"
                                                            onClick={() => {
                                                                if (documents?.data?.tpa?.name) {
                                                                    setIsOpenRejectDocument(true);
                                                                } else {
                                                                    setRequestDoc(true);
                                                                }
                                                            }}
                                                        >
                                                            Request Documents
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        {(dataUser?.data?.document_status === 'requested' ||
                                            dataUser?.data?.document_status === 'received') &&
                                            dataUser?.data?.status === 'pending' && (
                                                <div className="documents">
                                                    <div className="documents__title">TPA</div>
                                                    <div className="documents__requested requested">
                                                        <img src={Requested} />
                                                        <div className="requested__status">Requested</div>
                                                    </div>
                                                </div>
                                            )}

                                        {documents?.data?.tpa?.name && (
                                            <div className="documents">
                                                <div className="documents__title">TPA</div>
                                                <div className="documents__row">
                                                    <div
                                                        className="documents__doc"
                                                        onClick={() => openFile(documents?.data?.tpa?.uuid)}
                                                    >
                                                        <img src={fileIcon} />
                                                        <div className="documents__name">
                                                            {documents?.data?.tpa?.name}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="documents__row">
                                                    <div
                                                        className="documents__btn newTpa"
                                                        onClick={() => setRequestNewTpa(true)}
                                                    >
                                                        Request New TPA
                                                    </div>
                                                    <img
                                                        src={UploadNewTpa}
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => setOpenUploadTPA(true)}
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        {dataUser?.data?.status_name === 'Pending TPA' && (
                                            <div className="documents">
                                                <div className="documents__title">Current TPA</div>
                                                <div className="documents__btn" onClick={() => setOpenUploadTPA(true)}>
                                                    Upload File
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </Spin>
            </Modal>
            <Modal
                className={`${type === 'view' || type === 'view_pending' ? 'view' : ''} confirmModal`}
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                setOpenModal({ open: false, type: '', id: null });
                                setIsConfirmModal(false);
                                refetch();
                            }}
                            // block
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Your data won’t be saved! Are you sure you want to quit?</div>
            </Modal>

            {isBlacklisted && (
                <BlacklistSupplier
                    openModal={isBlacklisted}
                    setOpenModal={setIsBlacklisted}
                    setTrigger={getUserTriggerFunc}
                    data={dataUser?.data}
                    message={message}
                />
            )}
            {openUploadTPA && (
                <UploadFileSupplier
                    openModal={openUploadTPA}
                    setOpenModal={setOpenUploadTPA}
                    title={'TPA'}
                    subtitle={'Upload a file to import TPA'}
                    note={''}
                    file={filesTpa}
                    setFile={setFilesTpa}
                    type={'uploadTpaFile'}
                    data={dataUser?.data}
                    setTrigger={getUserTriggerFunc}
                />
            )}
            {requestDoc && (
                <RequestDocumentModal
                    openModal={requestDoc}
                    setOpenModal={setRequestDoc}
                    setTrigger={getUserTriggerFunc}
                    data={dataUser?.data}
                    message={message}
                />
            )}
            {requestNewTpa && (
                <RequestDocumentModal
                    openModal={requestNewTpa}
                    setOpenModal={setRequestNewTpa}
                    setTrigger={getUserTriggerFunc}
                    data={dataUser?.data}
                    message={message}
                    type="newTpa"
                />
            )}
            {isOpenRejectDocument && (
                <RejectDocSuppModal
                    openModal={isOpenRejectDocument}
                    setOpenModal={setIsOpenRejectDocument}
                    setTrigger={getUserTriggerFunc}
                    data={dataUser?.data}
                    type={documents?.data?.documents?.[0]?.name ? 'reject' : 'request'}
                />
            )}
        </>
    );
};
