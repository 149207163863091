import { FC, useState } from 'react';
import { taskProps, tenderDataProps } from '../../../pages/TenderViewPage/types';
import { TaskDeadlineModal } from '../../../components/Modals/TaskDeadline';
import { useUpdateTaskDeadlineMutation } from '../../../api/tenders';

import TenderClosingImage from '../../../assets/TenderClosingImage.svg';
import Rocket from '../../../assets/Rocket.svg';
import plus from '../../../assets/Plus, Add.svg';
import edit from '../../../assets/editAdminIcon.svg';
import calendar from '../../../assets/calendar-chedule.svg';

import moment from 'moment';

interface DefineSupportingDocumentTaskProps {
    data: tenderDataProps;
    role: string | null;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi: any;
}

export const TenderClosingDate: FC<DefineSupportingDocumentTaskProps> = ({ data, role, messageApi, setTrigger }) => {
    const [isClosingModal, setIsClosingModal] = useState(false);
    const [SelectedDate, setSelectedDate] = useState<Date | string>(
        data?.tasks_deadline_at ? new Date(data?.tasks_deadline_at) : new Date()
    );
    const [updateDeadline, { isLoading }] = useUpdateTaskDeadlineMutation();

    const updateDeadlineFunc = async () => {
        try {
            const formData = {
                id: data.id,
                data: { deadline_at: moment(SelectedDate).format('YYYY-MM-DD') }
            };
            await updateDeadline(formData).unwrap();
            await setIsClosingModal(false);
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    return (
        <>
            {data?.phase_tasks.length ? (
                data?.phase_tasks.map((elem: taskProps) => {
                    if (elem.action === 'launch_closing_date') {
                        if (role !== 'school_user') {
                            if (elem.status === 'pending_tr' && !elem.approved && !data.tasks_deadline_at) {
                                return (
                                    <>
                                        <div key={3} className={`tasks__task task `}>
                                            <div className="task__title">
                                                <div className="task-complete__title-row">
                                                    <img src={TenderClosingImage} />
                                                    Tender Closing Date
                                                </div>
                                                <div className="task-complete__title-row"></div>
                                            </div>
                                            <img
                                                src={plus}
                                                className="task__plus"
                                                onClick={() => {
                                                    setIsClosingModal(true);
                                                }}
                                            />
                                        </div>
                                    </>
                                );
                            }
                            if (
                                (elem.status === 'pending_tr' ||
                                    elem.status === 'completed' ||
                                    elem.status === 'need_approve_tr') &&
                                !elem.approved &&
                                data.tasks_deadline_at
                            ) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete green
                                    `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={TenderClosingImage} />
                                                {elem?.name}
                                            </div>
                                        </div>
                                        <div style={{ position: 'relative' }}>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__name">Date</div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value">
                                                    <img src={calendar} />
                                                    {moment(data.tasks_deadline_at).format('YYYY-MM-DD')}
                                                </div>
                                            </div>

                                            <img
                                                src={edit}
                                                className="task-complete__edit"
                                                onClick={() => {
                                                    setIsClosingModal(true);
                                                }}
                                            />
                                        </div>
                                    </div>
                                );
                            }
                        }
                    }
                })
            ) : (
                <>
                    {role === 'school_user' && data.tasks_deadline_at && (
                        <div className="noTasks">
                            <img src={Rocket} />
                            <div className="noTasks__title">Supplier Onboarding Underway</div>
                            <div className="noTasks__subtitle">
                                We are currently onboarding suppliers for this tender You will be notified as soon as
                                the tender is officially launched.
                                <br /> We will get back to you with their proposals after the tender closing date which
                                is currentlyset as
                            </div>
                            <div className="noTasks__date">
                                <img src={calendar} className="noTasks__image" />{' '}
                                {moment(data.tasks_deadline_at).format('DD MMMM, YYYY')}
                            </div>
                        </div>
                    )}
                    {role === 'school_user' && !data.tasks_deadline_at && (
                        <div className="noTasks">
                            <img src={Rocket} />
                            <div className="noTasks__title">Welcome to the Launch Phase!</div>
                            <div className="noTasks__subtitle">
                                Watch this space - new tasks for you will be posted here as they become available.
                            </div>
                        </div>
                    )}
                </>
            )}
            {isClosingModal && (
                <TaskDeadlineModal
                    title={'Tender closing date'}
                    buttonText="Confirm"
                    openModal={isClosingModal}
                    setOpenModal={setIsClosingModal}
                    SelectedDate={SelectedDate}
                    setSelectedDate={setSelectedDate}
                    updateDeadlineFunc={updateDeadlineFunc}
                    isLoading={isLoading}
                />
            )}
        </>
    );
};
