import { FC, useState, useEffect } from 'react';
import { Button, Form, Modal } from 'antd';
import { yupSync } from '../../utils';
import { FloatTextArea } from '../Fields/FloatTextArea';
import { useCreateTaskMutation, useSendTaskMutation, useUpdateTaskMutation } from '../../api/tenders';
import { Loader } from '../Loader/Loader';
import { tenderDataProps } from '../../pages/TenderViewPage/types';
import close from '../../assets/RoundedClose.svg';
import arrow from '../../assets/arrow-right-white.svg';

import * as Yup from 'yup';
import moment from 'moment';

interface FormDatatoSend {
    comment: string;
    action: string;
}

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    data?: tenderDataProps;
}

const validationSchema = Yup.object().shape({
    comment: Yup.string()
        .required('This is a required field')
        .test('not-only-spaces', 'This is a required field', (value) => {
            return value.trim() !== '';
        })
});

export const MultiAwardModal: FC<ModalProps> = ({ openModal, setOpenModal, data, setTrigger }) => {
    const [form] = Form.useForm();

    const [createTask, { isLoading, isSuccess }] = useCreateTaskMutation();
    const [sendTask, { isLoading: isLoadingSendTask, isSuccess: isSuccessSent }] = useSendTaskMutation();

    const [infoModal, setInfoModal] = useState(false);

    const [isConfirmModal, setIsConfirmModal] = useState(false);

    const sendTaskFunc = async () => {
        const formData = {
            id: data?.id
        };
        try {
            await sendTask(formData).unwrap();
        } catch {}
    };

    useEffect(() => {
        if (isSuccess) {
            sendTaskFunc();
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isSuccessSent) {
            setInfoModal(false);
            setOpenModal(false);
            setTrigger((prev) => !prev);
        }
    }, [isSuccessSent]);

    const updateTaskFunc = async (props: FormDatatoSend) => {
        if (isLoading) return;
        const formData = {
            tenderId: data?.id,
            data: props
        };
        try {
            await createTask(formData).unwrap();
        } catch {}
    };

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: any) => {
                const formData: FormDatatoSend = {
                    action: 'evaluate_4_multi_award',
                    comment: values.comment
                };

                updateTaskFunc(formData);
            })
            .catch(() => {});
    };

    return (
        <>
            <Modal
                className="modal-modal"
                destroyOnClose
                centered
                open={openModal}
                onOk={() => setInfoModal(true)}
                onCancel={() => {
                    if (form.isFieldsTouched()) {
                        setIsConfirmModal(true);
                    } else {
                        setOpenModal(false);
                    }
                }}
                closeIcon={<img src={close} />}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            block
                            key="back"
                            onClick={() => {
                                if (form.isFieldsTouched()) {
                                    setIsConfirmModal(true);
                                } else {
                                    setOpenModal(false);
                                }
                            }}
                            className="cancel"
                        >
                            Cancel
                        </Button>
                        <Button
                            className={`tasks__next-btn`}
                            style={{ width: '100%' }}
                            type="primary"
                            onClick={() => {
                                setInfoModal(true);
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                Send Task <img src={arrow} style={{ margin: '0 0 0 5px ' }} />
                            </div>
                        </Button>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header meeting" style={{ height: '88px' }}>
                        <div className="modal__title name-title">Multi-Award Scope of Work</div>
                    </div>

                    <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                        <div className="main__subtitle" style={{ margin: '0 0 6px 0' }}>
                            Please outline each awarded supplier’s responsibilities{' '}
                            <span style={{ color: '#E80A0F' }}>*</span>
                        </div>

                        <Form form={form} onFinish={handleFormSubmit}>
                            <Form.Item
                                className="input-wrapper"
                                name="comment"
                                rules={yupSync('comment', validationSchema, true)}
                            >
                                <FloatTextArea
                                    floatLabel={'Describe responsibilities'}
                                    hasError={() => !!form.getFieldError(['comment']).length}
                                    minRows={9}
                                    maxLength={1024}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
            <Modal
                className="confirmModal"
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                setOpenModal(false);
                                setIsConfirmModal(false);
                            }}
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Are you sure you want to abandon the scope of work description?</div>
            </Modal>
            <Modal
                className="confirmModal"
                centered
                open={infoModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            className={`tasks__next-btn`}
                            style={{ width: '100%' }}
                            type="primary"
                            onClick={() => {
                                if (isLoading || isLoadingSendTask) return;
                                handleFormSubmit();
                            }}
                        >
                            {isLoading || isLoadingSendTask ? <Loader /> : 'Got it'}
                        </Button>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header meeting" style={{ height: '88px' }}>
                        <div className="modal__title name-title">Important Information</div>
                    </div>

                    <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                        <div className="main__title" style={{ margin: '0 0 6px 0' }}>
                            Unsuccessful suppliers will only be notified once the agreement with your appointed supplier
                            has been finalised.
                            <br />
                            <br />
                            If you there is a change in supplier and your current supplier has not been successful, we
                            recommend booking a meeting with them as a courtesy to inform them of the outcome and
                            support a smooth transition before formal notifications are sent.
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
