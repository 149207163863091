import { SetStateAction, useState, Dispatch, useEffect } from 'react';
import logo from '../../assets/logoForHeader.svg';
import logoPhone from '../../assets/logo-width-to600.svg';
import SupplierFiles from '../../assets/SupplierFiles.svg';
import SupplierFileIcon from '../../assets/SupplierFileIcon.svg';
import ParticipationAgreement from '../../assets/ParticipationAgreement.svg';
import i from '../../assets/i.svg';
import { Button, Divider, Modal, Spin, Tooltip, Upload, UploadFile } from 'antd';
import { Loader } from '../../components/Loader/Loader';
import { UploadFileSupplier } from '../../components/Modals/UploadFileSupplier';
import ReceivedFile from '../../assets/ReceivedFile.svg';
import deleteIcon from '../../assets/DeleteIcon.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    useLazyChackSignQuery,
    useLazyChackUnvarificationQuery,
    useLazyChackVarificationQuery,
    useSubmitSignMutation,
    useSubmitUnvarificationMutation,
    useSubmitVarificationMutation,
    useUnverifySupplierMutation
} from '../../api/users';
import done from '../../assets/Done, Check.svg';
import successIcon from '../../assets/supplierCreated.svg';

interface ModalState {
    open: boolean;
    title: string;
    subtitle: string;
    note: string;
    setFile: ((file: File) => void) | null; // setFile is a function or null
    file: File | [];
}

export const SupplierVarification = () => {
    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);
    function handleResize() {
        setScreenWidth(window.screen.width);
    }
    const [submitVarification, { data: submitData, isLoading, isSuccess }] = useSubmitVarificationMutation();
    const [submitSign, { data: submitSignData, isLoading: isLoadingSignData, isSuccess: isSuccessSubmitSign }] =
        useSubmitSignMutation();
    const [submitUnvarification, { data: submitUnVarData, isLoading: isLoadingSubmitUnv, isSuccess: isSusseesUnvar }] =
        useSubmitUnvarificationMutation();
    const [
        unverifySupplier,
        {
            isLoading: isLoadingUnverifySupplier,
            isSuccess: isSuccessUnverifySupplier,
            error: errorUnverifySupplier,
            isError: isErrorUnverifySupplier
        }
    ] = useUnverifySupplierMutation();
    const [checkVarification, { data: varificationData, error }] = useLazyChackVarificationQuery();
    const [checkSign, { data: signData, isError: isErrorSignData, error: errorSignData }] = useLazyChackSignQuery();
    const [checkUnvarification, { data: unvarificationData, error: errorUnvar }] = useLazyChackUnvarificationQuery();
    const [openedLink, setOpendLink] = useState(false);
    const [isConfimed, setIsConfirmed] = useState(false);
    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
        //@ts-ignore
        if (isErrorUnverifySupplier && errorUnverifySupplier?.status === 403) {
            navigate('/not-found');
        }
    }, [isErrorUnverifySupplier, errorUnverifySupplier]);
    useEffect(() => {
        //@ts-ignore
        if (isErrorSignData && errorSignData?.status === 403) {
            navigate('/not-found');
        }
    }, [isErrorSignData, errorSignData]);

    useEffect(() => {
        if (location.pathname === '/supplier-company/verification') {
            checkVarification(location.search);
        }
        if (location.pathname === '/supplier-company/unverification') {
            checkUnvarification(location.search);
        }
        if (location?.pathname === '/supplier-company/sign-tpa') {
            checkSign(location.search);
        }
        if (location?.pathname === '/supplier-company/unverified') {
            unverifySupplier(location.search);
        }
    }, [location]);

    window.addEventListener('resize', handleResize);

    useEffect(() => {
        //@ts-ignore
        if (error?.status === 403 || errorUnvar?.status === 403) {
            navigate('/not-found');
        }
    }, [error, errorUnvar]);

    const [isDeleteFileModal, setIsDeleteFileModal] = useState(false);
    const [deletedFile, setDeletedFile] = useState('');

    const [financialAccount, setFinancialAccount] = useState<any>([]);
    const [BBEE, setBBEE] = useState<any>([]);
    const [SARS, setSARS] = useState<any>([]);
    const [Registration, setRegistration] = useState<any>([]);

    const [openFinancialAccount, setOpenFinancialAccount] = useState<any>(false);
    const [openBBEE, setOpenBBEE] = useState<any>(false);
    const [openSARS, setOpenSARS] = useState<any>(false);
    const [openRegistration, setOpenRegistration] = useState<any>(false);

    const handleFormSubmit = () => {
        if (location.pathname === '/supplier-company/verification') {
            const formData = new FormData();

            const files = [
                financialAccount?.[0]?.originFileObj,
                BBEE?.[0]?.originFileObj,
                SARS?.[0]?.originFileObj,
                Registration?.[0]?.originFileObj
            ];

            const validFiles = files.filter((file) => file !== undefined);

            validFiles.forEach((file) => {
                formData.append('files[]', file); // Используем 'files[]' для передачи массива файлов
            });
            formData.append('sign', '1');
            const data = {
                signature: location.search,
                data: formData
            };
            submitVarification(data);
        }
        if (location.pathname === '/supplier-company/unverification') {
            const formData = { sign: 1 };

            const data = {
                signature: location.search,
                data: formData
            };
            submitUnvarification(data);
        }
        if (location.pathname === '/supplier-company/sign-tpa') {
            const data = {
                sign: true,
                signature: location.search
            };
            submitSign(data);
        }
    };

    return (
        <>
            <div className="signup">
                <Spin spinning={isLoadingUnverifySupplier}>
                    <div className="signup__header header">
                        {screenWidth > 600 && <img src={logo} className="header__logo" />}
                        {screenWidth <= 600 && <img src={logoPhone} className="header__logo" />}
                    </div>
                    {varificationData?.data && !isSuccess && (
                        <div className="signup__main main supplier">
                            <div className="main__block">
                                <div className="main__form form">
                                    <div className="form__title">
                                        Complete the following tasks to <br /> obtain verified status
                                    </div>

                                    <div className="form__subtitle">Please upload requested documents</div>
                                </div>
                                <div className="supplier__files">
                                    {financialAccount?.length ? (
                                        <div className="supplier__selectedFile">
                                            <div className="supplier__row">
                                                <img src={SupplierFileIcon} className="supplier__image" />
                                                <div>Financial Accounts</div>
                                                <Tooltip
                                                    title={
                                                        <div style={{ fontWeight: '400' }}>
                                                            Financial accounts must be complete for the previous
                                                            financial year.
                                                        </div>
                                                    }
                                                    overlayClassName={'custom-tooltip-disabled'}
                                                >
                                                    <img src={i} className="supplier__i" />
                                                </Tooltip>
                                            </div>
                                            <Divider />
                                            <div className="supplier__wrap">
                                                <div className="supplier__name">Uploaded File</div>
                                            </div>

                                            <div className="supplier__wrap">
                                                <div className="supplier__value">
                                                    <img src={ReceivedFile} className="upload-download-icon" />
                                                    {financialAccount?.[0]?.name}
                                                </div>
                                                <img
                                                    src={deleteIcon}
                                                    className="supplier__delete"
                                                    onClick={() => {
                                                        setIsDeleteFileModal(true);
                                                        setDeletedFile('financing');
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="supplier__file">
                                            <div className="supplier__row">
                                                <img src={SupplierFileIcon} className="supplier__image" />
                                                <div>Financial Accounts</div>
                                                <Tooltip
                                                    title={
                                                        <div style={{ fontWeight: '400' }}>
                                                            Financial accounts must be complete for the previous
                                                            financial year.
                                                        </div>
                                                    }
                                                    overlayClassName={'custom-tooltip-disabled'}
                                                >
                                                    <img src={i} className="supplier__i" />
                                                </Tooltip>
                                            </div>
                                            <div
                                                className="supplier__btn"
                                                onClick={() => {
                                                    setOpenFinancialAccount(true);
                                                    setOpenBBEE(false);
                                                    setOpenSARS(false);
                                                    setOpenRegistration(false);
                                                }}
                                            >
                                                Upload
                                            </div>
                                        </div>
                                    )}
                                    {BBEE.length ? (
                                        <div className="supplier__selectedFile">
                                            <div className="supplier__row">
                                                <img src={SupplierFileIcon} className="supplier__image" />
                                                <div>B-BBEE Affidavit / Certificate</div>
                                                <Tooltip
                                                    title={
                                                        <div style={{ fontWeight: '400' }}>
                                                            Document must be dated within the last twelve months
                                                        </div>
                                                    }
                                                    overlayClassName={'custom-tooltip-disabled'}
                                                >
                                                    <img src={i} className="supplier__i" />
                                                </Tooltip>
                                            </div>
                                            <Divider />
                                            <div className="supplier__wrap">
                                                <div className="supplier__name">Uploaded File</div>
                                            </div>

                                            <div className="supplier__wrap">
                                                <div className="supplier__value">
                                                    <img src={ReceivedFile} className="upload-download-icon" />
                                                    {BBEE?.[0]?.name}
                                                </div>
                                                <img
                                                    src={deleteIcon}
                                                    className="supplier__delete"
                                                    onClick={() => {
                                                        setIsDeleteFileModal(true);
                                                        setDeletedFile('BBEE');
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="supplier__file">
                                            <div className="supplier__row">
                                                <img src={SupplierFileIcon} className="supplier__image" />
                                                <div>B-BBEE Affidavit / Certificate</div>
                                                <Tooltip
                                                    title={
                                                        <div style={{ fontWeight: '400' }}>
                                                            Document must be dated within the last twelve months
                                                        </div>
                                                    }
                                                    overlayClassName={'custom-tooltip-disabled'}
                                                >
                                                    <img src={i} className="supplier__i" />
                                                </Tooltip>
                                            </div>
                                            <div
                                                className="supplier__btn"
                                                onClick={() => {
                                                    setOpenFinancialAccount(false);
                                                    setOpenBBEE(true);
                                                    setOpenSARS(false);
                                                    setOpenRegistration(false);
                                                }}
                                            >
                                                Upload
                                            </div>
                                        </div>
                                    )}
                                    {SARS.length ? (
                                        <div className="supplier__selectedFile">
                                            <div className="supplier__row">
                                                <img src={SupplierFileIcon} className="supplier__image" />
                                                <div>SARS Letter of Good Standing</div>
                                                <Tooltip
                                                    title={
                                                        <div style={{ fontWeight: '400' }}>
                                                            Financial accounts must be complete for the previous
                                                            financial year.
                                                        </div>
                                                    }
                                                    overlayClassName={'custom-tooltip-disabled'}
                                                >
                                                    <img src={i} className="supplier__i" />
                                                </Tooltip>
                                            </div>
                                            <Divider />
                                            <div className="supplier__wrap">
                                                <div className="supplier__name">Uploaded File</div>
                                            </div>

                                            <div className="supplier__wrap">
                                                <div className="supplier__value">
                                                    <img src={ReceivedFile} className="upload-download-icon" />
                                                    {SARS?.[0]?.name}
                                                </div>
                                                <img
                                                    src={deleteIcon}
                                                    className="supplier__delete"
                                                    onClick={() => {
                                                        setIsDeleteFileModal(true);
                                                        setDeletedFile('SARS');
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="supplier__file">
                                            <div className="supplier__row">
                                                <img src={SupplierFileIcon} className="supplier__image" />
                                                <div>SARS Letter of Good Standing</div>
                                                <Tooltip
                                                    title={
                                                        <div style={{ fontWeight: '400' }}>
                                                            Document must be dated within the last twelve months
                                                        </div>
                                                    }
                                                    overlayClassName={'custom-tooltip-disabled'}
                                                >
                                                    <img src={i} className="supplier__i" />
                                                </Tooltip>
                                            </div>
                                            <div
                                                className="supplier__btn"
                                                onClick={() => {
                                                    setOpenFinancialAccount(false);
                                                    setOpenBBEE(false);
                                                    setOpenSARS(true);
                                                    setOpenRegistration(false);
                                                }}
                                            >
                                                Upload
                                            </div>
                                        </div>
                                    )}
                                    {Registration.length ? (
                                        <div className="supplier__selectedFile">
                                            <div className="supplier__row">
                                                <img src={SupplierFileIcon} className="supplier__image" />
                                                <div>Company Registration Certificate</div>
                                                <Tooltip
                                                    title={
                                                        <div style={{ fontWeight: '400' }}>
                                                            Financial accounts must be complete for the previous
                                                            financial year.
                                                        </div>
                                                    }
                                                    overlayClassName={'custom-tooltip-disabled'}
                                                >
                                                    <img src={i} className="supplier__i" />
                                                </Tooltip>
                                            </div>
                                            <Divider />
                                            <div className="supplier__wrap">
                                                <div className="supplier__name">Uploaded File</div>
                                            </div>

                                            <div className="supplier__wrap">
                                                <div className="supplier__value">
                                                    <img src={ReceivedFile} className="upload-download-icon" />
                                                    {Registration?.[0]?.name}
                                                </div>
                                                <img
                                                    src={deleteIcon}
                                                    className="supplier__delete"
                                                    onClick={() => {
                                                        setIsDeleteFileModal(true);
                                                        setDeletedFile('Registration');
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="supplier__file">
                                            <div className="supplier__row">
                                                <img src={SupplierFileIcon} className="supplier__image" />
                                                <div>Company Registration Certificate</div>
                                            </div>
                                            <div
                                                className="supplier__btn"
                                                onClick={() => {
                                                    setOpenFinancialAccount(false);
                                                    setOpenBBEE(false);
                                                    setOpenSARS(false);
                                                    setOpenRegistration(true);
                                                }}
                                            >
                                                Upload
                                            </div>
                                        </div>
                                    )}
                                    {varificationData?.data?.tpa_link && (
                                        <div className={`supplier__open ${isConfimed ? 'active' : ''}`}>
                                            <div className="supplier__file supplier">
                                                <div className="supplier__row">
                                                    <img src={ParticipationAgreement} className="supplier__image" />
                                                    <div>Tender Participation Agreement</div>
                                                    <Tooltip
                                                        title={
                                                            <div style={{ fontWeight: '400' }}>
                                                                To receive tender notifications and participate in our
                                                                tenders, suppliers must sign a Tender Participation
                                                                Agreement. The agreement outlines, amongst other things,
                                                                any setup fee payable to us if you are appointed by a
                                                                school following the tender process.
                                                            </div>
                                                        }
                                                        overlayClassName={'custom-tooltip-disabled'}
                                                    >
                                                        <img src={i} className="supplier__i" />
                                                    </Tooltip>
                                                </div>
                                                <a
                                                    className="supplier__btn"
                                                    href={varificationData?.data?.tpa_link}
                                                    target="_blank"
                                                    onClick={() => setOpendLink(true)}
                                                    rel="noreferrer"
                                                >
                                                    Open
                                                </a>
                                            </div>
                                            {isConfimed ? (
                                                <div
                                                    className={`supplier__isConfimed ${!openedLink ? 'disabled' : ''}`}
                                                    onClick={() => setIsConfirmed(true)}
                                                >
                                                    {' '}
                                                    <img src={done} />
                                                    Signing Confirmed
                                                </div>
                                            ) : (
                                                <Tooltip
                                                    title="To confirm the signature, open the Tender Participation Agreement link and sign the document"
                                                    overlayClassName={` ${
                                                        !isConfimed ? 'custom-tooltip-disabled' : 'custom-tooltip'
                                                    }`}
                                                >
                                                    <div
                                                        className={`supplier__button ${!openedLink ? 'disabled' : ''}`}
                                                        onClick={() => {
                                                            if (!openedLink) return;
                                                            setIsConfirmed(true);
                                                        }}
                                                    >
                                                        Confirm Signature
                                                    </div>
                                                </Tooltip>
                                            )}
                                        </div>
                                    )}
                                    <Button
                                        className="fields__btn"
                                        type="primary"
                                        onClick={handleFormSubmit}
                                        disabled={
                                            (!isConfimed && varificationData?.data?.tpa_link) ||
                                            financialAccount.length < 1 ||
                                            BBEE.length < 1 ||
                                            SARS.legnth < 1 ||
                                            Registration.length < 1
                                        }
                                    >
                                        {isLoading || isLoadingSubmitUnv ? <Loader /> : 'Submit'}
                                    </Button>
                                </div>
                            </div>

                            <div className={`main__block ${screenWidth < 1280 ? 'image' : ''}`}>
                                <div>
                                    <img src={SupplierFiles} className="image__item" />
                                </div>
                            </div>
                        </div>
                    )}
                    {(unvarificationData?.data?.tpa_link || signData?.data?.tpa_link) &&
                        !isSusseesUnvar &&
                        !isSuccessSubmitSign && (
                            <div className="signup__main main supplier">
                                <div className="main__block">
                                    <div className="main__form form">
                                        {signData?.data?.change_title ? (
                                            <div className="form__title">
                                                Complete the following task to <br />
                                                participate in future tenders
                                            </div>
                                        ) : (
                                            <div className="form__title">
                                                tasks to <br /> obtain unverified status
                                            </div>
                                        )}
                                    </div>
                                    <div className="supplier__files">
                                        <div className={`supplier__open ${isConfimed ? 'active' : ''}`}>
                                            <div className="supplier__file supplier">
                                                <div className="supplier__row">
                                                    <img src={ParticipationAgreement} className="supplier__image" />
                                                    <div>Tender Participation Agreement</div>
                                                    <Tooltip
                                                        title={
                                                            <div style={{ fontWeight: '400' }}>
                                                                To receive tender notifications and participate in our
                                                                tenders, suppliers must sign a Tender Participation
                                                                Agreement. The agreement outlines, amongst other things,
                                                                any setup fee payable to us if you are appointed by a
                                                                school following the tender process.
                                                            </div>
                                                        }
                                                        overlayClassName={'custom-tooltip-disabled'}
                                                    >
                                                        <img src={i} className="supplier__i" />
                                                    </Tooltip>
                                                </div>
                                                <a
                                                    className="supplier__btn"
                                                    href={
                                                        unvarificationData?.data?.tpa_link
                                                            ? unvarificationData?.data?.tpa_link
                                                            : signData?.data?.tpa_link
                                                    }
                                                    target="_blank"
                                                    onClick={() => setOpendLink(true)}
                                                    rel="noreferrer"
                                                >
                                                    Open
                                                </a>
                                            </div>
                                            {isConfimed ? (
                                                <div
                                                    className={`supplier__isConfimed ${!openedLink ? 'disabled' : ''}`}
                                                >
                                                    <img src={done} />
                                                    Signing Confirmed
                                                </div>
                                            ) : (
                                                <Tooltip
                                                    title="To confirm the signature, open the Tender Participation Agreement link and sign the document"
                                                    overlayClassName={` ${
                                                        !isConfimed ? 'custom-tooltip-disabled' : 'custom-tooltip'
                                                    }`}
                                                >
                                                    <div
                                                        className={`supplier__button ${!openedLink ? 'disabled' : ''}`}
                                                        onClick={() => {
                                                            if (!openedLink) return;
                                                            setIsConfirmed(true);
                                                        }}
                                                    >
                                                        Confirm Signature
                                                    </div>
                                                </Tooltip>
                                            )}
                                        </div>
                                        <Button
                                            className="fields__btn"
                                            type="primary"
                                            onClick={handleFormSubmit}
                                            disabled={
                                                !isConfimed ||
                                                ((financialAccount.length < 1 ||
                                                    BBEE.length < 1 ||
                                                    SARS.legnth < 1 ||
                                                    Registration.length < 1) &&
                                                    varificationData?.data?.tpa_link)
                                            }
                                        >
                                            {isLoading || isLoadingSubmitUnv || isLoadingSignData ? (
                                                <Loader />
                                            ) : (
                                                'Submit'
                                            )}
                                        </Button>
                                    </div>
                                </div>

                                <div className={`main__block ${screenWidth < 1280 ? 'image' : ''}`}>
                                    <div>
                                        <img src={SupplierFiles} className="image__item" />
                                    </div>
                                </div>
                            </div>
                        )}
                    {(isSuccess ||
                        isSusseesUnvar ||
                        isSuccessSubmitSign ||
                        //@ts-ignore
                        error?.status === 303 ||
                        //@ts-ignore
                        errorUnvar?.status === 303) && (
                        <div className="signup__success success">
                            <img src={successIcon} />
                            <div className="success__title">Your submission is complete and is now under review</div>
                            <div className="success__subtitle">Expect to hear from us soon regarding the outcome.</div>
                            <Button
                                type="primary"
                                onClick={() => (window.location.href = 'https://schooladvisor.co.za/')}
                            >
                                Back Home
                            </Button>
                        </div>
                    )}
                    {isSuccessUnverifySupplier && (
                        <div className="signup__success success">
                            <img src={successIcon} />
                            <div className="success__title">Your unverified status is confirmed</div>
                            <Button
                                type="primary"
                                onClick={() => (window.location.href = 'https://schooladvisor.co.za/')}
                                style={{ margin: '25px 0 0 0' }}
                            >
                                Back Home
                            </Button>
                        </div>
                    )}
                </Spin>
            </div>
            {openFinancialAccount && (
                <UploadFileSupplier
                    openModal={openFinancialAccount}
                    setOpenModal={setOpenFinancialAccount}
                    title={'Financial Accounts'}
                    subtitle={'Upload a file to import Financial Accounts'}
                    note={'Financial accounts must be complete for the previous financial year.'}
                    file={financialAccount}
                    setFile={setFinancialAccount}
                />
            )}
            {openBBEE && (
                <UploadFileSupplier
                    openModal={openBBEE}
                    setOpenModal={setOpenBBEE}
                    title={'B-BBEE Affidavit / Certificate'}
                    subtitle={'Upload a file to import B-BBEE Affidavit / Certificate'}
                    note={'Document must be dated within the last twelve months'}
                    file={BBEE}
                    setFile={setBBEE}
                />
            )}
            {openSARS && (
                <UploadFileSupplier
                    openModal={openSARS}
                    setOpenModal={setOpenSARS}
                    title={'SARS Letter of Good Standing'}
                    subtitle={'Upload a file to import SARS Letter of Good Standing'}
                    note={'Document must be dated within the last twelve months'}
                    file={SARS}
                    setFile={setSARS}
                />
            )}
            {openRegistration && (
                <UploadFileSupplier
                    openModal={openRegistration}
                    setOpenModal={setOpenRegistration}
                    title={'Company Registration Certificate'}
                    subtitle={'Upload a file to import Company Registration Certificate'}
                    note={''}
                    file={Registration}
                    setFile={setRegistration}
                />
            )}
            {isDeleteFileModal && (
                <Modal
                    className="confirmModal"
                    centered
                    open={isDeleteFileModal}
                    width={660}
                    closeIcon={<></>}
                    footer={
                        <div
                            style={{
                                display: 'flex',
                                justifyItems: 'space-between',
                                gap: '16px',
                                width: '100%',
                                margin: '25px 0 0 0'
                            }}
                        >
                            <Button key="back" onClick={() => setIsDeleteFileModal(false)} block className="cancel">
                                Cancel
                            </Button>
                            <button
                                className="quit"
                                style={{ width: '100%' }}
                                onClick={() => {
                                    if (deletedFile === 'financing') {
                                        setFinancialAccount([]);
                                    }
                                    if (deletedFile === 'BBEE') {
                                        setBBEE([]);
                                    }
                                    if (deletedFile === 'SARS') {
                                        setSARS([]);
                                    }
                                    if (deletedFile === 'Registration') {
                                        setRegistration([]);
                                    }
                                    setIsDeleteFileModal(false);
                                }}
                            >
                                Delete
                            </button>
                        </div>
                    }
                >
                    <div className="modal-confirm">
                        <div className="modal-confirm__subtitle" style={{ color: '#4D5E93' }}>
                            Are you sure you want to delete the file?
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};
